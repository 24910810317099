export const SET_PROFILE_LOADER = "SET_PROFILE_LOADER";

export const SET_PAYMENT_MODAL = "SET_PAYMENT_MODAL";
export const SET_ALERT_MODAL = "SET_ALERT_MODAL";

export const UPDATE_PROFILE_PIC_REQUEST = "UPDATE_PROFILE_PIC_REQUEST";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAILURE = "UPDATE_PROFILE_PIC_FAILURE";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const ADD_PROFILE_REQUEST = "ADD_PROFILE_REQUEST";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_FAILURE = "ADD_PROFILE_FAILURE";

export const UPLOAD_PROFILE_PIC_REQUEST = "UPLOAD_PROFILE_PIC_REQUEST";
export const UPLOAD_PROFILE_PIC_SUCCESS = "UPLOAD_PROFILE_PIC_SUCCESS";
export const UPLOAD_PROFILE_PIC_FAILURE = "UPLOAD_PROFILE_PIC_FAILURE";

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const GET_CARDS_REQUEST = "GET_CARDS_REQUEST";
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_FAILURE = "GET_CARDS_FAILURE";

export const CHANGE_PASS_REQUEST = "CHANGE_PASS_REQUEST";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAILURE = "CHANGE_PASS_FAILURE";

export const FORGOT_PASS_REQUEST = "FORGOT_PASS_REQUEST";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_FAILURE = "FORGOT_PASS_FAILURE";

export const GET_PAY_METHODS_REQUEST = "GET_PAY_METHODS_REQUEST";
export const GET_PAY_METHODS_SUCCESS = "GET_PAY_METHODS_SUCCESS";
export const GET_PAY_METHODS_FAILURE = "GET_PAY_METHODS_FAILURE";

export const DETACH_CARD_REQUEST = "DETACH_CARD_REQUEST";
export const DETACH_CARD_SUCCESS = "DETACH_CARD_SUCCESS";
export const DETACH_CARD_FAILURE = "DETACH_CARD_FAILURE";

export const ATTACH_CARD_REQUEST = "ATTACH_CARD_REQUEST";
export const ATTACH_CARD_SUCCESS = "ATTACH_CARD_SUCCESS";
export const ATTACH_CARD_FAILURE = "ATTACH_CARD_FAILURE";

export const UPDATE_SUB_REQUEST = "UPDATE_SUB_REQUEST";
export const UPDATE_SUB_SUCCESS = "UPDATE_SUB_SUCCESS";
export const UPDATE_SUB_FAILURE = "UPDATE_SUB_FAILURE";

export const UPDATE_SELECTED_PLAN = "UPDATE_SELECTED_PLAN";

export const RESET = "RESET";
