import * as types from "./types";

export const getUsersList = (payload) => ({
  type: types.GET_USERS_LIST_REQUEST,
  payload,
});
export const getUsersListSuccess = (payload) => ({
  type: types.GET_USERS_LIST_SUCCESS,
  payload,
});
export const getUsersListFailure = (payload) => ({
  type: types.GET_USERS_LIST_FAILURE,
  payload,
});

export const getCouponsList = (payload) => ({
  type: types.COUPONS_LIST_REQUEST,
  payload,
});
export const getCouponsListSuccess = (payload) => ({
  type: types.COUPONS_LIST_SUCCESS,
  payload,
});
export const getCouponsListFailure = (payload) => ({
  type: types.COUPONS_LIST_FAILURE,
  payload,
});

export const addCoupon = (payload) => ({
  type: types.ADD_COUPON_REQUEST,
  payload,
});
export const addCouponSuccess = (payload) => ({
  type: types.ADD_COUPON_SUCCESS,
  payload,
});
export const addCouponFailure = (payload) => ({
  type: types.ADD_COUPON_FAILURE,
  payload,
});
