/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Heart from "../../assets/images/componies/heart.svg";
import Heartfilled from "../../assets/images/componies/heartFilled.svg";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import verticleDevider2 from "../../assets/images/componies/verticleDevider2.svg";
import history from "../../routes/History";
// import CalendarNewestFirst from "../../assets/images/componies/CalendarNewestFirst.svg";
// import CalendarOldestFirst from "../../assets/images/componies/CalendarOldestFirst.svg";
// import SortAscending from "../../assets/images/componies/SortAscending.svg";
// import SortDescending from "../../assets/images/componies/SortDescending.svg";
// import dropdownArrow from "../../assets/images/componies/dropdownArrow.svg";
// import { Select } from "antd";
import "./Companies.scss";

// const { Option } = Select;

// const dropDownData = [
//   {
//     text: "A to Z",
//     imgSrc: SortAscending,
//   },
//   {
//     text: "Z to A",
//     imgSrc: SortDescending,
//   },
// {
//   text: "Latest First",
//   imgSrc: CalendarNewestFirst,
// },
// {
//   text: "Oldest First",
//   imgSrc: CalendarOldestFirst,
// },
// ];

const ComponiesTopBar = ({
  companyList,
  handleTabChange,
  faveratesList,
  // handleSort,
  activeTab,
  // sort,
  componiesMapScreen,
  handleMapClick,
}) => {
  const companyListObject = useSelector(
    (state) => state.componies.filteredCompanyList
  );
  // function handleChange(value) {
  //   handleSort(value);
  // }

  return (
    <div className="topbar_container">
      <div className="topbar_container_inner">
        {componiesMapScreen ? (
          <div className="mapSectionTopbar">
            <div onClick={handleMapClick} style={{ cursor: "pointer" }}>
              <img
                src={ArrowLeft}
                alt="arrow"
                style={{
                  marginBottom: "4px",
                  width: "20px",
                  marginRight: "8px",
                }}
              />
              <span style={{ fontWeight: 600, textDecoration: "underline" }}>
                {window?.location?.search === "?ExploreBySkills=true"
                  ? "Company Explore | Matching Selected Skills"
                  : "Company Match | Personalized Selection"}
              </span>
            </div>
            <img
              src={verticleDevider2}
              alt="verticleDevider"
              style={{
                margin: "0px 15px",
              }}
            />
            <div>Map</div>
          </div>
        ) : (
          <div
            className="companies_matched"
            onClick={() => {
              if (window?.location?.search === "?ExploreBySkills=true") {
                history.push("/explore");
              }
            }}
          >
            {window?.location?.search === "?ExploreBySkills=true" ? (
              <img
                src={ArrowLeft}
                alt="arrow"
                style={{
                  marginBottom: "4px",
                  width: "20px",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              />
            ) : null}
            <span>
              {window?.location?.search === "?ExploreBySkills=true"
                ? "Company Explore | Matching Selected Skills"
                : "Company Match | Personalized Selection"}
            </span>
          </div>
        )}

        <div className="results_favorites">
          <div
            style={{
              color: activeTab === "results" ? "#4389FA" : "",
              fontWeight: activeTab === "results" ? 600 : 500,
            }}
            onClick={() => handleTabChange("results")}
            className="results"
          >
            Results (
            {activeTab === "results"
              ? companyList.length
              : companyListObject.length - 1}
            )
          </div>
          <div
            style={{
              color: activeTab === "favrates" ? "#4389FA" : "",
              fontWeight: activeTab === "favrates" ? 600 : 500,
            }}
            className={"favrates"}
            onClick={() => handleTabChange("favrates")}
          >
            <img
              src={activeTab === "favrates" ? Heartfilled : Heart}
              alt="favorite"
              className="favIcon"
            />
            <p style={{ margin: 0 }}>
              {" "}
              Favorites (
              {Array.isArray(companyList) ? (
                <>
                  {
                    companyList.filter((item) =>
                      faveratesList.includes(`${item.unnlock_id}`)
                    ).length
                  }
                </>
              ) : null}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponiesTopBar;
