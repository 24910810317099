/* eslint-disable */
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, List, Spin } from "antd";
import { isEmpty } from "lodash-es";
import history from "../../routes/History";
import UnnlockLayout from "../layout";
import CompanyLeftNavigation from "./CompanyLeftNavigation/CompanyLeftNavigation";
import CompanyCard from "./componyCard";
import * as COMPANYActions from "../../store/componies/action";
import ComponiesTopBar from "./topBar";
import "./Companies.scss";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import FunnelSimple from "../../assets/images/componies/FunnelSimple.svg";
import arrrowDown from "../../assets/images/componies/arrowDown.svg";
import LocationsMap from "./LocationsMap";
import { PreviewSuggestionWithoutInfo } from "../ResumeOpimizer/suggestions";
import makeGeoJSON from "./GeoJson";

// const checkvalidArray = (data) => {
//   if (Array.isArray(data) && data.length) {
//     return true;
//   } else return false;
// };

const Companies = (props) => {
  const dispatch = useDispatch();
  const companyListObject = useSelector(
    (state) => state.componies.filteredCompanyList
  );
  console.log("Filerered", companyListObject);
  // const componiesByLocation = useSelector(
  //   (state) => state.componies.componiesByLocation
  // );
  const companyMatch = useSelector((state) => state.componies.companyMatch);
  const companyDeatils = useSelector((state) => state.componies.companyDeatils);
  const industryMatch = useSelector((state) => state.componies.industryMatch);
  const resumeParseData = useSelector(
    (state) => state.dashboard.resumeParseData
  );
  const loginLoader = useSelector((state) => state.login.loader);
  const userData = useSelector((state) => state.login.userData);
  const appliedFilteres = useSelector(
    (state) => state.componies.appliedFilteres
  );
  const companyLoader = useSelector((state) => state.componies.Loader);

  const [companyList, setCompanyList] = useState([]);
  const [faveratesList, setFaveratesList] = useState([]);
  const [activeTab, setActiveTab] = useState("results");
  const [isComponyDetails, setisComponyDetails] = useState(false);
  const [componiesMapScreen, setcomponiesMapScreen] = useState(false);
  const [mobileFilteres, setMobileFilteres] = useState(false);
  const [locationsList, setLocationsList] = useState([]);

  const getFavClick = (operation, companyId) => {
    dispatch(
      COMPANYActions.favouriteClickRequest({
        operation,
        companyId,
        urlKey: "favourite_companies",
      })
    );
  };

  const handleCompanyClicked = (compId) => {
    dispatch(COMPANYActions.selectedCompanyId(compId));
    dispatch(COMPANYActions.getComponyDeatailsRequest(compId));
    // setisComponyDetails(true);
  };

  const handleCompanyClickedCallback = useCallback(
    (unnlock_id) => {
      handleCompanyClicked(unnlock_id);
    },
    [handleCompanyClicked]
  );

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (Object.keys(appliedFilteres).length && companyListObject) {
      const filterArr = Object.keys(appliedFilteres);
      let companyArr = [];
      if (activeTab === "favrates") {
        const arr = companyListObject.filter((item) =>
          faveratesList.includes(`${item.unnlock_id}`)
        );
        companyArr = [...arr];
      } else {
        companyArr = [...companyListObject];
      }
      for (let i = 0; i < filterArr.length; i++) {
        companyArr = companyArr.filter((item) => {
          if (filterArr[i] === "industry_list") {
            if (appliedFilteres["industry_list"].length) {
              if (appliedFilteres["industry_list"].includes(item.industry)) {
                return true;
              }
              return false;
            }
          }
          if (filterArr[i] === "size_list") {
            if (appliedFilteres["size_list"].length) {
              if (appliedFilteres["size_list"].includes(item.company_size)) {
                return true;
              }
              return false;
            }
          }

          if (filterArr[i] === "location_list") {
            if (appliedFilteres["location_list"].length) {
              if (appliedFilteres["location_list"].includes(item.unnlock_id)) {
                return true;
              }
              return false;
            }
          }

          return true;
        });
      }
      setCompanyList(companyArr);
    } else {
      if (companyListObject) {
        if (activeTab === "favrates") {
          const arr = companyListObject.filter((item) =>
            faveratesList.includes(`${item.unnlock_id}`)
          );
          setCompanyList(arr);
        } else {
          setCompanyList(companyListObject);
        }
      }
    }
  }, [appliedFilteres, companyListObject]);

  useEffect(() => {
    const locations = [];
    companyList.forEach(function (company, index) {
      if (company.locations) {
        // const company_locations = JSON.parse(
        //   company.locations.replace(/: NaN/g, ": null")
        // );
        const company_locations = company.locations;
        company_locations.forEach(function (location, i, arr) {
          arr[i]["company"] = company;
          arr[i]["lat"] = arr[i]["Geometry"]["Point"][1];
          arr[i]["lng"] = arr[i]["Geometry"]["Point"][0];
        });
        if (Array.isArray(company_locations) && company_locations.length > 0)
          locations.push(company_locations);
      }
    });
    const geoLocations = makeGeoJSON.parse(locations.flat(), {
      Point: ["lat", "lng"],
    });
    setLocationsList(geoLocations);
  }, [companyList]);

  useEffect(() => {
    dispatch(COMPANYActions.addFilteres({}));
    if (activeTab === "favrates") {
      const arr = companyListObject.filter((item) =>
        faveratesList.includes(`${item.unnlock_id}`)
      );
      setCompanyList(arr);
    } else {
      setCompanyList(companyListObject);
    }
  }, [activeTab]);

  // const setCompanyListCallback = useCallback(
  //   (id_list) => {
  //     const arr = companyListObject.filter((company) =>
  //       id_list.includes(company.unnlock_id)
  //     );
  //     setCompanyList(arr);
  //   },
  //   [setCompanyList, companyListObject]
  // );

  useEffect(() => {
    if (window.location.search === "" && !loginLoader && userData) {
      dispatch(
        COMPANYActions.companiesSearchRequest({
          postData: {},
        })
      );
    }
    if (window.location.search === "?ExploreByCname=true") {
      setisComponyDetails(true);
    }
  }, [loginLoader]);

  useEffect(() => {
    if (
      userData &&
      userData.favourite_companies &&
      Array.isArray(userData.favourite_companies)
    ) {
      setFaveratesList(userData.favourite_companies);
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData &&
      userData.primary_resume &&
      companyMatch &&
      industryMatch &&
      !resumeParseData?.simplified_doc?.CompanyMatch &&
      !resumeParseData?.simplified_doc?.IndustryMatch
    )
      dispatch(
        COMPANYActions.updateTopComponiesRequest({
          companyName: companyMatch,
          industryName: industryMatch,
          resumeId: userData.primary_resume,
        })
      );
  }, [userData, companyMatch, industryMatch, resumeParseData]);

  const showFavByDefault = props.location?.state?.showFavByDefault || false;

  useEffect(() => {
    if (showFavByDefault) {
      console.log(showFavByDefault, "showFavByDefault");
      setActiveTab("favrates");
    }
  }, [showFavByDefault]);

  const listItem = (item) => (
    <CompanyCard
      item={item}
      getFavClick={getFavClick}
      handleCompanyClicked={handleCompanyClicked}
      isFaverate={faveratesList.includes(`${item.unnlock_id}`) ? true : false}
    />
  );

  useEffect(() => {
    if (companyDeatils?.unnlock_id) {
      setisComponyDetails(true);
    }
  }, [companyDeatils]);

  const filterArr = Object.keys(appliedFilteres);

  const suggestionContainer = (
    <div className="company_body_right">
      <PreviewSuggestionWithoutInfo
        page={
          window?.location?.search === "?ExploreBySkills=true"
            ? "companyExplore"
            : "companyMatch"
        }
      />
    </div>
  );

  return (
    <UnnlockLayout>
      <div
        className="companies-container"
        style={{ background: mobileFilteres ? "white" : "" }}
      >
        <Spin size="large" spinning={companyLoader}>
          {userData ? (
            <>
              {mobileFilteres ? (
                <div className="filtersMask">
                  <CompanyLeftNavigation
                    mobileView={true}
                    setMobileFilteres={setMobileFilteres}
                    companyList={companyList}
                    componiesMapScreen={componiesMapScreen}
                    handleMapClick={() => {
                      setMobileFilteres(false);
                      setcomponiesMapScreen(!componiesMapScreen);
                    }}
                  />
                </div>
              ) : (
                <>
                  {isComponyDetails ? (
                    <CompanyDetails
                      handleBack={() => {
                        setisComponyDetails(false);
                      }}
                      setisComponyDetails={setisComponyDetails}
                    />
                  ) : (
                    <>
                      <ComponiesTopBar
                        companyList={
                          // filterArr.length ? companyList : companyList.slice(1)
                          companyList
                        }
                        handleTabChange={handleTabChange}
                        faveratesList={faveratesList}
                        // handleSort={handleSort}
                        activeTab={activeTab}
                        // sort={sort}
                        componiesMapScreen={componiesMapScreen}
                        handleMapClick={() => {
                          setMobileFilteres(false);
                          setcomponiesMapScreen(!componiesMapScreen);
                        }}
                      />
                      <Row
                        justify="center"
                        className="companies-container-inner"
                      >
                        <Col span={6} className="companies-container-left">
                          <CompanyLeftNavigation
                            companyList={companyList}
                            componiesMapScreen={componiesMapScreen}
                            handleMapClick={() =>
                              setcomponiesMapScreen(!componiesMapScreen)
                            }
                          />
                        </Col>
                        <Col
                          span={16}
                          flex={4}
                          className="companies-container-right"
                        >
                          {componiesMapScreen ? (
                            <Row span={16} flex={4}>
                              <LocationsMap
                                locations={locationsList}
                                handleCompanyClickedCallback={
                                  handleCompanyClickedCallback
                                }
                                showList={true}
                                useLocationFilter={true}
                              />
                            </Row>
                          ) : (
                            <Row span={16} flex={4}>
                              {companyList.length === 0 &&
                              !filterArr.length ? null : (
                                <Row
                                  justify="space-between"
                                  className="componies"
                                >
                                  <div className="mobileSuggestion">
                                    {suggestionContainer}
                                  </div>
                                  <Col xs={24} sm={24} md={24} lg={12}>
                                    {companyList.length ? (
                                      <CompanyCard
                                        item={companyList[0]}
                                        getFavClick={getFavClick}
                                        handleCompanyClicked={
                                          handleCompanyClicked
                                        }
                                        isFaverate={
                                          faveratesList.includes(
                                            `${companyList[0].unnlock_id}`
                                          )
                                            ? true
                                            : false
                                        }
                                        className="topTwoCompany1"
                                      />
                                    ) : null}
                                    {companyList.length > 1 ? (
                                      <CompanyCard
                                        item={companyList[1]}
                                        getFavClick={getFavClick}
                                        handleCompanyClicked={
                                          handleCompanyClicked
                                        }
                                        isFaverate={
                                          faveratesList.includes(
                                            `${companyList[1].unnlock_id}`
                                          )
                                            ? true
                                            : false
                                        }
                                        className="topTwoCompany2"
                                      />
                                    ) : null}
                                  </Col>
                                  <Col
                                    className="suggestion_container"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                  >
                                    {suggestionContainer}
                                  </Col>
                                </Row>
                              )}
                              <Col className="company-container">
                                {companyList.length === 0 &&
                                !filterArr.length ? null : (
                                  <List
                                    grid={{
                                      xs: 1,
                                      sm: 1,
                                      md: 1,
                                      lg: 2,
                                      xl: 2,
                                      xxl: 2,
                                      gutter: 16,
                                    }}
                                    dataSource={
                                      isEmpty(companyList)
                                        ? []
                                        : window && !window.location.search
                                        ? filterArr.length
                                          ? companyList
                                          : companyList
                                        : companyList
                                    }
                                    renderItem={(item, i) => {
                                      if (i > 1) return listItem(item);
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                          {userData?.tier_id === 0 &&
                          !companyLoader &&
                          !componiesMapScreen ? (
                            <div className="subscribeAlertContainer">
                              <p>
                                Upgrade your plan to view additional companies
                              </p>
                              <button
                                onClick={() => history.push("/subscriptions")}
                              >
                                Upgrade
                              </button>
                            </div>
                          ) : null}
                        </Col>
                        <div className="bottomFixed">
                          <div
                            className="filteres"
                            onClick={() => setMobileFilteres(true)}
                          >
                            <div className="filter">Filteres</div>
                            <div className="count">
                              {Object.keys(appliedFilteres).length}
                            </div>
                            <img src={FunnelSimple} className="filterIcon" />
                          </div>
                          <div className="sorting">
                            <div className="sortBy">
                              Sort by <span>A to Z</span>
                              <img src={arrrowDown} className="arrowdown" />
                            </div>
                          </div>
                        </div>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
          ) : null}
        </Spin>
      </div>
    </UnnlockLayout>
  );
};
export default Companies;
