import React from "react";
/* eslint-disable */
import "react-perfect-scrollbar/dist/css/styles.css";
import "./skills.scss";

const SkillsCard = ({ title, skills }) => {
  return (
    <div className="skills_card_container">
      <div className="header_title">{title}</div>
      <div className="skills_container">
        <div className="skills">
          {skills.map((item) => (
            <div className={item.match ? "skill_item_active" : "skill_item"}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillsCard;
