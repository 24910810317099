import * as Yup from "yup";

export const key = "association";
export const schema = [
  {
    field: "input",
    label: "Name",
    id: "name",
    type: "text",
  },
  {
    field: "input",
    label: "Role",
    id: "role",
    type: "text",
  },
];

export const values = {
  role: "",
  name: "",
};
export const initialValues = { association: [values] };
export const validation = Yup.object().shape({
  association: Yup.array().of(
    Yup.object().shape({
      role: Yup.string().required("role is required"),
      name: Yup.string().required("name is required"),
    })
  ),
});
