/*eslint-disable*/
import React from "react";

const Template2 = ({ isEdit }) => {
  return (
    <div>
      <div>Template2</div>
      {isEdit ? <button>Edit</button> : null}
    </div>
  );
};

export default Template2;
