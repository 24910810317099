/* eslint-disable */
import * as types from "./types";

const INITIAL_STATE = {
  loader: false,
  users: [],
  coupons: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        users: action.payload,
      };
    case types.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.COUPONS_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.COUPONS_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        coupons: action.payload,
      };
    case types.COUPONS_LIST_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.ADD_COUPON_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.ADD_COUPON_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.ADD_COUPON_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
