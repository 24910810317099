import React, { useEffect, useRef } from "react";
import ResumeOptimizerHeader from "./ROHeader";
import ResumeOptimizerBody from "./ROBody";
import { useDispatch, useSelector } from "react-redux";
import {
  resumeRequest,
  setCurrentResumeId,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import UnnlockLayout from "../layout";
// import { getUserDataRequest } from "../../store/newLogin/newLoginAction";

const ResumeOptimizer = (props) => {
  const dispatch = useDispatch();
  const currentResume = useSelector(
    (state) => state.resumeOptimizer.currentResume
  );
  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);
  const loginLoader = useSelector((state) => state.login.loader);
  const resumeId = props.location?.state?.resumeId;
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    if (resumeId) {
      dispatch(setCurrentResumeId(resumeId));
    }
  }, [resumeId]);

  useEffect(() => {
    if (currentResume) {
      if (!resumeOptimizerState.loader) {
        // dispatch(getUserDataRequest());
        dispatch(resumeRequest());
      }
    }
  }, [currentResume, loginLoader]);

  return (
    <UnnlockLayout>
      <div className="ResumeOptimizer-container">
        <ResumeOptimizerHeader {...props} downloadLinkRef={downloadLinkRef} />
        <ResumeOptimizerBody downloadLinkRef={downloadLinkRef} />
      </div>
    </UnnlockLayout>
  );
};

export default ResumeOptimizer;
