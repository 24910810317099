/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const NewEditor = ({ value, handleEditorChange }) => {
  const suggestionLoader = useSelector(
    (state) => state.resumeOptimizer.suggestionLoader
  );

  const setOnclickEventforPtag = (data) => {
    modifyHtmlP(data);
  };

  const setOnclickEventforLitag = (data) => {
    modifyHtmlLi(data);
  };

  const modifyHtmlLi = (data) => {
    const modifiedIgnoreHtml = value.match(/li_ignore_(\d+(d)*)/gm);
    const modifiedReplaceHtml = value.match(/li_replace_(\d+(d)*)/gm);
    if (
      modifiedIgnoreHtml &&
      Array.isArray(modifiedIgnoreHtml) &&
      modifiedIgnoreHtml.length
    )
      modifiedIgnoreHtml.forEach((id) => {
        if (document.getElementById(`${id}`))
          document.getElementById(`${id}`).addEventListener(
            "click",
            () => {
              let replaceStr = "";
              if (id.split("_")[0] === "li" && !suggestionLoader) {
                let strId = `li_${id.split("_")[2]}`;
                replaceStr = `<div contenteditable="false" id="li_div_${
                  id.split("_")[2]
                }".*<span id="li_span_${
                  id.split("_")[2]
                }">&nbsp;<\/span><\/div><\/div>`;
                console.log(
                  new RegExp(replaceStr),
                  data,
                  "new RegExp(replaceStr), data"
                );
                let str = data.replace(new RegExp(replaceStr), "");
                let finalStr = str
                  .replace(`<li id="${strId}"`, "<li")
                  .replace(/<li><br><\/li>/gm, "");
                handleEditorChange(finalStr);
                setOnclickEventforPtag(finalStr);
                setOnclickEventforLitag(finalStr);
              }
            },
            false
          );
      });
    if (
      modifiedReplaceHtml &&
      Array.isArray(modifiedReplaceHtml) &&
      modifiedReplaceHtml.length
    )
      modifiedReplaceHtml.forEach((id) => {
        if (document.getElementById(`${id}`))
          document.getElementById(`${id}`).addEventListener(
            "click",
            () => {
              let strId = `li_${id.split("_")[2]}`;
              let replaceStr = "";
              if (id.split("_")[0] === "li" && !suggestionLoader) {
                const myHTML = document.getElementById(
                  `li_div_${id.split("_")[2]}`
                ).outerHTML;
                const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                replaceStr = `<li id="${strId}".*<span id="li_span_${
                  id.split("_")[2]
                }">&nbsp;<\/span><\/div><\/div>`;
                console.log(
                  new RegExp(replaceStr),
                  data,
                  "new RegExp(replaceStr), data"
                );
                let str = data
                  .replace(/\n/g, "")
                  .replace(new RegExp(replaceStr), `<li>${strippedHtml}</li>`);
                let finalStr = str
                  .replace(/<li><br><\/li>/gm, "")
                  .replace(/IgnoreReplace&nbsp;/gm, "");
                handleEditorChange(finalStr);
                setOnclickEventforPtag(finalStr);
                setOnclickEventforLitag(finalStr);
              }
            },
            false
          );
      });
  };

  const modifyHtmlP = (data) => {
    const modifiedIgnoreHtml = value.match(/p_ignore_(\d+(d)*)/gm);
    const modifiedReplaceHtml = value.match(/p_replace_(\d+(d)*)/gm);
    if (
      modifiedIgnoreHtml &&
      Array.isArray(modifiedIgnoreHtml) &&
      modifiedIgnoreHtml.length
    )
      modifiedIgnoreHtml.forEach((id) => {
        if (document.getElementById(`${id}`))
          document.getElementById(`${id}`).addEventListener(
            "click",
            () => {
              let replaceStr = "";
              if (id.split("_")[0] === "p" && !suggestionLoader) {
                let strId = `p_${id.split("_")[2]}`;
                replaceStr = `<div contenteditable="false" id="p_div_${
                  id.split("_")[2]
                }".*<span id="p_span_${
                  id.split("_")[2]
                }">&nbsp;<\/span><\/div><\/div>`;
                console.log(
                  new RegExp(replaceStr),
                  data,
                  "new RegExp(replaceStr), data"
                );
                let str = data.replace(new RegExp(replaceStr), "");
                let finalStr = str
                  .replace(`<p id="${strId}"`, "<p")
                  .replace(/<p><br><\/p>/gm, "");
                handleEditorChange(finalStr);
                setOnclickEventforPtag(finalStr);
                setOnclickEventforLitag(finalStr);
              }
            },
            false
          );
      });
    if (
      modifiedReplaceHtml &&
      Array.isArray(modifiedReplaceHtml) &&
      modifiedReplaceHtml.length
    )
      modifiedReplaceHtml.forEach((id) => {
        if (document.getElementById(`${id}`))
          document.getElementById(`${id}`).addEventListener(
            "click",
            () => {
              let strId = `p_${id.split("_")[2]}`;
              let replaceStr = "";
              if (id.split("_")[0] === "p" && !suggestionLoader) {
                const myHTML = document.getElementById(
                  `p_div_${id.split("_")[2]}`
                ).outerHTML;
                const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                replaceStr = `<p id="${strId}".*<span id="p_span_${
                  id.split("_")[2]
                }">&nbsp;<\/span><\/div><\/div>`;
                console.log(
                  new RegExp(replaceStr),
                  data,
                  "new RegExp(replaceStr), data"
                );
                let str = data
                  .replace(/\n/g, "")
                  .replace(new RegExp(replaceStr), `<p>${strippedHtml}`);
                let finalStr = str
                  .replace(/<p><br><\/p>/gm, "")
                  .replace(/IgnoreReplace&nbsp;/gm, "");
                handleEditorChange(finalStr);
                setOnclickEventforPtag(finalStr);
                setOnclickEventforLitag(finalStr);
              }
            },
            false
          );
      });
  };

  // const checkForServerErrorForLi = (item) => {
  //   if (item.id.split("_")[0] === "li" && !suggestionLoader) {
  //     const myHTML = document.getElementById(
  //       item.id.split("_")[0] + "_" + "div_" + `${item.id.split("_")[2]}`
  //     ).outerHTML;
  //     const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
  //     if (
  //       strippedHtml ===
  //       "The server had an error while processing your request due to excessive load. Sorry about that! You can retry your request"
  //     ) {
  //       return true;
  //     } else {
  //       false;
  //     }
  //   }
  // };

  // const checkForServerErrorForP = (item) => {
  //   if (item.id.split("_")[0] === "p" && !suggestionLoader) {
  //     const myHTML = document.getElementById(
  //       item.id.split("_")[0] + "_" + "div_" + `${item.id.split("_")[2]}`
  //     )?.outerHTML;
  //     if (myHTML) {
  //       const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
  //       if (
  //         strippedHtml ===
  //         "The server had an error while processing your request due to excessive load. Sorry about that! You can retry your request"
  //       ) {
  //         return true;
  //       } else {
  //         false;
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (!suggestionLoader) {
      setOnclickEventforPtag(value);
      setOnclickEventforLitag(value);
    }
  }, [suggestionLoader]);

  console.log(value);

  return (
    <div className="list_style">
      {/* {modifyHtmlLi() &&
      Array.isArray(modifyHtmlLi()) &&
      modifyHtmlLi().length ? (
        <div>
          {modifyHtmlLi().map((item) => (
            <div
              style={{
                position: "absolute",
                top: `${item.doc.top + 50}px`,
                left: `${item.doc.left - 60}px`,
                zIndex: 2,
                border: "none",
                // padding: "2px 5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  border: "none",
                  fontWeight: 600,
                  color: "#261C4B",
                  padding: "2px 5px",
                  marginRight: "3px",
                }}
                onClick={() => {
                  let replaceStr = "";
                  if (item.id.split("_")[0] === "li" && !suggestionLoader) {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    replaceStr = `<div contenteditable="false" id="${
                      item.id.split("_")[0] +
                      "_" +
                      "div_" +
                      `${item.id.split("_")[2]}`
                    }".*<span contenteditable="false" id="${
                      item.id
                    }">&nbsp;<\/span><\/div><\/div>`;
                    let str = value.replace(new RegExp(replaceStr), ``);
                    handleEditorChange(
                      str.replace(
                        `<li contenteditable="false" id="${strId}"`,
                        "<li"
                      )
                    );
                  }
                }}
              >
                Ignore?
              </div>
              {checkForServerErrorForLi(item) ? null : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "none",
                    fontWeight: 600,
                    color: "#4389FA",
                    padding: "2px 5px",
                  }}
                  onClick={() => {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    let replaceStr = "";
                    if (item.id.split("_")[0] === "li" && !suggestionLoader) {
                      const myHTML = document.getElementById(
                        item.id.split("_")[0] +
                          "_" +
                          "div_" +
                          `${item.id.split("_")[2]}`
                      ).outerHTML;
                      const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                      replaceStr = `<li contenteditable="false" id="${strId}">.*<span contenteditable="false" id="${item.id}">&nbsp;<\/span><\/div><\/div><\/li>`;
                      let str = value
                        .replace(/\n/g, "")
                        .replace(
                          new RegExp(replaceStr),
                          `<li>${strippedHtml}</li>`
                        );
                      handleEditorChange(str);
                    }
                  }}
                >
                  Replace?
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null} */}
      {/* {modifyHtmlP() && Array.isArray(modifyHtmlP()) && modifyHtmlP().length ? (
        <div>
          {modifyHtmlP().map((item) => (
            <div
              style={{
                position: "absolute",
                top: `${item.doc.top}px`,
                left: `${item.doc.left}px`,
                zIndex: 2,
                // padding: "2px 5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  border: "none",
                  fontWeight: 600,
                  color: "#261C4B",
                  padding: "2px 5px",
                  marginRight: "3px",
                }}
                onClick={() => {
                  let replaceStr = "";
                  if (item.id.split("_")[0] === "p" && !suggestionLoader) {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    replaceStr = `<div contenteditable="false" id="${
                      item.id.split("_")[0] +
                      "_" +
                      "div_" +
                      `${item.id.split("_")[2]}`
                    }".*<span contenteditable="false" id="${
                      item.id
                    }">&nbsp;<\/span><\/div><\/div>`;
                    let str = value.replace(new RegExp(replaceStr), ``);
                    handleEditorChange(
                      str
                        .replace(
                          `<p contenteditable="false" id="${strId}"`,
                          "<p"
                        )
                        .replace(/<p><br><\/p>/gm, "")
                    );
                  }
                }}
              >
                Ignore?
              </div>
              {checkForServerErrorForP(item) ? null : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "none",
                    fontWeight: 600,
                    color: "#4389FA",
                    padding: "2px 5px",
                  }}
                  onClick={() => {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    let replaceStr = "";
                    if (item.id.split("_")[0] === "p" && !suggestionLoader) {
                      const myHTML = document.getElementById(
                        item.id.split("_")[0] +
                          "_" +
                          "div_" +
                          `${item.id.split("_")[2]}`
                      ).outerHTML;
                      const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                      replaceStr = `<p contenteditable="false" id="${strId}">.*<span contenteditable="false" id="${item.id}">&nbsp;<\/span><\/div><\/div>`;
                      let str = value
                        .replace(/\n/g, "")
                        .replace(
                          new RegExp(replaceStr),
                          `<p>${strippedHtml}</p>`
                        );
                      handleEditorChange(str.replace(/<p><br><\/p>/gm, ""));
                    }
                  }}
                >
                  Replace?
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null} */}
    </div>
  );
};

export default NewEditor;
