/*eslint-disable*/
import React from "react";
import Pen from "../../../assets/images/resumeOptimizer/Pen.svg";

const EditButton = ({ handleClick }) => (
  <button
    style={{
      cursor: "pointer",
      fontSize: "16px",
      color: " #4389fa",
      background: "rgba(67, 137, 250, 0.1)",
      borderRadius: "10px",
      border: "none",
      padding: "7px 12px",
    }}
    onClick={handleClick}
  >
    Edit
  </button>
);

export default EditButton;
