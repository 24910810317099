import React from "react";
// import { Rate } from "antd";
import { useSelector } from "react-redux";
import "./bottomContainer.scss";
// import gradientlogo from "../../../../assets/images/tools/firefox.svg";
import SkillsCard from "./skillsCard/skills";
import OverallMatch from "./overallMatch";

// import JobTypes from "./jobTypes";
// import Transitions from "./transitions";
// import KeySkills from "./keySkills";
// import Colleges from "./colleges";

const BottomContainer = () => {
  const hardSkills = useSelector(
    (state) => state.tools.matchJobResults.hardSkills || []
  );
  const softSkills = useSelector(
    (state) => state.tools.matchJobResults.softSkills || []
  );
  const formData = useSelector((state) => state.tools.formData);

  return (
    <div className="mjr_bottomContainer">
      <div className="bottom_header">
        {/* <img src={gradientlogo} alt="firefox" /> */}
        <div>
          <div className="text_holder">{formData.companyname}</div>
          {/* <Rate allowHalf defaultValue={2.5} className="rating_star" /> */}
        </div>
      </div>
      <div className="bottom_body">
        <SkillsCard
          title={`Hard Skills - ${
            hardSkills.filter((item) => item.match === true).length
          } out of ${hardSkills.length}`}
          skills={hardSkills}
        />
        <SkillsCard
          title={`Soft Skills - ${
            hardSkills.filter((item) => item.match === true).length
          } out of ${softSkills.length}`}
          skills={softSkills}
        />
        <OverallMatch />
        {/* <JobTypes />
        <Transitions />
        <KeySkills />
        <Colleges /> */}
      </div>
    </div>
  );
};

export default BottomContainer;
