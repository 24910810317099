import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import "./input.scss";
import ClosedEye from "../../assets/images/profile/closedEye.svg";
import OpenedEye from "../../assets/images/profile/openedEye.svg";

const PasswordInput = ({ label, value, handleChange, name }) => {
  return (
    <div className="textOnInput">
      <p className="label" style={{ top: "-16%" }}>
        {label}
      </p>
      <Input.Password
        className="password_input_custom"
        name={name}
        onChange={handleChange}
        value={value}
        size="small"
        iconRender={(visible) =>
          visible ? (
            <img src={OpenedEye} alt="open" />
          ) : (
            <img src={ClosedEye} alt="close" />
          )
        }
      />
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.string,
  name: PropTypes.string.isRequired,
};

PasswordInput.defaultProps = {
  value: "",
  handleChange: () => {},
};

export default PasswordInput;
