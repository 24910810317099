/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import "../Profile/model.scss";
import {
  resumeUpdateRequest,
  setExperienceSaved,
} from "../../store/resumeOptimizer/resumeOptimizerActions";

const ConfirmationModal = ({ visible, unsavedExperience }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
    console.log(unsavedExperience);
    dispatch(
      resumeUpdateRequest({
        formData: unsavedExperience,
        label: "employment-update",
      })
    );
    // dispatch(setExperienceSaved(""));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(setExperienceSaved(""));
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="confirmationModal"
    >
      <div className="model_body">
        <div>
          <div style={{ display: "block", padding: "15px" }}>
            <h3>You have updated the content in the employement section</h3>
          </div>
          <div className="buttons_container">
            <Button type="primary" className="custom_btn" onClick={handleOk}>
              Save
            </Button>
            <Button type="link" className="custom_btn" onClick={handleCancel}>
              Ignore
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
