// get questions Types
export const GET_QUESTIONS_REQUEST = "persanalityTest/GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS = "persanalityTest/GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "persanalityTest/GET_QUESTIONS_FAILURE";

// get results Types
export const GET_RESULTS_REQUEST = "persanalityTest/GET_RESULTS_REQUEST";
export const GET_RESULTS_SUCCESS = "persanalityTest/GET_RESULTS_SUCCESS";
export const GET_RESULTS_FAILURE = "persanalityTest/GET_RESULTS_FAILURE";

// submit results Types
export const SUBMIT_RESULTS_REQUEST = "persanalityTest/SUBMIT_RESULTS_REQUEST";
export const SUBMIT_RESULTS_SUCCESS = "persanalityTest/SUBMIT_RESULTS_SUCCESS";
export const SUBMIT_RESULTS_FAILURE = "persanalityTest/SUBMIT_RESULTS_FAILURE";

// Reset
export const RESET = "login/RESET";
