/* eslint-disable */
import { put, takeLatest, call, select } from "redux-saga/effects";
import * as RESUMEOPTIMIZERACTIONS from "../resumeOptimizer/resumeOptimizerActions";
import * as TYPES from "./resumeOptimizerTypes";
import Network from "../../network";
import unnlockNotification from "../../components/notification";
import { getSubsCountRequest } from "../newLogin/newLoginAction";
import {
  getJobTrackeresrFailure,
  jobMatchFetchFailure,
  jobMatchFetchRequest,
  setTrackerID,
  showUpdateSkillsModal,
} from "../jobMatch/jobMatchActions";
import History from "../../routes/History";

const { API, Config } = Network;

function* handleRequestResumeVaults() {
  try {
    const getResumeVaultsUrl = Config.resumeOptimizer.getResumeVaultsUrl();
    const result = yield call(API.get, getResumeVaultsUrl, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    if (result?.length === 1) {
      yield put(RESUMEOPTIMIZERACTIONS.setCurrentResumeId(result[0].resume_id));
      yield put(
        RESUMEOPTIMIZERACTIONS.cloneResume({
          name: `${result[0].file_name.split(".")[0]}-1`,
          note: "Duplicated automatically from your primary resume",
          currentResume: result[0].resume_id,
        })
      );
    }
    yield put(RESUMEOPTIMIZERACTIONS.getResumeVaultsSuccess(result));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.getResumeVaultsFailure(error));
  }
}

function* handlePutResumeVaults(action) {
  try {
    const formData = action.payload;
    // console.log(formData, "sagaFormata");
    const putResumeVaultsUrl = Config.resumeOptimizer.putResumeVaultsUrl;
    const result = yield call(
      API.put,
      putResumeVaultsUrl(formData.id),
      { file_name: formData.name, notes: formData.note },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(RESUMEOPTIMIZERACTIONS.putResumeVaultsSuccess(result));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.putResumeVaultsFailure(error));
  }
}

function* cloneResume(action) {
  try {
    const params = action.payload;
    const { name, note, fromTracker, trackerId, currentResume, fromJobMatch } =
      params;
    const resumeVaults = yield select(
      (state) => state.resumeOptimizer.resumeVaults
    );
    const userData = yield select((state) => state.login.userData);

    const clonedResumes = resumeVaults.filter(
      (item) => item.cloned_from_tracker && item.job_match_id === trackerId
    );

    let result;
    if (!(clonedResumes.length && fromTracker)) {
      result = yield call(
        API.post,
        Config.resumeOptimizer.cloneResume(currentResume),
        {
          file_name: name,
          notes: note,
          cloned_from_tracker: `${fromTracker}`,
          job_match_id: trackerId,
        },
        {
          Authorization: localStorage.getItem("unnlockUserToken"), // id_token
        }
      );
    }
    if (result?.status === "SUBSCRIPTION_EXPIRED") {
      unnlockNotification(result.message, "warning", 0, 10);
      yield put(RESUMEOPTIMIZERACTIONS.cloneResumeFailure(""));
    } else {
      if (fromTracker) {
        const clonedResumeId = clonedResumes.length
          ? clonedResumes[0].resume_id
          : result
              .map((item) => item.resume_id)
              .filter(
                (x) => !resumeVaults.map((res) => res.resume_id).includes(x)
              )[0];
        yield put(RESUMEOPTIMIZERACTIONS.setCurrentResumeId(clonedResumeId));
        // yield put(jobMatchFetchFailure());
        // yield put(RESUMEOPTIMIZERACTIONS.setCurrentResumePage("experience"));
        const postdata = {
          matchId: trackerId,
          donotSave: true,
          fromResumeOptimzer: true,
          resume_id: clonedResumeId,
          file_name: name,
        };
        yield put(jobMatchFetchRequest(postdata));
        clonedResumes.length
          ? null
          : yield put(RESUMEOPTIMIZERACTIONS.cloneResumeSuccess(result));
        yield put(RESUMEOPTIMIZERACTIONS.resumeRequest(clonedResumeId));
        // yield put(getJobTrackeresrFailure());
        // History.push({
        //   pathname: `/resumeOptimizer`,
        //   state: {
        //     resumeId: clonedResumeId,
        //     resumeName: name,
        //     trackerId,
        //     fromJobMatch,
        //   },
        // });
        yield put(showUpdateSkillsModal(true));
        // unnlockNotification(
        //   "Please update the unmatched skills once you are in the step 4.",
        //   "success",
        //   0,
        //   10
        // );
        yield put(setTrackerID(trackerId));
      } else {
        yield put(RESUMEOPTIMIZERACTIONS.cloneResumeSuccess(result));
      }
      if (userData?.tier_id === 0)
        yield put(
          getSubsCountRequest({ key: "clone_count", label: "resume clone" })
        );
    }
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.cloneResumeFailure(error));
  }
}

function* getParsedResume(action) {
  try {
    const resumeId = action.payload
      ? action.payload
      : yield select((state) => state.resumeOptimizer.currentResume);

    // console.log(resumeId, "resumeId");

    const result = yield call(
      API.get,
      Config.resumeOptimizer.resumeUrls(resumeId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    yield put(RESUMEOPTIMIZERACTIONS.resumeSuccess(result));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.resumeFailure(error));
    // console.log("resumedataerr:::", error);
  }
}

function* updateResume(action) {
  try {
    const postData = action.payload;
    // console.log(postData, "sagaFormata");
    const resumeId = yield select(
      (state) => state.resumeOptimizer.currentResume
    );
    const putResumesUrl = Config.resumeOptimizer.putResumesUrl;
    const result = yield call(
      API.put,
      putResumesUrl(resumeId, postData.label),
      postData.formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    const payloadData = {
      matchId:
        postData?.updateJobmatch && postData?.trackerID
          ? postData?.trackerID
          : null,
      donotSave: true,
      resume_id: resumeId,
    };
    yield put(RESUMEOPTIMIZERACTIONS.resumeUpdateSuccess(result));
    if (postData.label === "employment-update")
      yield put(
        RESUMEOPTIMIZERACTIONS.syncSkillsForJobMatchRequest(payloadData)
      );
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.resumeUpdateFailure(error));
  }
}

function* handleSyncResume(action) {
  try {
    const postData = action.payload;
    const syncSkillsForJobMatch = Config.resumeOptimizer.syncSkillsForJobMatch(
      postData.resume_id
    );
    const result = yield call(API.get, syncSkillsForJobMatch, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(RESUMEOPTIMIZERACTIONS.syncSkillsForJobMatchSuccess(result));
    if (postData?.matchId) {
      yield put(jobMatchFetchRequest(postData));
    }
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.syncSkillsForJobMatchFailure(error));
  }
}

// function* handleOtheresSectionsRequest() {
//   try {
//     const getOtheresSections = Config.resumeOptimizer.getOtheresSections();
//     const result = yield call(API.post, getOtheresSections, {
//       Authorization: localStorage.getItem("unnlockUserToken"), // id_token
//     });
//     yield put(RESUMEOPTIMIZERACTIONS.getOtheresSectionsSuccess(result));
//   } catch (error) {
//     yield put(RESUMEOPTIMIZERACTIONS.getOtheresSectionsFailure(error));
//   }
// }

function* handlegetSuggestedSkillsRequest(action) {
  try {
    const { label, id, page } = action.payload;
    const getOtheresSections = Config.resumeOptimizer.getSuggestedSkills(
      label,
      id
    );
    const result = yield call(API.get, getOtheresSections, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    const obj = {};
    obj[`${label}_name`] =
      result.specialized_skills_name || result.common_skills_name;
    yield put(RESUMEOPTIMIZERACTIONS.getSkillsSuccess(obj));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.getSkillsFailure(error));
  }
}

function* handleDeleteResumeRequest(action) {
  try {
    const resumeId = action.payload;
    const deleteResume = Config.resumeOptimizer.deleteResume(resumeId);
    const result = yield call(API.delete, deleteResume, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(RESUMEOPTIMIZERACTIONS.resumeDeleteSuccess(result));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.resumeDeleteFailure(error));
  }
}

function* handleDownloadResumePDF(action) {
  try {
    const { data, fileName, format } = action.payload;
    const dowloadInPdfUrl = Config.resumeOptimizer.dowloadInPdf(
      fileName,
      format
    );
    const result = yield call(API.get, dowloadInPdfUrl, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(RESUMEOPTIMIZERACTIONS.downloadResumePdfSuccess(result));
  } catch (error) {
    yield put(RESUMEOPTIMIZERACTIONS.downloadResumePdfFailure(error));
  }
}

export function* requestResumeVaults() {
  yield takeLatest(TYPES.GET_RESUME_VAULTS_REQUEST, handleRequestResumeVaults);
}
export function* requestPutResumeVaults() {
  yield takeLatest(TYPES.PUT_RESUME_VAULTS_REQUEST, handlePutResumeVaults);
}
export function* cloneOriginalResume() {
  yield takeLatest(TYPES.CLONE_RESUME_REQUEST, cloneResume);
}
export function* requestParsedResume() {
  yield takeLatest(TYPES.RESUME_REQUEST, getParsedResume);
}
export function* updatResumeInformation() {
  yield takeLatest(TYPES.UPDATE_RESUME_REQUEST, updateResume);
}
// export function* getOtheresSections() {
//   yield takeLatest(
//     TYPES.GET_OTHERES_SECTIONS_REQUEST,
//     handleOtheresSectionsRequest
//   );
// }
export function* deleteResume() {
  yield takeLatest(TYPES.DELETE_RESUME_REQUEST, handleDeleteResumeRequest);
}
export function* getsuggestedSkills() {
  yield takeLatest(TYPES.GET_SKILLS_REQUEST, handlegetSuggestedSkillsRequest);
}
export function* downloadResumePDF() {
  yield takeLatest(TYPES.DOWNLOAD_RESUME_PDF_REQUEST, handleDownloadResumePDF);
}
export function* SyncResume() {
  yield takeLatest(TYPES.SYNC_JOBMATCHSKILLS_REQUEST, handleSyncResume);
}
