/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";

import FroalaEditor from "react-froala-wysiwyg";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

const GrammerlyDescriptionEditor = ({
  handleEditorChange,
  id,
  value,
  label,
}) => {
  const [localState, setLocalState] = useState(value);
  const buttons = [
    // "paragraphFormat",
    "bold",
    "italic",
    "underline",
    // "strikeThrough",
    "align",
    "formatOL",
    "formatUL",
    // "insertTable",
    // "insertImage",
    // "insertLink",
    "undo",
    "redo",
    // "quote",
    // "fullscreen",
    // "html",
    // "|",
  ];

  const config = {
    toolbarButtons: buttons,
    toolbarButtonsMD: buttons,
    toolbarButtonsSM: buttons,
    toolbarButtonsXS: buttons,
    spellcheck: false,
    placeholderText: "Describe here...",
    key: "nQE2uG3B2D1H1B3B3lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2D1C5G1E1F1H4C10==",
  };

  useEffect(() => {
    const sel = window.getSelection();
    const innerDiv = document.getElementsByClassName("fr-element")[0];
    if (innerDiv) {
      const innerDivText = innerDiv.firstChild;
      sel.collapse(innerDivText, 0);
      innerDiv.parentNode.focus();
    }
  }, []);

  useEffect(() => {
    handleEditorChange(localState, id, false);
  }, [localState]);

  return (
    <div className="job_description_conatiner">
      {label ? (
        <Row justify="space-between">
          <Col>
            <p className="job_description_label">{label}</p>
          </Col>
        </Row>
      ) : null}
      <div className="job_description_inner_conatiner">
        <div className="job_description">
          <GrammarlyEditorPlugin clientId="client_DTbhiJ6wdpZapKyQ5YDMzA">
            <FroalaEditor
              config={config}
              tag="textarea"
              model={localState}
              onModelChange={(e) => {
                setLocalState(e);
              }}
            />
          </GrammarlyEditorPlugin>
        </div>
      </div>
    </div>
  );
};

export default GrammerlyDescriptionEditor;
