import { Bulletes } from "./Data";
import moment from "moment";

const { uuid } = require("uuidv4");
/* eslint-disable */

export const getResumeScreens = (data) => {
  const defaultData = [
    // { title: "Certifications", id: "Certifications" },
    { title: "Licenses & Certifications", id: "LicensesAndCertifications" },
    { title: "Associations", id: "Associations" },
    { title: "Military Experience", id: "MilitaryExperience" },
    { title: "Security Credentials", id: "SecurityCredentials" },
    { title: "Achievements", id: "Achievements" },
    { title: "Training", id: "Training" },
    { title: "Hobbies", id: "Hobbies" },
    { title: "Patents", id: "Patents" },
    { title: "Publications", id: "Publications" },
    { title: "Speaking Engagements", id: "SpeakingEngagements" },
  ];
  const arr = Object.keys(data);
  return [
    ...defaultData.filter((item) => arr.includes(item.id)),
    ...data.others,
  ];
};

export const formatContactData = (contactInfo) => {
  let obj = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    onlineProfile1: "",
    onlineProfile2: "",
    onlineProfile3: "",
  };
  if (contactInfo) {
    try {
      obj = {
        firstName: contactInfo?.GivenName || "",
        lastName: contactInfo?.FamilyName || "",
        formattedName: contactInfo?.FormattedName || "",
        email: contactInfo?.InternetEmailAddress || "",
        phoneNumber: contactInfo?.phone_number || "",
        addressLine1: contactInfo?.DeliveryAddress,
        addressLine2: "",
        city: contactInfo?.Municipality,
        state: contactInfo?.Region,
        country: contactInfo?.CountryCode,
        postalCode: contactInfo?.PostalCode,
        onlineProfile1: contactInfo?.OnlineProfiles[0],
        onlineProfile2: contactInfo?.OnlineProfiles[1],
        onlineProfile3: contactInfo?.OnlineProfiles[2],
      };
    } catch {
      return obj;
    }
  }
  return obj;
};

// const CheckForBreak = (data) => {
//   let arr = data.split("\r\n");
//   if (arr[0]) return [`<li>${arr[0]}</li>`, arr[1]];
//   else return ["", arr[1]];
// };

// const replaceAll = (data) => {
//   let liStr = ``;
//   let ulStr = ``;
//   for (let i = 0; i <= Bulletes.length; i++) {
//     let arr = data.split(Bulletes[i]);
//     if (Array.isArray(arr) && arr.length > 1) {
//       arr.map((item, i) => {
//         if (i && arr.length !== i + 1) {
//           const str = liStr + `<li>${item}</li>`;
//           liStr = replaceAll(str);
//         }
//       });
//       ulStr = `${arr[0]}<ul>${liStr}${
//         CheckForBreak(arr[arr.length - 1])[0]
//       }</ul>${CheckForBreak(arr[arr.length - 1])[1] || ""}`;
//       break;
//     }
//   }
//   if (ulStr) return ulStr;
//   else return data;
// };

// const checkForPTag = (data) => {
//   if (data.includes("<p>") || data.includes("<li>")) return data;
//   else return `<p>${data}</p>`;
// };

// const removeExtraLicontent = (data) => {
//   let arr = data.split("</li></ul>");
//   let resultData = data;
//   for (let i = 0; i <= arr.length; i++) {
//     if (i && arr[i]) {
//       let subStrArr = arr[i].split("<li>");
//       if (
//         !(
//           subStrArr[0].includes("</ul>") ||
//           subStrArr[0].includes("</p>") ||
//           subStrArr[0].includes("<ul>") ||
//           subStrArr[0].includes("<p>") ||
//           subStrArr[0].includes("<li>") ||
//           subStrArr[0].includes("</li>")
//         )
//       ) {
//         if (arr[i - 1].includes("<li>")) {
//           if (subStrArr[0]) {
//             resultData = resultData.replace(subStrArr[0], "");
//           }
//         }
//       }
//     }
//   }
//   return resultData;
// };

const checkForDateValidity = (d) => {
  if (d) {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(d)) {
      const strArr = d.split(format);
      return strArr.join("/");
    } else {
      if (d === "current") {
        return "";
      }
      return moment(d).format("MM/YYYY");
    }
  } else {
    return d;
  }
};

export const formatEmployementData = (employmentdata) => {
  const obj = {
    designation: "",
    company: "",
    location: "",
    from: "",
    to: "",
    jobDescription: "",
  };
  let arr = [];
  if (Array.isArray(employmentdata)) {
    if (employmentdata.length) {
      arr = employmentdata.map((item) => {
        try {
          if (Array.isArray(item?.PositionHistory)) {
            return {
              designation: item?.PositionHistory[0]?.Title,
              company: item?.EmployerOrgName,
              location: item?.PositionHistory[0]?.Municipality,
              from: checkForDateValidity(
                item?.PositionHistory[0]?.StartDate || ""
              ),
              to: checkForDateValidity(item?.PositionHistory[0]?.EndDate || ""),
              iscurrentJob: item?.PositionHistory[0]?.currentEmployer
                ? true
                : false,
              jobDescription: item?.PositionHistory[0]?.Description,
              currentStep: item?.PositionHistory[0]?.currentStep || 0,
              hardSkills: item?.PositionHistory[0]?.HardSkills || [],
              softSkills: item?.PositionHistory[0]?.SoftSkills || [],
            };
          }
          return obj;
        } catch {
          return obj;
        }
      });
      return { employment: arr };
    }
  }

  return {
    employment: [],
  };
};

export const formatEducationData = (educationdata) => {
  const obj = {
    level: "",
    field: "",
    school: "",
    grade: "",
    from: "",
    to: "",
    degreeName: "",
    degreeType: "",
  };
  let arr = [];
  if (Array.isArray(educationdata)) {
    if (educationdata.length) {
      arr = educationdata.map((item) => {
        try {
          const degree = Array.isArray(item?.Degree) ? item?.Degree[0] : "";
          return {
            field: degree?.DegreeMajor,
            school: item?.SchoolName,
            grade: degree?.GPA,
            from: degree?.DatesOfAttendance?.StartDate,
            to: degree?.DatesOfAttendance?.EndDate,
            degreeName: degree?.DegreeName,
            minor: degree?.minor,
            degreeType: degree?.degreeType,
          };
        } catch {
          return obj;
        }
      });
      return { education: arr };
    }
  }

  return {
    education: [],
  };
};

export const formatDashboardSkills = (dashboardSkills) => {
  const obj = {
    title: "",
  };
  let arr = [];
  if (Array.isArray(dashboardSkills)) {
    if (dashboardSkills?.length) {
      arr = dashboardSkills.map((item) => {
        try {
          return {
            title: item?.skill_name,
          };
        } catch {
          return obj;
        }
      });
      return { dashboardSkills: arr };
    }
  }
  return {
    dashboardSkills: [obj],
  };
};

export const formatSkillsForJobMAtch = (jobMatchSkills) => {
  let arr = [];
  if (Array.isArray(jobMatchSkills)) {
    if (jobMatchSkills?.length) {
      arr = jobMatchSkills.map((item) => {
        try {
          return item?.name;
        } catch {
          return "";
        }
      });
      return { jobMatchSkills: arr };
    }
  }
  return {
    jobMatchSkills: [],
  };
};

export const formatCertificateData = (certificatedata) => {
  let arr = [];
  if (Array.isArray(certificatedata)) {
    if (certificatedata?.length) {
      arr[0] = {
        description: certificatedata[0],
      };
      return { certification: arr };
    }
  }
  return {
    certification: [],
  };
};

export const formatLanguageData = (languageData) => {
  let arr = [];
  if (Array.isArray(languageData)) {
    if (languageData.length) {
      arr = languageData.map((item) => {
        try {
          return {
            // Comments: item.Comments,
            LanguageCode: item?.LanguageCode,
            // Read: item.Read,
            // Speak: item.Speak,
            // Write: item.Write,
          };
        } catch {
          return {
            LanguageCode: "",
          };
        }
      });
      return { languages: arr };
    }
  }

  return {
    languages: [],
  };
};
/* eslint-disable */
export const formatSkillsData = (languageData) => {
  // console.log(languageData);
};

export const formatAchievementsData = (achievementsData) => {
  let arr = [];
  if (Array.isArray(achievementsData)) {
    if (achievementsData.length) {
      // arr = achievementsData.map((item) => {
      //   return {
      //     title: item.title,
      //     description: item?.Description || item?.description || "",
      //   };
      // });
      arr[0] = {
        title: achievementsData[0].title,
        description:
          achievementsData[0]?.Description ||
          achievementsData[0]?.description ||
          "",
      };
      return { achievements: arr };
    }
  }

  return {
    achievements: [],
  };
};

export const formatAssociationData = (associationData) => {
  let arr = [];
  if (Array.isArray(associationData)) {
    if (associationData.length) {
      arr = associationData;
      return { association: arr };
    }
  }

  return {
    association: [],
  };
};

export const formatMiliteryData = (militeryData) => {
  const obj = {
    country: "",
    branch: "",
    unit: "",
    startDate: "",
    endDate: "",
  };
  let arr = [];
  if (Array.isArray(militeryData)) {
    if (militeryData.length) {
      arr = militeryData.map((item) => {
        try {
          return {
            country: item?.Country,
            branch: item?.Branch,
            unit: item?.Unit,
            startDate: item?.StartDate,
            endDate: item?.EndDate,
          };
        } catch {
          return obj;
        }
      });
      return { miltaryHistory: arr };
    }
  }
  return {
    miltaryHistory: [],
  };
};

export const formatSecurityData = (securityData) => {
  if (securityData) {
    return {
      description: securityData?.Description || "",
    };
  }
  return {
    description: "",
  };
};

// export const formatOtheres = (data) => {
//   if (data) {
//     return {
//       militeryHistory: data.militeryHistory?.length ? data.militeryHistory : [],
//       patent: data.patent?.length ? data.patent : [],
//     };
//   }
//   return {
//     militeryHistory: [],
//     patent: [],
//   };
// };

export const formatTrainingData = (trainingData) => {
  let arr = [];
  if (Array.isArray(trainingData)) {
    if (trainingData.length) {
      // arr = trainingData.map((item) => {
      //   try {
      //     return {
      //       title: item["sov:TrainingName"],
      //       description: item["sov:Description"],
      //       endDate: item["sov:EndDate"].Year,
      //     };
      //   } catch {
      //     return { title: "", description: "", endDate: "" };
      //   }
      // });
      arr[0] = { description: trainingData[0]["sov:Description"] };
      return { trainingHistory: arr };
    }
  }

  return {
    trainingHistory: [],
  };
};

export const formatReferenceData = (referenceData) => {
  let arr = [];
  if (Array.isArray(referenceData)) {
    if (referenceData.length) {
      arr = referenceData.map((item) => {
        return {
          name: item?.PersonName?.FormattedName || "",
          mobile: Array.isArray(item?.ContactMethod)
            ? item.ContactMethod.filter((i) => i.hasOwnProperty("Telephone"))[0]
                ?.Telephone?.FormattedNumber
            : "",
          email: Array.isArray(item?.ContactMethod)
            ? item.ContactMethod.filter((i) =>
                i.hasOwnProperty("InternetEmailAddress")
              )[0]?.InternetEmailAddress
            : "",
          designation: item?.PositionTitle,
        };
      });
      return { references: arr };
    }
  }
  return {
    references: [
      {
        name: "",
        mobile: "",
        email: "",
        designation: "",
      },
    ],
  };
};

export const formatPiechartData = (skillsData) => {
  if (Array.isArray(skillsData)) {
    if (skillsData.length) {
      const data = skillsData.map((item) => {
        return {
          type: item?.taxonomy,
          value: parseInt(item?.percentOfOverall, 10),
        };
      });
      return data;
    }
  }
  return [];
};

export const formatATSdata = (data) => {
  if (!(Object.entries(data).length === 0 && data.constructor === Object)) {
    let mainArray = { ...data, label: data?.id, id: uuid() };

    Array.isArray(mainArray?.children) &&
      mainArray?.children.length &&
      mainArray?.children.forEach(function iter(a) {
        a["label"] = a?.id;
        a["id"] = uuid();
        Array.isArray(a?.children) && a?.children.forEach(iter);
      });

    return mainArray;
  }
  return "";
};

export const formatToEmployementData = (item) => {
  const obj = {
    EmployerOrgName: item.company,
    PositionHistory: [
      {
        Title: item.designation,
        OrganizationName: item.company,
        Municipality: item.location,
        StartDate: item.from,
        EndDate: item?.iscurrentJob ? "present" : item.to,
        HardSkills: item.skills,
        SoftSkills: item.skills,
        currentEmployer: item?.iscurrentJob || false,
        currentStep: item?.currentStep || 0,
      },
    ],
  };
  return obj;
};

export const formatToEducationData = (item) => {
  return {
    SchoolName: item.school,
    Degree: [
      {
        DegreeMajor: item.field,
        GPA: item.grade,
        DatesOfAttendance: {
          StartDate: item.from,
          EndDate: item.to,
        },
        degreeType: item.degreeType,
        minor: item.minor,
      },
    ],
  };
};

export const formatToCertificateData = (item, i, editorValues) => {
  return { Name: item.title, Description: editorValues[i] };
};

export const formatToMiliteryData = (item) => {
  return {
    Country: item.country,
    Branch: item.branch,
    Unit: item.unit,
    StartDate: item.startDate,
    EndDate: item.endDate,
  };
};

export const formatToTrainingData = (item, i, editorValues) => {
  const obj = {};
  obj["sov:TrainingName"] = item.title;
  obj["sov:Description"] = editorValues[i];
  obj["sov:EndDate"] = {
    Year: item.endDate,
  };
  return obj;
};

export const formatExperienceSummary = (item) => {
  const obj = {};
  obj["sov:MonthsOfWorkExperience"] = item["MonthsOfWorkExperience"];
  return obj;
};
export function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
}

export function findAndUpdate(arr, value) {
  const objIndex = arr.findIndex((item) => item.resume_id === value.resume_id);
  arr[objIndex] = value;
  return arr;
}
