import React, { useEffect, useState, useRef } from "react";
import { Modal, Drawer, Button, Slider, Switch, Form, Space, Input, Tooltip, Popover } from "antd";
import {
  DownloadOutlined,
  ShareAltOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import download from "../../assets/images/resumeVault/download.svg";
import "./index.scss";
import Preview from "./preview";
import CommonInput from "../ICDProvider/commonInput";
import unnlockNotification from "../../components/notification";

const PreviewModal = ({ visible, editDownloadFileName }) => {
  // Create a reference to the Preview component
  const downloadLinkRef = useRef(null);

  const [fileName, setFileName] = useState(
    editDownloadFileName ? "" : "Resume"
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [instanceLoader, setinstanceLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [sectionSpacing, setSectionSpacing] = useState(2);
  const [lineHeight, setlineHeight] = useState(1.5);
  const [fontSize, setFontSize] = useState(14);
  const [fontWeight, setFontWeight] = useState(400);
  // const [enabledSections, setEnabledSections] = useState({
  //   Summary: true,
  //   Contact: true,
  //   Skills: true,
  //   Education: true,
  //   Additional: true,
  // });

  const hidePopover = () => {
    setPopoverOpen(false);
  };

  const handlePopoverOpenChange = (newOpen) => {
    setPopoverOpen(newOpen);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  // const handleSectionToggle = (section, checked) => {
  //   setEnabledSections({ ...enabledSections, [section]: checked });
  // };

  // Function to be executed when the button is clicked
  const handleDownload = () => {
    if (downloadLinkRef && downloadLinkRef.current && fileName) {
      downloadLinkRef.current.download = `${fileName}.pdf`;
      downloadLinkRef.current.click();
      hidePopover();
    }
    if (!fileName) {
      unnlockNotification("Please Enter File Name", "warning");
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setShouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      closable={false}
      closeIcon={null}
      footer={null}
      className="preview_model"
      width={editDownloadFileName ? "5.5in" : "8.5in"}
      bodyStyle={{
        paddingTop: 20,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      }}
    >
      {editDownloadFileName ? (
        <div style={{ padding: "10px" }}>
          <h3>Please Enter the file.</h3>
          <CommonInput
            label=""
            name="companyName"
            placeholder="Enter file name"
            value={fileName}
            handleChange={(e) => setFileName(e.target.value)}
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              border: "none",
              background: "transparent",
            }}
          />
          <Button
            type="primary"
            disabled={instanceLoader}
            onClick={handleDownload}
            style={{ marginRight: 20 }}
          >
            {instanceLoader ? "Loading..!" : "Download"}
          </Button>
          <Button type="link" className="custom_btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space.Compact style={{ marginRight: "0.75in" }}>
              <Tooltip title="Customize">
                <Button onClick={showDrawer} icon={<ShareAltOutlined />} />
              </Tooltip>
              <Popover 
                content={
                  <Space.Compact>
                    <Input defaultValue={fileName ? fileName : "Resume"} onChange={handleFileNameChange} addonAfter=".pdf" style={{width: "200px"}}/>
                    <Tooltip title="Download">
                      <Button type="primary" onClick={handleDownload} icon={<CheckOutlined />}/>
                    </Tooltip>
                  </Space.Compact>
                }
                title="Enter filename:"
                trigger="click"
                open={popoverOpen}
                onOpenChange={handlePopoverOpenChange}
              >
                <Tooltip title="Download">
                  <Button icon={<DownloadOutlined/>} />
                </Tooltip>
              </Popover>
              <Tooltip title="Close">
                <Button onClick={handleCancel} icon={<CloseOutlined />} />
              </Tooltip>
            </Space.Compact>
          </div>
          
          <Drawer
            title="Customize Style"
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={isDrawerVisible}
            getContainer={false}
            maskClosable={false}
            mask={false}
            style={{ position: "absolute", width: "50%", left: "8.1in" }}
          >
            <Form layout="vertical">
              {/* Updated Antd Version to get Color Picker Component 
              <Form.Item label="Primary Color">
                <HexColorPicker color={color} onChange={onChange} />
                <ColorPicker showText={true}
                  presets={[
                    {
                      label: 'Recommended',
                      colors: [
                        '#000000',
                        '#000000E0',
                        '#000000A6',
                        '#00000073',
                        '#00000040',
                        '#00000026',
                        '#0000001A',
                        '#00000012',
                        '#0000000A',
                        '#00000005',
                        '#F5222D',
                        '#FA8C16',
                        '#FADB14',
                        '#8BBB11',
                        '#52C41A',
                        '#13A8A8',
                        '#1677FF',
                        '#2F54EB',
                        '#722ED1',
                        '#EB2F96',
                        '#F5222D4D',
                        '#FA8C164D',
                        '#FADB144D',
                        '#8BBB114D',
                        '#52C41A4D',
                        '#13A8A84D',
                        '#1677FF4D',
                        '#2F54EB4D',
                        '#722ED14D',
                        '#EB2F964D',
                      ],
                    }
                  ]}
                />
              </Form.Item> */}
              <Form.Item label="Section Spacing">
                <Slider
                  defaultValue={sectionSpacing}
                  min={1}
                  max={5}
                  step={1}
                  onAfterChange={setSectionSpacing}
                />
              </Form.Item>
              <Form.Item label="Line Spacing">
                <Slider
                  defaultValue={lineHeight}
                  min={1}
                  max={2}
                  step={0.1}
                  onAfterChange={setlineHeight}
                />
              </Form.Item>
              <Form.Item label="Font Size">
                <Slider
                  defaultValue={fontSize}
                  min={9}
                  max={16}
                  step={1}
                  onAfterChange={setFontSize}
                />
              </Form.Item>
              <Form.Item label="Font Weight">
                <Slider
                  defaultValue={fontWeight}
                  min={100}
                  max={900}
                  step={100}
                  onAfterChange={setFontWeight}
                />
              </Form.Item>
              {/* <Form.Item label="Sections">
                {[
                  "Subtitle",
                  "Summary",
                  "Contact",
                  "Skills",
                  "Education",
                  "Additional",
                ].map((section) => (
                  <div key={section} style={{ marginBottom: 10 }}>
                    <Switch
                      checked={enabledSections[section]}
                      onChange={(checked) =>
                        handleSectionToggle(section, checked)
                      }
                    />
                    <span style={{ marginLeft: 8 }}>{section}</span>
                  </div>
                ))}
              </Form.Item> */}
            </Form>
          </Drawer>
        </>
      )}
      <div
        style={editDownloadFileName ? { visibility: "hidden", height: 0 } : {}}
      >
        <Preview
          key={`${sectionSpacing}-${lineHeight}-${fontSize}-${fontWeight}`}
          pdfOnly={false}
          downloadLinkRef={downloadLinkRef}
          isEdit={false}
          sectionSpacing={sectionSpacing}
          lineHeight={lineHeight}
          fontSize={fontSize}
          fontWeight={fontWeight}
          //enabledSections={enabledSections}
          setinstanceLoader={setinstanceLoader}
        />
      </div>
    </Modal>
  );
};

export default PreviewModal;
