import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import NewHeader from "../components/newHeader";
import { Helmet } from "react-helmet";
class UnnlockLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const {
      loginState,
      dashboardState,
      dontDisplayHeader,
      optionalHeader,
      toolState,
    } = this.props;
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100%",
            overflowX: "hidden",
            textAlign: "center",
          }}
        >
          <h3>Something went wrong!</h3>
        </div>
      );
    }
    return (
      <div
        style={{
          width: "100vw",
          height: "100%",
          background: "rgb(233, 241, 255)",
          // background: "#E9F1FF",
          overflowX: "hidden",
        }}
      >
        {toolState?.showCookies ? (
          <Helmet>
            {window?.location.hostname === "dev.unnlock.ai" ? (
              <script
                type="text/javascript"
                src="https://app.termly.io/embed.min.js"
                data-auto-block="on"
                data-website-uuid="ca79926a-5727-42a7-a0f8-36c1df897cff"
              ></script>
            ) : (
              <script
                type="text/javascript"
                src="https://app.termly.io/embed.min.js"
                data-auto-block="on"
                data-website-uuid="97e50d15-b061-4f2f-949c-979010f38de2"
              ></script>
            )}
          </Helmet>
        ) : null}
        <Spin
          size="large"
          spinning={
            loginState.loader ||
            loginState.subscriptionLoader ||
            dashboardState.loader
          }
        >
          {dontDisplayHeader ? null : <NewHeader />}
          {optionalHeader}
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <main style={{ width: "100%", maxWidth: "1420px" }}>
              {this.props.children}
            </main>
          </div>
        </Spin>
      </div>
    );
  }
}

export default connect((state) => ({
  loginState: state.login,
  dashboardState: state.dashboard,
  toolState: state.tools,
}))(UnnlockLayout);
