import React from "react";
import computer from "../../../../../assets/images/tools/computer.svg";
import infoCircle from "../../../../../assets/images/tools/infoCircle.svg";
import "./match.scss";

const OverallMatch = () => {
  return (
    <div className="match_container">
      <div className="title_container">
        <div className="title">Overall Match - Low</div>
        <img src={infoCircle} alt="tv" />
      </div>
      <img src={computer} alt="tv" className="image_tv" />
      <div className="blue_container">
        <div className="sub_title">Domain Match</div>
        <div className="texts">
          <div>
            <p>Industry</p>
            <div className="sub_title">High Match</div>
          </div>
          <div>
            <p>Similarity Index</p>
            <div className="sub_title">Low</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallMatch;
