// Resume Parse Types
export const RESUME_PARSER_REQUEST = "dashboard/RESUME_PARSER_REQUEST";
export const RESUME_PARSER_SUCCESS = "dashboard/RESUME_PARSER_SUCCESS";
export const RESUME_PARSER_FAILURE = "dashboard/RESUME_PARSER_FAILURE";

// Extract Skills Types
export const EXTRACT_SKILLS_REQUEST = "dashboard/EXTRACT_SKILLS_REQUEST";
export const EXTRACT_SKILLS_SUCCESS = "dashboard/EXTRACT_SKILLS_SUCCESS";
export const EXTRACT_SKILLS_FAILURE = "dashboard/EXTRACT_SKILLS_FAILURE";

// Accept OverLay
export const ACCEPT_OVERlAY_REQUEST = "dashboard/ACCEPT_OVERlAY_REQUEST";
export const ACCEPT_OVERlAY_SUCCESS = "dashboard/ACCEPT_OVERlAY_SUCCESS";
export const ACCEPT_OVERlAY_FAILURE = "dashboard/ACCEPT_OVERlAY_FAILURE";

// save company Details
export const SAVE_COMPANY_DATILS_REQUEST =
  "dashboard/SAVE_COMPANY_DATILS_REQUEST";
export const SAVE_COMPANY_DATILS_SUCCESS =
  "dashboard/SAVE_COMPANY_DATILS_SUCCESS";
export const SAVE_COMPANY_DATILS_FAILURE =
  "dashboard/SAVE_COMPANY_DATILS_FAILURE";

// get company filter Details
export const GET_COMPANY_FILTERS_DATILS_REQUEST =
  "dashboard/GET_COMPANY_FILTERS_DATILS_REQUEST";
export const GET_COMPANY_FILTERS_DATILS_SUCCESS =
  "dashboard/GET_COMPANY_FILTERS_DATILS_SUCCESS";
export const GET_COMPANY_FILTERS_DATILS_FAILURE =
  "dashboard/GET_COMPANY_FILTERS_DATILS_FAILURE";

export const RESET = "dashboard/RESET";
