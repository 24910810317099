import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in";

import "./style.scss";

import teacher from "../../assets/images/teacher.svg";
import ticksquareupload from "../../assets/images/ticksquareupload.svg";
import briefcaseupload from "../../assets/images/briefcaseupload.svg";
import documenttxtupload from "../../assets/images/documenttxtupload.svg";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";

const UploadAtsView = (props) => {
  const {
    name,
    employInfo,
    skills,
    education,
    ExperienceSummaryDetails,
    handleNextClick,
  } = props;

  const [showNextButton, setShowNextButon] = useState(true);

  useEffect(() => {
    if (window) {
      if (window.location.pathname == "/resumeVault") {
        setShowNextButon(false);
      } else {
        setShowNextButon(true);
      }
    }
  }, [location]);

  const experienceData = ExperienceSummaryDetails?.MonthsOfWorkExperience;

  const years = parseInt(parseInt(experienceData) / 12);
  const months = parseInt(experienceData) - years * 12 || 0;

  const educationText = [
    education?.education[0]?.school,
    education?.education[0]?.field,
  ].filter((item) => item && item !== null);

  document.title = "Resume ATS overview";

  return (
    <div className="uploaddiv">
      <div className="welcomeTitle">
        <p className="extractTitle" style={{ margin: "8px" }}>
          Welcome {name}!
        </p>
        {showNextButton && 
           <button
           onClick={() => handleNextClick()}
           type="button"
           className="nxtButton"
           htmlType="submit"
         >
           <>
             Next <img src={WhiteArrowLeft} />
           </>
         </button>
        }
      </div>

      <p
        className="dragtext"
        style={{
          border: "2px solid rgb(213, 224, 243)",
          borderRadius: "12px",
          padding: "32px",
          marginLeft: "0px",
        }}
      >
        This is how your resume gets parsed by a real applicant tracking system
        (ATS) when you apply online.
      </p>
      <FadeIn delay={1200}>
        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex" }}>
            <img src={briefcaseupload} alt="" />
            <p className="atsTitletxt">Work Experience</p>
          </div>
          <p className="atsSubtxt">
            {years} Years, {months} Months
          </p>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <img src={documenttxtupload} alt="" />
            <p className="atsTitletxt">Job History</p>
          </div>
          <ol style={{ fontSize: "16px" }}>
            {employInfo.map((item, i) => {
              if (i <= 2)
                return (
                  <li
                    style={{
                      lineHeight: "24px",
                      color: "rgba(38, 28, 75, 0.8)",
                    }}
                  >
                    {item?.company} | {item?.designation} | {item?.from}{" "}
                    {item?.from && item?.to ? "to" : null} {item?.to}
                  </li>
                );
            })}
          </ol>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex" }}>
            <img src={ticksquareupload} alt="" />
            <p className="atsTitletxt">Hard Skills</p>
          </div>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            <p className="atsSubtxt">
              {skills
                .filter((item) => item.id === "ST1")
                .map((item) => item.name.split("(")[0])
                // .slice(0, 20)
                .join(", ")}
            </p>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex" }}>
            <img src={ticksquareupload} alt="" />
            <p className="atsTitletxt">Soft Skills</p>
          </div>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            <p className="atsSubtxt">
              {skills
                .filter((item) => item.id === "ST2")
                .map((item) => item.name.split("(")[0])
                // .slice(0, 20)
                .join(", ")}
            </p>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex" }}>
            <img src={teacher} alt="" />
            <p className="atsTitletxt">Education</p>
          </div>
          <p className="atsSubtxt">{educationText.join(", ")}</p>
        </div>
        <div
          style={{
            marginTop: "40px",
            border: "2px solid #d5e0f3",
            borderRadius: "12px",
            padding: "32px",
          }}
        >
          <p
            className="atsTitletxt"
            style={{ marginLeft: "0px", marginBottom: "8px" }}
          >
            See something wrong?
          </p>
          <p className="dragtext" style={{ margin: "0px" }}>
            ATS errors occur due to resume formatting issues. Don’t worry, we
            will help you fix them before you apply.
          </p>
          <p
            className="atsTitletxt"
            style={{
              marginLeft: "0px",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            Why it matters
          </p>
          <p className="dragtext" style={{ margin: "0px" }}>
            Many recruiters see a summary like this before deciding to look
            closer or move on.
          </p>
        </div>
      </FadeIn>
    </div>
  );
};

export default UploadAtsView;
