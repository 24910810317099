import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Layout, Radio, Input, Select } from "antd";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import * as COMPANYActions from "../../store/componies/action";
import UnnlockLayout from "../layout";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { isArray, isEmpty } from "lodash";
import NewHeader from "../../components/newHeader";
import history from "../../routes/History";
import "./OfferEvaluator.scss";
import SearchInput from "../Dashboard/searchField";

import suitcase from "../../assets/images/offer/suitcase.svg";
import orgnization from "../../assets/images/offer/orgnization.svg";
import jobtitle from "../../assets/images/offer/jobtitle.svg";
import amazonlogo from "../../assets/images/offer/amazonlogo.svg";
import search from "../../assets/images/offer/search.svg";
import dashiconsmoney from "../../assets/images/offer/dashiconsmoney.svg";

import offerevalcompanyicon from "../../assets/images/offer/offerevalcompanyicon.svg";
import offerevaluser from "../../assets/images/offer/offerevaluser.svg";
import offerevallocation from "../../assets/images/offer/offerevallocation.svg";
import greencirletick from "../../assets/images/offer/greencirletick.svg";

import ArrowRight from "../../assets/images/ArrowRight.svg";
import ArrowLeft from "../../assets/images/ArrowLeft.svg";

import {
  getDashboardComponyDeatailsRequest,
  getMatchIndustryRequest,
} from "../../store/componies/action";
import CommonInput from "./OffereInput";
import IndustryDropDown from "../ICDProvider/industryDropDown";

const { Content } = Layout;
const { Option } = Select;

const DisplayingErrorMessagesSchema = Yup.object().shape({
  comp_name: Yup.string().required("Required"),
  curr_base: Yup.string().required("Required"),
  new_base: Yup.string().required("Required"),
  industry: Yup.string().required("Required"),
  job_title: Yup.string().required("Required"),
  comp_size: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
});

const optionsCompSize = [
  { value: "51 - 200", title: "51 - 200" },
  { value: "201 - 500", title: "201 - 500" },
  { value: "501 - 1000", title: "501 - 1000" },
  { value: "1001 - 5000", title: "1001 - 5000" },
  { value: "5001 - 10000", title: "5001 - 10000" },
  { value: "10000+", title: "10000+" },
];

const OfferEvaluator = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const location = useLocation();
  const loginLoader = useSelector((state) => state.login.loader);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );
  const currentCompany = useSelector((state) => state.profile.currentCompany);
  const isCompanyFound = useSelector((state) => state.componies.isFailed);
  const industryMatchObj = useSelector(
    (state) => state.componies.offerEvalIndustryMatch
  );
  const [isEvaluateClick, setEvaluateClick] = useState(false);
  const [existingCompanyDetails, setExistingCompanyDetails] = useState([]);
  const [newCompanyDetails, setNewCompanyDetails] = useState([]);
  const [showCurrCompensationScreen, setShowCurrCompensationScreen] =
    useState(true);

  const dashboardState = useSelector((state) => state.dashboard);
  const [industries, setIndustries] = useState(
    dashboardState.companyFilteres?.industry_list || []
  );
  const [industrySelected, setIndustrySelected] = useState("");

  const formRef = useRef();

  const [details, setDetails] = useState([
    { label: "Company Name", value: "", type: "text", name: "name" },
    {
      label: "Employees Size",
      value: "",
      type: "select",
      name: "size",
      options: dashboardState.companyFilteres?.size_list || [],
    },
    {
      label: "Industry",
      value: "",
      type: "select",
      name: "industry",
      options: dashboardState.companyFilteres?.industry_list || [],
    },
    { label: "Company Website", value: "", type: "text", name: "url" },
    {
      label: "Company Type",
      value: "",
      type: "select",
      name: "company_type",
      options: dashboardState.companyFilteres?.type_list || [],
    },
    { label: "Location", value: "", type: "text", name: "location" },
  ]);

  const handleChange = (e) => {
    let foundIndex = details.map((item) => item.name).indexOf(e.target.name);
    let arr = [...details];
    arr[foundIndex] = { ...arr[foundIndex], value: e.target.value };
    setDetails(arr);
  };

  const handleSearch = (newValue) => {
    if (newValue) {
      setIndustries(
        [...industries].filter((item) => {
          if (item.toUpperCase().indexOf(newValue.toUpperCase()) > -1) {
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      setIndustries(dashboardState.companyFilteres?.industry_list || []);
    }
  };

  const [initialFormData, setinitialFormData] = useState({
    curr_base: "",
    curr_bonus: "",
    curr_grantval: "",
    unvested: "",
    val_unvested: "",
    relocate: "",

    comp_name: "",
    industry: "",
    job_title: "",
    comp_size: "",
    location: "",

    new_base: "",
    new_bonus: "",
    new_equity: "",
  });

  useEffect(() => {
    if (location && location.state) {
      const objForm = location.state.enteredFormData;
      const compCurrObject = location.state.sentCompanyDetails;
      if (compCurrObject) {
        setExistingCompanyDetails(compCurrObject);
      }
      setinitialFormData(objForm);
    }

    if (!dashboardState && !dashboardState.companyFilteres?.industry_list) {
      setIndustries(dashboardState.companyFilteres?.industry_list || []);
    }
  }, [location, dashboardState]);

  const handleSubmit = (data) => {
    console.log("Submit data ", data);

    setinitialFormData(data);

    const obj = {
      industry1: existingCompanyDetails[0].industry
        ? existingCompanyDetails[0].industry
        : "",
      industry2:
        companyDetails && Array.isArray(companyDetails) && companyDetails.length
          ? companyDetails[0].industry
          : "",
    };

    dispatch(COMPANYActions.getMatchIndustryRequest(obj));

    // history.push({
    //   pathname: "/OfferEvaluatorResult",
    //   state: {
    //     offerObject: data,
    //     companyDetails: (companyDetails && Array.isArray(companyDetails) && companyDetails.length) ? companyDetails[0] : null,
    //     currentCompData: {
    //       currentCompName: currentCompany.name,
    //       currDesig: employementInfo?.employment[0]?.designation,
    //       currCompDetails: existingCompanyDetails,
    //     },
    //   },
    // });
  };

  const handleSelect = (e) => {
    formRef.current.setFieldValue("comp_name", e.text);
    if (e && e.value) {
      dispatch(
        getDashboardComponyDeatailsRequest({
          // company_name: e.text,
          unnlock_id: e.value,
        })
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(industryMatchObj) && isEvaluateClick) {
      setEvaluateClick(false);
      history.push({
        pathname: "/OfferEvaluatorResult",
        state: {
          offerObject: initialFormData,
          companyDetails:
            newCompanyDetails &&
            Array.isArray(newCompanyDetails) &&
            newCompanyDetails.length
              ? newCompanyDetails[0]
              : null,
          currentCompData: {
            currentCompName: currentCompany.name,
            currDesig: employementInfo?.employment[0]?.designation,
            currCompDetails: existingCompanyDetails,
          },
          MatchIndustryObj: industryMatchObj,
        },
      });
    }
  }, [industryMatchObj]);

  useEffect(() => {
    if (
      currentCompany &&
      !isEmpty(currentCompany) &&
      currentCompany.unnlock_id != 0
    ) {
      dispatch(
        getDashboardComponyDeatailsRequest({
          unnlock_id: currentCompany.unnlock_id,
        })
      );
    }
  }, [currentCompany]);

  useEffect(() => {
    if (document.getElementById("evaluete_button_id"))
      document.getElementById("evaluete_button_id").click();
  }, [loginLoader]);

  useEffect(() => {
    if (
      existingCompanyDetails.length <= 0 &&
      companyDetails &&
      companyDetails.length //&&
      //companyDetails[0].company === currentCompany.name
    ) {
      setExistingCompanyDetails(companyDetails);
    } else if (existingCompanyDetails.length <= 0 && companyDetails) {
      let arr = [
        {
          company: currentCompany.name,
          company_size: currentCompany.size,
          logo_url: null,
          industry: currentCompany.industry,
          type: currentCompany.company_type,
          unnlock_id: currentCompany.unnlock_id,
        },
      ];
      setExistingCompanyDetails(arr);
    } else if (
      isArray(companyDetails) &&
      companyDetails.length > 0 &&
      companyDetails[0].company !== currentCompany.name
    ) {
      setNewCompanyDetails(companyDetails);
      let obj = {
        target: {
          name: "industry",
          value: companyDetails[0].industry,
        },
      };
      handleChange(obj);
      setIndustrySelected(companyDetails[0].industry);
      formRef.current.setFieldValue("industry", companyDetails[0].industry);
      formRef.current.setFieldValue(
        "comp_size",
        companyDetails[0].company_size
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    if (
      Array.isArray(dashboardState.topCompanies) &&
      dashboardState.topCompanies.length
    ) {
      handleChange({
        target: { value: dashboardState.topCompanies[0].name, name: "name" },
      });
    }
  }, [dashboardState.topCompanies]);

  const options = industries.map((i) => <Option key={i}>{i}</Option>);

  return (
    <UnnlockLayout>
      <div className="offerEvaluatorContainer">
        <Content>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#FFFFFF",
              padding: "15px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {showCurrCompensationScreen ? (
                <div className="numbercircle">
                  <p className="numbertext">1</p>
                </div>
              ) : (
                <img src={greencirletick} alt="" />
              )}
              <p
                className="addResumeText"
                style={{
                  color: showCurrCompensationScreen ? "#4389fa" : "#6EBF67",
                }}
              >
                CURRENT COMPENSATION
              </p>
            </div>
            <span className="line"></span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="numbercircle"
                style={{
                  background: showCurrCompensationScreen
                    ? "#606060"
                    : "#4389fa",
                }}
              >
                <p className="numbertext">2</p>
              </div>
              <p
                className="addResumeText"
                style={{
                  color: showCurrCompensationScreen ? "#606060" : "#4389fa",
                }}
              >
                YOUR NEW OFFER
              </p>
            </div>
          </div>

          <div className="contentContainer" style={{ padding: "32px" }}>
            <Formik
              initialValues={initialFormData}
              validationSchema={DisplayingErrorMessagesSchema}
              enableReinitialize
              onSubmit={(values) => {
                setEvaluateClick(true);
                handleSubmit(values);
              }}
              innerRef={formRef}
            >
              {({ errors, touched, setFieldValue, validateField, values }) => (
                <Form>
                  {showCurrCompensationScreen && (
                    <>
                      <Row>
                        <Col md={24} style={{ paddingRight: "15px" }}>
                          <div className="bigcard">
                            <Row className="headersec">
                              <img src={dashiconsmoney} alt="dash" />
                              <p
                                className="compsatitle"
                                style={{ marginLeft: "24px" }}
                              >
                                Current Compensation
                              </p>
                            </Row>
                            <Row>
                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="curr_base"
                                  placeholder="Base Salary (in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>

                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginLeft: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="curr_bonus"
                                  placeholder="Bonus (in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>
                            </Row>
                            <div
                              className="error_fields"
                              style={{
                                display:
                                  touched.curr_base && errors.curr_base
                                    ? ""
                                    : "transparent",
                              }}
                            >
                              {errors?.curr_base}
                            </div>

                            <Row className="headersec">
                              <img src={dashiconsmoney} alt="dash" />
                              <p
                                className="compsatitle"
                                style={{ marginLeft: "24px" }}
                              >
                                Equity/Stock options
                              </p>
                            </Row>
                            <Row>
                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="curr_grantval"
                                  placeholder="Grant Value (in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>

                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="val_unvested"
                                  placeholder="Unvested options value(in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>
                            </Row>
                            <div
                              className="cardsec"
                              style={{ marginTop: "40px" }}
                            >
                              <p
                                className="compsatitle"
                                style={{ width: "40%", fontWeight: "400" }}
                              >
                                Do you have opportunities in your current
                                company?
                              </p>
                              <Radio.Group defaultValue="b">
                                <Radio value="a">Yes</Radio>
                                <Radio value="b">No</Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <button
                        onClick={() => {
                          validateField("curr_base");
                          if (values.curr_base != "")
                            setShowCurrCompensationScreen(
                              !showCurrCompensationScreen
                            );
                        }}
                        style={{
                          cursor: "pointer",
                          padding: "0px 10%",
                          marginTop: "60px",
                          marginBottom: "60px",
                          background: "none",
                          border: "none",
                          marginLeft: "39%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="bottomtext">Next</p>
                          <img
                            src={ArrowRight}
                            alt=""
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      </button>
                    </>
                  )}

                  {!showCurrCompensationScreen && (
                    <>
                      <Row>
                        <Col md={24} style={{ paddingRight: "15px" }}>
                          <div className="bigcard">
                            <Row className="headersec">
                              <img src={dashiconsmoney} alt="dash" />
                              <p
                                className="compsatitle"
                                style={{ marginLeft: "24px" }}
                              >
                                New Company Details
                              </p>
                            </Row>
                            <Row>
                              <Col md={11}>
                                <SearchInput
                                  name="comp_name"
                                  placeholder="Enter New Company Name"
                                  onChange={handleSelect}
                                  setselectedComp={() => {}}
                                  style={{
                                    border: "1px solid #dee5ff",
                                    padding: "10px 15px 6px",
                                    borderRadius: "8px",
                                  }}
                                  initialEmpty
                                />
                                <div
                                  className="error_fields"
                                  style={{
                                    color:
                                      touched.comp_name && errors.comp_name
                                        ? ""
                                        : "transparent",
                                  }}
                                >
                                  {errors?.comp_name}
                                </div>
                              </Col>
                              <Col md={11}>
                                <Field
                                  name="industry"
                                  render={({ field }) => (
                                    <IndustryDropDown
                                      {...field}
                                      name="industry"
                                      placeholder="Industry"
                                      value={industrySelected}
                                      handleChange={(e) => {
                                        let obj = {
                                          target: {
                                            name: "industry",
                                            value: e,
                                          },
                                        };
                                        handleChange(obj);
                                      }}
                                      styles={{
                                        border: "1px solid #dee5ff",
                                        marginBottom: "13px",
                                        padding: "10px 15px 6px",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  )}
                                />
                                <div
                                  className="error_fields"
                                  style={{
                                    color:
                                      touched.industry && errors.industry
                                        ? ""
                                        : "transparent",
                                  }}
                                >
                                  {errors?.industry}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Field
                                  name="job_title"
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        type="text"
                                        className="cardtextinput"
                                        placeholder="Enter Job Title"
                                        prefix={
                                          <img
                                            src={offerevaluser}
                                            alt="search"
                                          />
                                        }
                                      />
                                      <div
                                        className="error_fields"
                                        style={{
                                          color:
                                            touched.job_title &&
                                            errors.job_title
                                              ? ""
                                              : "transparent",
                                        }}
                                      >
                                        {errors?.job_title}
                                      </div>
                                    </>
                                  )}
                                />
                              </Col>
                              <Col md={12}>
                                {/* <Field
                                name="comp_size"
                                render={({ field }) => (
                                  <>
                                    <Input
                                      {...field}
                                      type="text"
                                      className="cardtextinput"
                                      placeholder="Select Company Size"
                                      prefix={<img src={offerevalcompanyicon} alt="search" />}
                                    />
                                    <div
                                      className="error_fields"
                                      style={{
                                        color:
                                          touched.comp_size && errors.comp_size
                                            ? ""
                                            : "transparent",
                                      }}
                                    >
                                      {errors?.comp_size}
                                    </div>
                                  </>
                                )}
                              /> */}

                                <Field
                                  as="select"
                                  name="comp_size"
                                  defaultValue="Select"
                                  className="cardtextinput"
                                  style={{
                                    paddingRight: "20px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  {optionsCompSize.map(({ value, title }) => (
                                    <option
                                      key={`${value}-${title}`}
                                      value={value}
                                      placeholder="Select Company Size"
                                    >
                                      {title}
                                    </option>
                                  ))}
                                </Field>
                                <div
                                  className="error_fields"
                                  style={{
                                    color:
                                      touched.comp_size && errors.comp_size
                                        ? ""
                                        : "transparent",
                                  }}
                                >
                                  {errors?.comp_size}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Field
                                  name="location"
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        type="text"
                                        className="cardtextinput"
                                        placeholder="Enter Location"
                                        prefix={
                                          <img
                                            src={offerevallocation}
                                            alt="search"
                                          />
                                        }
                                      />
                                      <div
                                        className="error_fields"
                                        style={{
                                          color:
                                            touched.location && errors.location
                                              ? ""
                                              : "transparent",
                                        }}
                                      >
                                        {errors?.location}
                                      </div>
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={24} style={{ paddingRight: "15px" }}>
                          <div className="bigcard">
                            <Row className="headersec">
                              <img src={dashiconsmoney} alt="dash" />
                              <p
                                className="compsatitle"
                                style={{ marginLeft: "24px" }}
                              >
                                New OfferCompensation
                              </p>
                            </Row>
                            <Row>
                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="new_base"
                                  placeholder="Base Salary (in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>
                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="new_bonus"
                                  placeholder="Bonus (in $)"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>
                            </Row>
                            <div className="cardsec">
                              <div
                                className="error_fields"
                                style={{
                                  display:
                                    touched.new_base && errors.new_base
                                      ? ""
                                      : "transparent",
                                }}
                              >
                                {errors?.new_base}
                              </div>
                            </div>
                            <Row>
                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <CommonInput
                                  formField
                                  name="new_equity"
                                  placeholder=" Equity/Stock Options"
                                  type="text"
                                  style={{ marginLeft: "0px" }}
                                />
                              </Col>

                              <Col
                                md={10}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: "1",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="compsatitle"
                                    style={{
                                      fontWeight: "400",
                                      marginRight: "16px",
                                    }}
                                  >
                                    Do you need to relocate?
                                  </p>
                                  <Radio.Group defaultValue="b">
                                    <Radio value="a">Yes</Radio>
                                    <Radio value="b">No</Radio>
                                  </Radio.Group>
                                </div>
                              </Col>
                            </Row>
                            <div
                              style={{ display: "flex", paddingLeft: "40px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flex: "1",
                                  alignItems: "center",
                                  paddingRight: "8px",
                                  marginTop: "40px",
                                }}
                              >
                                <p
                                  className="compsatitle"
                                  style={{ width: "40%", fontWeight: "400" }}
                                >
                                  Is this promotion in responsibilities and
                                  position ?
                                </p>
                                <Radio.Group defaultValue="b">
                                  <Radio value="a">Yes</Radio>
                                  <Radio value="b">No</Radio>
                                </Radio.Group>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "60px",
                        }}
                      >
                        <Col>
                          <div
                            onClick={() =>
                              setShowCurrCompensationScreen(
                                !showCurrCompensationScreen
                              )
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={ArrowLeft}
                              alt=""
                              style={{ marginLeft: "5px" }}
                            />
                            <p className="bottomtext">Back</p>
                          </div>
                        </Col>
                        <Col>
                          <button
                            type="submit"
                            className="check_btn"
                            id="evaluete_button_id"
                          >
                            <p>Evaluate Now</p>
                          </button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Content>
      </div>
    </UnnlockLayout>
  );
};

export default OfferEvaluator;
