/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import "../../Profile/model.scss";
import { useSelector } from "react-redux";

const AddSkillsModal = ({
  skills,
  title,
  visible,
  setaddedSkills,
  addedSkills,
  setsaveSkills,
  foundSkills,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewAll, setviewAll] = useState(true);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const { saveLoader } = useSelector((state) => state.resumeOptimizer);

  const handleOk = () => {
    setIsModalVisible(false);
    setaddedSkills(selectedSkills);
    setviewAll(false);
    setsaveSkills(true);
    setTimeout(() => {
      setsaveSkills(false);
    }, 1000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setviewAll(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
    setSelectedSkills([...addedSkills]);
  }, [visible]);

  useEffect(() => {
    setSuggestedSkills(skills);
  }, [skills]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className={
        viewAll ? "custom_addSkillsModal_enlarged" : "custom_addSkillsModal"
      }
      width={viewAll ? "85vw" : "60vw"}
    >
      <div className="model_body">
        <div className="content_holder" style={{ width: "100%" }}>
          <div className="title">Add {title}</div>
          <div className="subTitle">
            Please add 6-8 skills that you deployed in your job
          </div>
          {selectedSkills.length ? (
            <div className="selecteddSkills">
              <div className="title">Selected Skills</div>
              <div className="skills">
                {selectedSkills.map((item) => (
                  <div
                    className="skill"
                    onClick={() => {
                      setSelectedSkills(
                        selectedSkills.filter((i) => i !== item)
                      );
                      setSuggestedSkills([item, ...suggestedSkills]);
                    }}
                  >
                    <p>{item}</p>
                    <span>+</span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className="suggestedSkills">
            <div className="title">Suggested Skills</div>
            {saveLoader ? (
              "Getting suggested skills..!"
            ) : (
              <div
                className="skills"
                style={{ maxHeight: viewAll ? "300px" : "200px" }}
              >
                {suggestedSkills.length
                  ? suggestedSkills.map((item, i) => (
                      <>
                        {i < 8 || viewAll ? (
                          <div
                            className="skill"
                            onClick={() => {
                              setSelectedSkills([
                                ...selectedSkills.filter(
                                  (prev) => prev !== item
                                ),
                                item,
                              ]);
                              setSuggestedSkills(
                                suggestedSkills.filter((sel) => sel !== item)
                              );
                            }}
                            style={{
                              color: foundSkills.includes(item)
                                ? "blue"
                                : "#261c4b",
                              background: foundSkills.includes(item)
                                ? "rgba(67, 137, 250, 0.1)"
                                : "white",
                            }}
                          >
                            <p>{item}</p>
                            <span>+</span>
                          </div>
                        ) : null}
                      </>
                    ))
                  : null}
                {viewAll || !suggestedSkills.length ? null : (
                  <button
                    type="button"
                    className="save-button"
                    onClick={() => setviewAll(true)}
                  >
                    View All
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="buttons_container">
            <Button type="primary" className="custom_btn" onClick={handleOk}>
              Save
            </Button>
            <Button type="link" className="custom_btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSkillsModal;
