/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spin } from "antd";
import { key, schema, validation, values as schemaValues } from "./schema";
import { formatToEducationData } from "../../../utils/helper";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import "./index.scss";
import ResumeOptimizerInput from "../resumeOptimizerInput";
// import AutoSave from "../../ResumeVault/autoSave";

import TrashSimple from "../../../assets/images/resumeOptimizer/TrashSimple (red).svg";
import CommonInput from "../../ICDProvider/commonInput";

const EducationInfo = () => {
  const educationInfo = useSelector(
    (state) => state.resumeOptimizer.educationInfo
  );
  const [changedFieldValues, setChangedFieldValues] = useState(
    educationInfo.education
  );
  const { loader } = useSelector((state) => state.resumeOptimizer);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const formData = {
      education: changedFieldValues.map((item) => {
        return formatToEducationData(item);
      }),
    };
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "education-update",
      })
    );
  };

  const handleChange = (value, key, index) => {
    let arr = [...changedFieldValues];
    arr[index] = { ...arr[index], [key]: value };
    setChangedFieldValues(arr);
  };

  const degreeTypes = ["PhD", "MS", "BS", "Associate", "High School"];

  return (
    <div className="update_education_container">
      <Spin size="large" spinning={loader}>
        <Formik
          initialValues={educationInfo}
          enableReinitialize
          // validationSchema={validation}
          onSubmit={(values) => {
            setTimeout(() => {
              handleSubmit(values);
            }, 400);
          }}
        >
          {({ values, errors, touched }) => (
            <Form>
              <FieldArray name={key}>
                {({ remove, push }) => (
                  <>
                    {changedFieldValues.length > 0 &&
                      changedFieldValues.map((item, i) => (
                        <div key={i}>
                          <Row justify="space-between" gutter={[24, 12]}>
                            <Col>
                              <div className="title">Degree {i + 1}</div>
                            </Col>
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <Col>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      document
                                        .getElementById("submitEducationBtn")
                                        .click()
                                    }
                                    className="save-button"
                                  >
                                    Save
                                  </button>
                                </Col>
                                <Col>
                                  <button
                                    className="delete-button"
                                    onClick={() => {
                                      remove(i);
                                      let arr = [...changedFieldValues];
                                      arr.splice(i, 1);
                                      setChangedFieldValues(arr);
                                      document
                                        .getElementById("submitEducationBtn")
                                        .click();
                                    }}
                                  >
                                    <img src={TrashSimple} alt="remove" />
                                    Delete
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[24, 12]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <CommonInput
                                placeholder={`Enter school`}
                                value={item.school}
                                handleChange={(e) => {
                                  handleChange(e.target.value, "school", i);
                                }}
                                type="text"
                              />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <ResumeOptimizerInput
                                label="degreeType"
                                name={`education[${i}].degreeType`}
                                type="selection"
                                options={
                                  item.degreeType &&
                                    !degreeTypes.includes(item.degreeType)
                                    ? [...degreeTypes, item.degreeType]
                                    : degreeTypes
                                }
                                error={errors.degreeType}
                                touched={touched.degreeType}
                                value={item.degreeType}
                                handleChange={(value) => {
                                  handleChange(value, "degreeType", i);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row gutter={[24, 12]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <CommonInput
                                placeholder={`Enter degree major`}
                                value={item.field}
                                handleChange={(e) => {
                                  handleChange(e.target.value, "field", i);
                                }}
                                type="text"
                              />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <ResumeOptimizerInput
                                onlyYear={true}
                                label="Completion Year"
                                name={`education[${i}].to`}
                                type="month"
                                error={errors.to}
                                touched={touched.to}
                                handleChange={(value) => {
                                  handleChange(value, "to", i);
                                }}
                                value={item.to}
                              />
                            </Col>
                          </Row>
                          <Row gutter={[24, 24]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <CommonInput
                                placeholder={`Enter degree minor`}
                                value={item.minor}
                                handleChange={(e) => {
                                  handleChange(e.target.value, "minor", i);
                                }}
                                type="text"
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}
                    <button
                      className="save-button"
                      type="button"
                      onClick={() => {
                        let arr = [...changedFieldValues];
                        arr.push({
                          school: "",
                          field: "",
                          from: "",
                          to: "",
                          degreeType: "",
                          minor: "",
                        });
                        push({
                          school: "",
                          field: "",
                          from: "",
                          to: "",
                          degreeType: "",
                          minor: "",
                        });
                        setChangedFieldValues(arr);
                      }}
                      style={{ margin: "20px 0px" }}
                    >
                      + Add Education
                    </button>
                  </>
                )}
              </FieldArray>
              <button
                type="submit"
                id="submitEducationBtn"
                style={{ visibility: "hidden" }}
              >
                Submit
              </button>
              {/* <AutoSave debounceMs={1000} /> */}
            </Form>
          )}
        </Formik>
      </Spin>
    </div>
  );
};

export default EducationInfo;
