/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import * as ACTIONS from "./persanalityTestActions";
import * as TYPES from "./persanalityTestTypes";
import Network from "../../network";

const { API, Config } = Network;

function* handleQuestionsRequest(action) {
  try {
    const personalityTestUrl = Config.personalityTest.getQuestions();
    const result = yield call(API.get, personalityTestUrl, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getQuestionsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getQuestionsFailure(error));
  }
}
function* handleResultsRequest(action) {
  try {
    const { id } = action.payload;
    const personalityTestUrl = Config.personalityTest.getResults(id);
    const result = yield call(API.get, personalityTestUrl, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getResultsSuccess({ data: result }));
  } catch (error) {
    yield put(ACTIONS.getResultsFailure(error));
  }
}
function* handlesaveResultsRequest(action) {
  try {
    const personalityTestUrl = Config.personalityTest.submitResults();
    const result = yield call(API.post, personalityTestUrl, action.payload, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.submitResultsSuccess({ data: result }));
  } catch (error) {
    yield put(ACTIONS.submitResultsFailure(error));
  }
}

export function* requestSaveResults() {
  yield takeLatest(TYPES.SUBMIT_RESULTS_REQUEST, handlesaveResultsRequest);
}
export function* requestResults() {
  yield takeLatest(TYPES.GET_RESULTS_REQUEST, handleResultsRequest);
}
export function* requestQuestions() {
  yield takeLatest(TYPES.GET_QUESTIONS_REQUEST, handleQuestionsRequest);
}
