/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import "../Profile/model.scss";
import { useSelector } from "react-redux";
import UploadAtsView from "../NewRegistration/uploadAtsView";

const AtsModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);

  const { contactInfo } = useSelector((state) => state.resumeOptimizer);
  const educationInfo = useSelector(
    (state) => state.resumeOptimizer.educationInfo
  );
  const employementInfo = useSelector(
    (state) => state.resumeOptimizer.employementInfo.employment
  );
  const ExperienceSummaryDetails = useSelector(
    (state) => state.resumeOptimizer?.ExperienceSummaryDetails
  );
  const skills = useSelector(
    (state) => state.resumeOptimizer.skills_for_job_match
  );
  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="atsViewModal"
    >
      <Skeleton loading={resumeOptimizerState.loader} active>
        <div className="model_body">
          <div className="content_holder" style={{ width: "100%" }}>
            <UploadAtsView
              name={contactInfo.formattedName}
              employInfo={employementInfo}
              skills={skills}
              education={educationInfo}
              ExperienceSummaryDetails={ExperienceSummaryDetails}
            />
          </div>
        </div>
      </Skeleton>
    </Modal>
  );
};

export default AtsModal;
