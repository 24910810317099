/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import suggestion from "../../assets/images/resumeOptimizer/suggestion.svg";
import verifiedIcon from "../../assets/images/resumeOptimizer/verifiedIcon.svg";
import { useState } from "react";

export const ContactInfoSuggestion = () => {
  const data = [
    {
      label: "Street Address",
      desc: "For privacy reasons, it is not required to have street addresses, we have eliminated street address.",
    },
    {
      label: "City, State, Zip code",
      desc: "Always include, recruiters are interested in knowing a candidates location. Local candidates may be prefferred for certain",
    },
    {
      label: "Phone Number",
      desc: "Always include a phone number to allow a recruiter to contact you.",
    },
    {
      label: "Email Address",
      desc: "Always include a phone number to allow a recruiter to contact you.",
    },
  ];
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={suggestion}
          alt="suggestion"
          style={{ marginRight: "12px", width: "42px" }}
        />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Personal Info</span>
      </div>
      {data.map((item) => (
        <div style={{ padding: "8px 16px" }}>
          <div
            style={{
              fontWeight: 700,
              fontSize: "16px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </div>
          <div
            style={{
              borderRadius: "24px",
              padding: "10px 16px",
            }}
          >
            {item.desc}
          </div>
        </div>
      ))}
    </div>
  );
};

export const SummarySuggestion = () => {
  const data = [
    {
      label: "",
      desc: "This important section near the top of your resume works best as a short summary of your best hard skills, top accomplishments, and areas of specialty.",
    },
  ];
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={suggestion}
          alt="suggestion"
          style={{ marginRight: "12px", width: "42px" }}
        />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Summary</span>
      </div>
      {data.map((item) => (
        <div style={{ padding: "8px 16px" }}>
          <div
            style={{
              fontWeight: 700,
              fontSize: "16px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </div>
          <div
            style={{
              borderRadius: "24px",
              padding: "10px 16px",
            }}
          >
            {item.desc}

            <p>
              <br />A recruiter should see this and think, “Now that’s what
              we’re looking for!”
            </p>
          </div>
        </div>
      ))}
      {/* <div style={{ padding: "8px 16px" }}>
        <div className="title-label">How to write a good summary?</div>
        <div style={{ color: "#4389FA", fontWeight: 700 }}>View Examples</div>
      </div> */}
    </div>
  );
};

export const GetOnboardSuggestions = ({ screen, mainTitle }) => {
  console.log("screen--", screen);
  const data = [
    {
      label: "",
      desc:
        screen === "forCompanyDescription"
          ? "Your occupation is used to select and match appropriate skills. The selected occupation will determine the most popular skills for that particular occupation."
          : "There are many companies with similar names. To ensure that the right company is selected, please type your Company name, select and then confirm.",
    },
  ];
  const viewData = (itemLocal) => {
    if (screen === "companySearch") {
      return (
        <div>
          <p>
            Confirming your current or most recent company enables personalized
            guidance and insights based on your career path and skills.{" "}
          </p>
          Your job search is confidential
        </div>
      );
    }
    if (screen === "confirmEmployer") {
      return (
        <div>
          <p>
            Your current company or a similar company helps identify companies
            that match in skills, industry, and builds your target company list.
          </p>
        </div>
      );
    }
    if (screen === "fromRoleSelection") {
      return (
        <>
          <p>
            Management skills are broader and rely on domain experience while
            individual contributor skills are more specialized and
            task-oriented.
          </p>

          <p>
            <strong>Management</strong>: Your current role involves directly
            managing people.
          </p>

          <p>
            <strong>Individual Contributor</strong>: You report to a manager but
            do not have anyone reporting to you.
          </p>
        </>
      );
    }
    if (screen === "companyNotfound") {
      return (
        <>
          <p>
            Your company isn’t in our database yet. Provide a little more
            information to continue.
          </p>
          <ul>
            <li>
              Search for a company that is similar to your most recent employer,
              like a competitor in the same industry.
            </li>
            <li>
              Enter your company details to help us create a bigger database for
              deeper insights.
            </li>
          </ul>
          <p>
            Your job search is confidential and personal data is NEVER shared.
          </p>
        </>
      );
    } else {
      return <>{itemLocal.desc}</>;
    }
  };
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "auto",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={suggestion}
          alt="suggestion"
          style={{ marginRight: "12px", width: "42px" }}
        />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>
          {mainTitle ? mainTitle + "?" : "Tips"}
        </span>
      </div>
      {data.map((item) => (
        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: "16px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </div>
          <div
            style={{
              borderRadius: "24px",
              padding: "16px",
            }}
          >
            {screen === "exploreBySkills" || screen === "exploreByComp" ? (
              <p>
                {screen === "exploreBySkills" ? (
                  <>
                    <p>
                      Select 5 or more skills to highlight in your next role.
                      Identify companies that hire employees with these skill
                      combinations.
                    </p>
                    <br />
                    <p>These skills were extracted from your resume.</p>
                  </>
                ) : (
                  <>
                    <p>
                      Search by name to access company insights, research
                      potential employers, and build your target company list.
                    </p>
                  </>
                )}
              </p>
            ) : (
              <>{viewData(item)}</>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

const data = [
  {
    label: "Manual corrections",
    desc: `unnlock uses a real resume parser that you’ll encounter in online job applications.   Correct any errors and add missing info here to avoid problems like this when you apply.`,
    btnText: "All good, move to next step",
    isCompleted: false,
  },
  {
    label: "AI Suggestions",
    desc: "Let AI rewrite your description and bullet points. Use the whole thing or just take the parts you like.  Note: You can only do this once per resume position.",
    btnText: "Generate AI Suggestions",
    isCompleted: false,
  },
  // {
  //   label: "Spellcheck and Grammar",
  //   desc: "Double-check your spelling and grammar with help from Grammarly. ",
  //   btnText: "All good, move to next step",
  //   isCompleted: false,
  // },
  {
    label: "Hard and Soft Skills",
    desc: "Include relevant skills to help recruiters imagine you in the role. Skills are important for recruiter searches and ATS rankings.   Only include skills with which you have real experience. Don’t include skills you wouldn’t want to talk about in an interview. ",
    btnText: "Done",
    isCompleted: false,
  },
];

export const EmployementSuggestion = () => {
  const suggestionLoader = useSelector(
    (state) => state.resumeOptimizer.suggestionLoader
  );
  const currentStep = useSelector(
    (state) => state.resumeOptimizer?.currentStep
  );

  const [steps, setTeps] = useState(data);
  const [showSubStep, setShowSubStep] = useState(false);

  const handleClick = (i) => {
    if (i === 0) {
      const element = document.getElementsByClassName(
        "job_description_label"
      )[0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (i === 2) {
      const element = document.getElementsByClassName("skills_titleCard")[0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (i === 1) {
      document.getElementById("compute_description_id").click();
      setShowSubStep(true);
    } else {
      document.getElementById("submitExperienceBtn").click();
      console.log("submitExperienceBtn clicked");
      setShowSubStep(false);
    }
  };

  return (
    <>
      <div className="employementSteps">
        <div className="title">
          <img src={suggestion} alt="suggestion" style={{ width: "42px" }} />
          <span>Experience</span>
          <div className="subtitle">Refine the content</div>
        </div>
        {steps.map((item, i) => (
          <>
            {i < currentStep ? (
              <>
                <div className="selected_step">
                  <div className="step">
                    <span>STEP {i + 1}</span> <img src={verifiedIcon} />
                  </div>
                  <div className="label">{item.label}</div>
                </div>
              </>
            ) : (
              <>
                {i === currentStep ? (
                  <div className="selected_step">
                    {currentStep === 2 && (
                      <div
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "5px",
                        }}
                      >
                        <grammarly-button></grammarly-button>
                      </div>
                    )}
                    <div className="step">STEP {i + 1}</div>
                    <div className="label">{item.label}</div>
                    <div className="desc">{item.desc}</div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {showSubStep && !suggestionLoader ? (
                        <button
                          className="nextStepButton"
                          onClick={() => {
                            document
                              .getElementById("submitExperienceBtn")
                              .click();
                            setShowSubStep(false);
                          }}
                          // style={{ marginLeft: "10px" }}
                        >
                          Go To Next Step
                        </button>
                      ) : (
                        <button
                          className="nextStepButton"
                          onClick={() =>
                            suggestionLoader ? {} : handleClick(i)
                          }
                        >
                          {!suggestionLoader
                            ? item.btnText
                            : "Getting prefered suggestions..!"}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="unselected_step">
                    <div className="step">STEP {i + 1}</div>
                    <div className="label">{item.label}</div>
                  </div>
                )}
              </>
            )}
            <div className="borderBottom" />
          </>
        ))}
      </div>
    </>
  );
};

export const DefaultSuggestion = () => {
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={suggestion} alt="suggestion" style={{ width: "42px" }} />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Suggestions</span>
      </div>
      <div className="default_content">No Suggestions for this section</div>
    </div>
  );
};

export const AssosiationSuggestion = () => {
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={suggestion} alt="suggestion" style={{ width: "42px" }} />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Suggestions</span>
      </div>
      <div style={{ padding: "16px" }}>
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Awards and Honors</p>
        <p>
          If you have received special recognition that is relevant to your
          career, list them here. (Optional)
        </p>
        <br />
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Associations</p>
        <p>
          Include professional associations, affiliations, and memberships
          relevant to your career. (Optional)
        </p>
      </div>
    </div>
  );
};

export const EducationSuggestion = () => {
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={suggestion} alt="suggestion" style={{ width: "42px" }} />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Suggestions</span>
      </div>
      <div style={{ padding: "16px" }}>
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Education</p>
        <p>List your higher education with your most recent degree first.</p>
        <br />
        <p>
          You may omit completion years for anything over 15 years old to fight
          against age discrimination
        </p>
      </div>
    </div>
  );
};

export const PreviewSuggestion = () => {
  const data = [
    {
      label: "",
      desc: "See how your new resume is shaping up.\n\nCheck for ATS parsing errors and missing info, then navigate between tabs to optimize each section of your resume with AI help and automations.",
    },
  ];
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={suggestion}
          alt="suggestion"
          style={{ marginRight: "12px", width: "42px" }}
        />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Overview</span>
      </div>
      {data.map((item) => (
        <div style={{ padding: "8px 16px" }}>
          {/* <div
            style={{
              fontWeight: 700,
              fontSize: "16px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </div> */}
          <div
            style={{
              borderRadius: "24px",
              paddingTop: "16px",
              whiteSpace: "pre-line",
            }}
          >
            {item.desc}
          </div>
        </div>
      ))}
    </div>
  );
};

export const PreviewSuggestionWithoutInfo = ({ page }) => {
  const data = [
    {
      label:
        "Here we will guide you through every element of your resume and make recommendations.",
      desc: "Our application has parsed your Resume and transferred it to “Original Resume”. It may not be exact due to parsing errors, etc. We have created a duplicate Resume for you to Edit & Optimize. You can store all the versions of your edited Resume here.",
    },
  ];
  const getCurrentDesc = () => {
    switch (page) {
      case "companyMatch":
        return (
          <>
            <p style={{ margin: "0px" }}>
              These potential employers were selected based on AI analysis of
              your career profile and big workforce data.
            </p>
            <ul style={{ padding: "16px" }}>
              <li style={{ marginBottom: "8px" }}>
                <p>
                  Click on a company to see how your skills and qualifications
                  stack up against their employees.
                </p>
              </li>
              <li style={{ marginBottom: "8px" }}>
                <p>Favorite ♥️ companies to track them in your dashboard.</p>
              </li>
              <li>
                <p style={{ margin: "0px" }}>
                  Go to Map to navigate companies by location
                </p>
              </li>
            </ul>
          </>
        );
      case "companyExplore":
        return (
          <>
            <p>
              These companies hire people with the skill combinations you
              selected.
            </p>
            <p>
              Click companies to see how you stack up against their employees or
              add them to your favorites.
            </p>
          </>
        );
      case "companyDetails":
        return (
          <>
            <p>
              Your Skills – These are all skills from your Resume matched to
              skills at the company.
            </p>
            <p>
              All Skills- These are all skills at the company matched to your
              skills.
            </p>
            <p>
              Role Skills – These are skills at the company for your current
              role.
            </p>
            <p>
              If this is confusing – just note that more blue highlighted boxes
              are good.
            </p>
          </>
        );
      case "jobMatch":
        return (
          <div>
            <p>
              <b>Resume</b>: Choose between your saved resumes to see which one
              fits best, then use our guided suggestions to optimize it for the
              jobs
            </p>
            <p>
              <b>Enter details</b>: Fill in the fields then copy and paste the
              text from the entire job listing to see if your resume is a fit
              for the job. For a good experience, please provide all available
              details
            </p>
            <p>
              <b>Industry</b>: Find the Industry in the About section in the
              Company’s LinkedIn page
            </p>
          </div>
        );
      case "resumeVault":
        return (
          <div>
            <p>
              unnlock parsed your original resume like a real job application.
            </p>
            <p>
              Duplicate and optimize saved resumes to add missing skills, fix
              errors, and upgrade your resume content before applying to any
              job.
            </p>
          </div>
        );
      default:
        return (
          <>
            {data.map((item) => (
              <>
                <div>{item.desc}</div>
              </>
            ))}
          </>
        );
    }
  };
  return (
    <div
      style={{
        background: "#ffffff",
        // boxShadow: " 0px 5px 45px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "4px solid rgb(233, 241, 255)",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={suggestion}
          alt="suggestion"
          style={{ marginRight: "12px", width: "42px" }}
        />
        <span style={{ fontSize: "24px", fontWeight: 500 }}>Tips</span>
      </div>
      <div
        style={{
          borderRadius: "24px",
          padding: "16px",
          maxHeight: "80%",
          overflowX: "hidden",
        }}
      >
        {getCurrentDesc()}
      </div>
    </div>
  );
};
