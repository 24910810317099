import React from "react";
import UnnlockLayout from "../layout";
import titleImage from "../../assets/images/UnnlockNew.svg";
import history from "../../routes/History";
import "./terms.scss";
import { useEffect } from "react";

const Terms = () => {
  const isLogin = () => {
    if (localStorage.getItem("unnlockUserToken")) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <UnnlockLayout
      dontDisplayHeader={isLogin() ? false : true}
      background="white"
      optionalHeader={
        !isLogin() ? (
          <>
            <div className="loginPage_header">
              <div className="loginPage_header_inner">
                <img
                  src={titleImage}
                  alt="titleImage"
                  className="unnlockIcon"
                  onClick={() => {
                    history.push("/");
                  }}
                />
              </div>
              <div
                onClick={() => {
                  history.push("/registration");
                }}
                style={{ cursor: "pointer", color: "white", width: "13%" }}
              >
                Login / SIGN IN
              </div>
            </div>
          </>
        ) : null
      }
    >
      <div
        style={{ background: "white", borderRadius: "10px", margin: "40px" }}
      >
        <div
          name="termly-embed"
          data-id="3873ae0a-0b3c-4199-8d00-7349223c9861"
          data-type="iframe"
        ></div>
      </div>
    </UnnlockLayout>
  );
};
export default Terms;
