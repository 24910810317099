import React, { useEffect, useState } from "react";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";
import history from "../../routes/History";
import "./index.scss";
import SearchInput from "../Dashboard/searchField";
import { useDispatch, useSelector } from "react-redux";
import CompanyDescCard from "../ICDProvider/companyDescCard";
import {
  getDashboardComponyDeatailsFailure,
  getDashboardComponyDeatailsRequest,
  selectedCompanyId,
} from "../../store/componies/action";

const ByCompanyName = ({ }) => {
  const dispatch = useDispatch();
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );

  const handleSelect = (e) => {
    dispatch(
      getDashboardComponyDeatailsRequest({
        // company_name: e.text,
        unnlock_id: e.value,
      })
    );
  };

  const handleSubmit = () => {
    dispatch(selectedCompanyId(companyDetails[0].unnlock_id));
    history.push("/companyMatch?ExploreByCname=true");
  };

  useEffect(() => {
    dispatch(getDashboardComponyDeatailsFailure());
  }, []);

  return (
    <div style={{ marginTop: "62px", paddingLeft: "8px", paddingRight: "8px" }}>
      <SearchInput
        placeholder="Company Name"
        onChange={handleSelect}
        setselectedComp={() => { }}
        style={{
          border: "1px solid #dee5ff",
          padding: "7px 15px",
          borderRadius: "8px",
          // marginLeft: "24px",
          // marginRight: "25px",
        }}
        initialEmpty
      />
      {companyDetails && companyDetails.length ? (
        <CompanyDescCard fromExplore />
      ) : null}
      <div
        className="ByCompanyName_submit"
        style={{
          position: "unset",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <button type="button" onClick={() => handleSubmit()}>
          Next <img src={WhiteArrowLeft} />
        </button>
      </div>
    </div>
  );
};

export default ByCompanyName;
