/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentResumePage } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import EditButton from "./editButton";
// import ContentEditable from "react-contenteditable";
import {
  Page,
  Document,
  View,
  Text,
  Font,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import sanitizeHtml from "sanitize-html";

//DND imports start here
import { MenuOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { resumeUpdateRequest } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import { formatToEmployementData } from "../../../utils/helper";
//DND imports ends here

// Dragand drop functionality starts here
// data generator
const getItems = (experienceData) =>
  experienceData.map((k, i) => ({
    id: `${i}`,
    content: k,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "grey" : "white",
  // padding: grid,
  // width: 250,
});
// Dragand drop functionality ends here

const checkForEmpty = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    if (arr.length === 1) {
      if (Object.values(arr[0]).every((val) => val === "")) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  } else {
    return false;
  }
};

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
      fontWeight: 100,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
      fontWeight: 200,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
      fontWeight: 300,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
      fontWeight: 600,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
      fontWeight: 700,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      fontWeight: 800,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
      fontWeight: 900,
    },
  ],
});

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length <= 15
      ? [word]
      : [word.substring(0, middle), word.substring(middle)];
  return parts;
});
// Font.register({
//   family: "Poppins",
//   fonts: [
//     {
//       fontWeight: "100",
//       fontStyle: "normal",
//       src: FontPoppinsThin,
//     },
//     {
//       fontWeight: "100",
//       fontStyle: "italic",
//       src: FontPoppinsThinItalic,
//     },
//     {
//       fontWeight: "200",
//       fontStyle: "normal",
//       src: FontPoppinsExtraLight,
//     },
//     {
//       fontWeight: "200",
//       fontStyle: "italic",
//       src: FontPoppinsExtraLightItalic,
//     },
//     {
//       fontWeight: "300",
//       fontStyle: "normal",
//       src: FontPoppinsLight,
//     },
//     {
//       fontWeight: "300",
//       fontStyle: "italic",
//       src: FontPoppinsLightItalic,
//     },
//     {
//       fontWeight: "400",
//       fontStyle: "normal",
//       src: FontPoppinsRegular
//     },
//     {
//       fontWeight: "400",
//       fontStyle: "italic",
//       src: FontPoppinsItalic,
//     },
//     {
//       fontWeight: "500",
//       fontStyle: "normal",
//       src: FontPoppinsMedium,
//     },
//     {
//       fontWeight: "500",
//       fontStyle: "italic",
//       src: FontPoppinsMediumItalic,
//     },
//     {
//       fontWeight: "600",
//       fontStyle: "normal",
//       src: FontPoppinsSemiBold,
//     },
//     {
//       fontWeight: "600",
//       fontStyle: "italic",
//       src: FontPoppinsSemiBoldItalic,
//     },
//     {
//       fontWeight: "700",
//       fontStyle: "normal",
//       src: FontPoppinsBold,
//     },
//     {
//       fontWeight: "700",
//       fontStyle: "italic",
//       src: FontPoppinsBoldItalic,
//     },
//     {
//       fontWeight: "800",
//       fontStyle: "normal",
//       src: FontPoppinsExtraBold,
//     },
//     {
//       fontWeight: "800",
//       fontStyle: "italic",
//       src: FontPoppinsExtraBoldItalic,
//     },
//     {
//       fontWeight: "900",
//       fontStyle: "normal",
//       src: FontPoppinsBlack,
//     },
//     {
//       fontWeight: "900",
//       fontStyle: "italic",
//       src: FontPoppinsBlackItalic,
//     },
//   ],
// });
// Font.register({
//   family: "Poppins",
//   fonts: [
//     {
//       fontWeight: "100",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
//     },
//     {
//       fontWeight: "100",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
//     },
//     {
//       fontWeight: "200",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "200",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "300",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "300",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "400",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
//     },
//     {
//       fontWeight: "400",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
//     },
//     {
//       fontWeight: "500",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "500",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "600",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "600",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "700",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "700",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "800",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "800",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
//     },
//     {
//       fontWeight: "900",
//       fontStyle: "normal",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
//     },
//     {
//       fontWeight: "900",
//       fontStyle: "italic",
//       src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf",
//     },
//   ],
// });
// Font.register({
//   family: 'Roboto',
//   fonts: [
//     {fontWeight: 100, src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf"},
//     {fontWeight: 100, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf"},
//     {fontWeight: 300,src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf"},
//     {fontWeight: 300,fontStyle: "italic",src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf"},
//     {fontStyle: "normal", src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf"},
//     {fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf"},
//     {fontWeight: 500, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf"},
//     {fontWeight: 500, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf"},
//     {fontWeight: 700, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf"},
//     // {fontWeight: 700, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf"},
//     // {fontWeight: 900, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf"},
//     // {fontWeight: 900, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf"},
//   ],
// });
// Font.register({
//   family: 'Montserrat',
//   fonts: [
//     {fontWeight: 100, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-Y3tcoqK5.ttf"},
//     {fontWeight: 200, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 300, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 400, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 500, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 600, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf"},
//     {fontWeight: 700, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"},
//     // {fontWeight: 800, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf"},
//     // {fontWeight: 900, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf"},
//     {fontWeight: 100, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX9-p7K5ILg.ttf"},
//     {fontWeight: 200, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX9-p7K5ILg.ttf"},
//     {fontWeight: 300, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX9-p7K5ILg.ttf"},
//     {fontWeight: 400, fontStyle: "italic", src:"https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX9-p7K5ILg.ttf"},
//     {fontWeight: 500, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX9-p7K5ILg.ttf"},
//     // {fontWeight: 600, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 700, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 800, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 900, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX9-p7K5ILg.ttf"}
//   ],
// });

const getStyles = (
  isPDF,
  sectionSpacing,
  lineHeight,
  fontSize,
  fontWeight
) => ({
  page: {
    paddingTop: "0.75in",
    paddingBottom: "0.75in",
    paddingLeft: "0.75in",
    paddingRight: "0.75in",
    color: "black",
    fontFamily: "Inter",
    fontSize: fontSize + "px",
    fontWeight: fontWeight,
    lineHeight: lineHeight,
  },
  pageHeader: {},
  pageHeaderFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pageHeaderName: {
    color: "gray",
    flex: 7,
  },
  pageHeaderNumber: {
    color: "gray",
    flex: 0,
  },
  pageHeaderBorder: {
    //work around for borderBottom appearing on first page
    width: "100%",
    height: 2,
    backgroundColor: "gray",
    marginBottom: "10px",
  },
  section: {},
  sectionTitle: {
    fontWeight: fontWeight <= 800 ? fontWeight + 200 : fontWeight,
    marginTop: 10 * sectionSpacing + "px",
    marginBottom: 10 * sectionSpacing + "px",
    paddingBottom: "5px",
    borderBottom: "2px solid rgba(200, 200, 200)",
    letterSpacing: "3px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  identification: {
    flex: 2,
  },
  name: {
    fontSize: "24px",
    fontWeight: fontWeight <= 800 ? fontWeight + 300 : fontWeight,
  },
  title: {
    fontSize: "24px",
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
  },
  communication: {
    flex: 1,
    fontSize: fontSize + "px",
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
  },
  ".summary-content-paragraph": {
    fontSize: fontSize + "px",
    marginBottom: "10px",
    textAlign: "justify",
  },
  job: {
    // marginBottom: 10*sectionSpacing+"px",
  },
  jobHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0px",
    marginTop: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  jobTitle: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight <= 800 ? fontWeight + 100 : fontWeight,
    flex: 2,
    textAlign: "left",
    marginTop: "0px",
  },
  jobDate: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
    flex: 1,
    textAlign: "right",
    color: "black",
    marginTop: "0px",
  },
  jobCompany: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
    color: "black",
    marginTop: "0px",
    marginBottom: "10px",
  },
  jobDescription: {
    fontSize: fontSize + "px",
    textAlign: "justify",
    fontWeight: fontWeight,
  },
  ".job-description-paragraph": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
    textAlign: "justify",
  },
  ".job-description-orderedlist": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
    textAlign: "justify",
  },
  ".job-description-unorderedlist": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
    textAlign: "justify",
  },
  ".job-description-list-item": {
    paddingRight: "0.25in",
    fontWeight: fontWeight,
    textAlign: "justify",
  },
  skills: {
    marginBottom: "10px",
  },
  hardSkills: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
  },
  hardSkillsTitle: {
    flex: 1,
    fontWeight: fontWeight >= 200 ? fontWeight : fontWeight,
    fontSize: fontSize + "px",
  },
  hardSkillsItems: {
    flex: 7,
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
    fontSize: fontSize + "px",
    textAlign: "justify",
  },
  softSkills: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
  },
  softSkillsTitle: {
    flex: 1,
    fontWeight: fontWeight >= 200 ? fontWeight : fontWeight,
    fontSize: fontSize + "px",
  },
  softSkillsItems: {
    flex: 7,
    fontWeight: fontWeight >= 200 ? fontWeight - 100 : fontWeight,
    fontSize: fontSize + "px",
    textAlign: "justify",
  },
  education: {
    marginBottom: "10px",
  },
  educationHeader: {
    display: "flex",
    flexDirection: "row",
  },
  educationTitle: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight,
    flex: 3,
    textAlign: "left",
  },
  educationDate: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight,
    flex: 1,
    textAlign: "right",
  },
  otherData: {
    fontSize: fontSize + "px",
    fontWeight: fontWeight,
  },
  ".other-data-paragraph": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
  },
  ".other-data-orderedlist": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
  },
  ".other-data-unorderedlist": {
    marginTop: "0px",
    marginBottom: "5px",
    fontWeight: fontWeight,
  },
  ".other-data-list-item": {
    paddingRight: "0.25in",
    fontWeight: fontWeight,
  },
});

const HtmlResume = ({
  contactInfo,
  summary,
  educationInfo,
  employementInfo,
  resumeScreens,
  otheresSections,
  styles,
  isEdit,
}) => {
  const [items, setItems] = useState([]);
  const [isDragged, setIsDragged] = useState(false);
  const dispatch = useDispatch();

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const Items = reorder(items, result.source.index, result.destination.index);
    setItems(Items);
    setIsDragged(true);
  }

  useEffect(() => {
    if (items.length && isDragged) CallApi();
  }, [items, isDragged]);

  const checkForInvalidDateString = (date) => {
    return date === "Invalid date" ? "" : date;
  };

  const renderExperienceCard = (item) => {
    return (
      <div style={styles.job}>
        <div style={styles.jobHeader}>
          <div style={styles.jobTitle}>
            {isEdit ? (
              <MenuOutlined style={{ margin: "0px 10px 0px -25px" }} />
            ) : null}
            {item.designation ? item.designation : ""}
          </div>
          <div style={styles.jobDate}>
            {checkForInvalidDateString(item.from)
              ? checkForInvalidDateString(item.from) + " "
              : ""}
            {(checkForInvalidDateString(item.to) || item.iscurrentJob) &&
            checkForInvalidDateString(item.from)
              ? " - "
              : ""}
            {checkForInvalidDateString(item.to)
              ? checkForInvalidDateString(item.to)
              : item.iscurrentJob
              ? "Present"
              : ""}
          </div>
        </div>
        <div style={styles.jobCompany}>
          {item.company ? item.company : ""}
          {item.location ? ", " + item.location : ""}
        </div>
        {item.jobDescription && item.jobDescription !== "<p></p>" ? (
          <div
            style={styles.jobDescription}
            stylesheet={styles}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(item.jobDescription, {
                allowedTags: ["p", "ul", "ol", "li"],
                allowedAttributes: {
                  p: ["class", "style"],
                  ol: ["class", "style"],
                  ul: ["class", "style"],
                  li: ["class", "style"],
                },
                allowedClasses: {
                  p: ["job-description-paragraph"],
                  ol: ["job-description-orderedlist"],
                  ul: ["job-description-unorderedlist"],
                  li: ["job-description-list-item"],
                },
                transformTags: {
                  p: sanitizeHtml.simpleTransform("p", {
                    class: "job-description-paragraph",
                  }),
                  ol: sanitizeHtml.simpleTransform("ol", {
                    class: "job-description-orderedlist",
                  }),
                  ul: sanitizeHtml.simpleTransform("ul", {
                    class: "job-description-unorderedlist",
                  }),
                  li: sanitizeHtml.simpleTransform("li", {
                    class: "job-description-list-item",
                  }),
                },
              }),
            }}
          />
        ) : (
          <div stylesheet={styles}></div>
        )}
        {item.softSkills?.length || item.hardSkills?.length ? (
          <div style={styles.skills}>
            {item.hardSkills?.length ? (
              <div style={styles.hardSkills}>
                <div style={styles.hardSkillsTitle}>{"Hard Skills: "}</div>
                <div style={styles.hardSkillsItems}>
                  {item.hardSkills.join(", ")}
                </div>
              </div>
            ) : (
              <></>
            )}
            {item.softSkills?.length ? (
              <div style={styles.softSkills}>
                <div style={styles.softSkillsTitle}>{"Soft Skills: "}</div>
                <div style={styles.softSkillsItems}>
                  {item.softSkills.join(", ")}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getPostData = () => {
    let formData = {
      employment: items
        .map((item) => item.content)
        .map((item) => {
          return {
            ...formatToEmployementData(item),
            PositionHistory: [
              {
                ...formatToEmployementData(item).PositionHistory[0],
                Description: item.jobDescription || "",
                HardSkills: item.hardSkills || [],
                SoftSkills: item.softSkills || [],
                currentStep: item.currentStep || 0,
              },
            ],
          };
        }),
    };
    return formData;
  };

  const CallApi = () => {
    let formData = getPostData();
    dispatch(
      resumeUpdateRequest({
        formData,
        label: "employment-update",
      })
    );
    setIsDragged(false);
  };

  useEffect(() => {
    if (employementInfo.employment) {
      setItems(getItems(employementInfo.employment));
    }
  }, [employementInfo.employment]);

  return (
    <div className="document">
      <div className="page" style={styles.page}>
        <div style={styles.section}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {isEdit ? (
              <EditButton
                handleClick={() =>
                  dispatch(setCurrentResumePage("personal_info"))
                }
              />
            ) : null}
          </div>
          <div style={styles.header}>
            <div style={styles.identification}>
              <div style={styles.name}>
                {contactInfo.firstName.toUpperCase()}{" "}
                {contactInfo.lastName.toUpperCase()}
              </div>
            </div>
            <div style={styles.communication}>
              <div id="address">
                {contactInfo.city ? contactInfo.city : ""}
                {contactInfo.state ? "," : ""} {contactInfo.state}
                {contactInfo.country ? "," : ""}{" "}
                {contactInfo.country ? contactInfo.country : ""}{" "}
                {contactInfo.postalCode ? contactInfo.postalCode : ""}
              </div>
              <div id="email">{contactInfo.email ? contactInfo.email : ""}</div>
              <div id="phone">
                {contactInfo.phoneNumber ? contactInfo.phoneNumber : ""}
              </div>
              <div id="profile1">
                {contactInfo.onlineProfile1 ? contactInfo.onlineProfile1 : ""}
              </div>
              <div id="profile2">
                {contactInfo.onlineProfile2 ? contactInfo.onlineProfile2 : ""}
              </div>
              <div id="profile3">
                {contactInfo.onlineProfile3 ? contactInfo.onlineProfile3 : ""}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.section}>
          {summary && summary !== "<p></p>" ? (
            <div style={styles.sectionTitle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>SUMMARY</div>
                {isEdit ? (
                  <EditButton
                    handleClick={() =>
                      dispatch(setCurrentResumePage("summary"))
                    }
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <></>
          )}
          {summary && summary !== "<p></p>" ? (
            <div
              style={styles.jobDescription}
              stylesheet={styles}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(summary, {
                  allowedTags: ["p", "ul", "ol", "li"],
                  allowedAttributes: {
                    p: ["class", "style"],
                    ol: ["class", "style"],
                    ul: ["class", "style"],
                    li: ["class", "style"],
                  },
                  allowedClasses: {
                    p: ["summary-content-paragraph"],
                    ol: ["summary-content-orderedlist"],
                    ul: ["summary-content-unorderedlist"],
                    li: ["summary-content-list-item"],
                  },
                  transformTags: {
                    p: sanitizeHtml.simpleTransform("p", {
                      class: "summary-content-paragraph",
                    }),
                    ol: sanitizeHtml.simpleTransform("ol", {
                      class: "summary-content-orderedlist",
                    }),
                    ul: sanitizeHtml.simpleTransform("ul", {
                      class: "summary-content-unorderedlist",
                    }),
                    li: sanitizeHtml.simpleTransform("li", {
                      class: "summary-content-list-item",
                    }),
                  },
                }),
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div style={styles.section}>
          <div style={styles.sectionTitle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>EXPERIENCE</div>
              {isEdit ? (
                <EditButton
                  handleClick={() =>
                    dispatch(setCurrentResumePage("experience"))
                  }
                />
              ) : null}
            </div>
          </div>
          {items.length ? (
            <DragDropContext onDragEnd={isEdit ? onDragEnd : null}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {renderExperienceCard(item.content)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
        {checkForEmpty(educationInfo.education) ? (
          <div style={styles.section}>
            <div style={styles.sectionTitle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>EDUCATION</div>
                {isEdit ? (
                  <EditButton
                    handleClick={() =>
                      dispatch(setCurrentResumePage("education"))
                    }
                  />
                ) : null}
              </div>
            </div>
            {educationInfo.education.map((item) => (
              <div style={styles.education}>
                <div style={styles.educationHeader}>
                  <div style={styles.educationTitle}>
                    {item.degreeType ? item.degreeType : ""}
                    {item.degreeType && item.field ? " in " : ""}
                    {item.field ? item.field : ""}
                    {item.field && item.school ? " @ " : ""}
                    {item.school ? item.school : ""}{" "}
                  </div>
                  <div style={styles.educationDate}>
                    {checkForInvalidDateString(item.from)
                      ? checkForInvalidDateString(item.from)
                      : ""}
                    {checkForInvalidDateString(item.to) &&
                    checkForInvalidDateString(item.from)
                      ? " - "
                      : ""}{" "}
                    {checkForInvalidDateString(item.to)
                      ? checkForInvalidDateString(item.to)
                      : ""}
                  </div>
                </div>
                <div>
                  <div>{item.minor ? "Minor in " + item.minor : ""}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
        {resumeScreens.map((item) => (
          <div>
            {otheresSections &&
            typeof otheresSections[item.id] === "string" &&
            otheresSections[item.id] ? (
              <div style={styles.section}>
                <div id={item.id} style={styles.sectionTitle}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>{item.title.toUpperCase()}</div>
                    {isEdit ? (
                      <EditButton
                        handleClick={() =>
                          dispatch(setCurrentResumePage(item.id))
                        }
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  style={styles.otherData}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(otheresSections[item.id], {
                      allowedTags: ["p", "ul", "ol", "li"],
                      allowedAttributes: {
                        p: ["class", "style"],
                        ol: ["class", "style"],
                        ul: ["class", "style"],
                        li: ["class", "style"],
                      },
                      allowedClasses: {
                        p: ["other-data-paragraph"],
                        ol: ["other-data-orderedlist"],
                        ul: ["other-data-unorderedlist"],
                        li: ["other-data-list-item"],
                      },
                      transformTags: {
                        p: sanitizeHtml.simpleTransform("p", {
                          class: "other-data-paragraph",
                        }),
                        ol: sanitizeHtml.simpleTransform("ol", {
                          class: "other-data-orderedlist",
                        }),
                        ul: sanitizeHtml.simpleTransform("ul", {
                          class: "other-data-unorderedlist",
                        }),
                        li: sanitizeHtml.simpleTransform("li", {
                          class: "other-data-list-item",
                        }),
                      },
                    }),
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
const PdfResume = ({
  contactInfo,
  summary,
  educationInfo,
  employementInfo,
  resumeScreens,
  otheresSections,
  styles,
}) => {
  return (
    <Document>
      <Page size="LETTER" dpi={90} style={styles.page}>
        <View
          render={({ pageNumber, totalPages }) =>
            pageNumber > 1 && (
              <View style={styles.pageHeader}>
                <View style={styles.pageHeaderFlex}>
                  <Text style={styles.pageHeaderName}>
                    {contactInfo.firstName} {contactInfo.lastName}
                  </Text>
                  <Text style={styles.pageHeaderNumber}>Page {pageNumber}</Text>
                </View>
                <View style={styles.pageHeaderBorder}></View>
              </View>
            )
          }
          fixed
        />
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.identification}>
              <Text style={styles.name}>
                {contactInfo.firstName.toUpperCase()}{" "}
                {contactInfo.lastName.toUpperCase()}
              </Text>
            </View>
            <View style={styles.communication}>
              <Text id="address">
                {contactInfo.city ? contactInfo.city : ""}
                {contactInfo.state ? "," : ""} {contactInfo.state}
                {contactInfo.country ? "," : ""}{" "}
                {contactInfo.country ? contactInfo.country : ""}{" "}
                {contactInfo.postalCode ? contactInfo.postalCode : ""}
              </Text>
              <Text id="email">
                {contactInfo.email ? contactInfo.email : ""}
              </Text>
              <Text id="phone">
                {contactInfo.phoneNumber ? contactInfo.phoneNumber : ""}
              </Text>
              <Text id="profile1">
                {contactInfo.onlineProfile1 ? contactInfo.onlineProfile1 : ""}
              </Text>
              <Text id="profile2">
                {contactInfo.onlineProfile2 ? contactInfo.onlineProfile2 : ""}
              </Text>
              <Text id="profile3">
                {contactInfo.onlineProfile3 ? contactInfo.onlineProfile3 : ""}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>SUMMARY</Text>
          <Html stylesheet={styles}>
            {sanitizeHtml(summary, {
              allowedTags: ["p", "ul", "ol", "li"],
              allowedAttributes: {
                p: ["class", "style"],
                ol: ["class", "style"],
                ul: ["class", "style"],
                li: ["class", "style"],
              },
              allowedClasses: {
                p: ["summary-content-paragraph"],
                ol: ["summary-content-orderedlist"],
                ul: ["summary-content-unorderedlist"],
                li: ["summary-content-list-item"],
              },
              transformTags: {
                p: sanitizeHtml.simpleTransform("p", {
                  class: "summary-content-paragraph",
                }),
                ol: sanitizeHtml.simpleTransform("ol", {
                  class: "summary-content-orderedlist",
                }),
                ul: sanitizeHtml.simpleTransform("ul", {
                  class: "summary-content-unorderedlist",
                }),
                li: sanitizeHtml.simpleTransform("li", {
                  class: "summary-content-list-item",
                }),
              },
            })}
          </Html>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>EXPERIENCE</Text>
          {employementInfo.employment.map((item) => (
            <View style={styles.job} wrap={true}>
              <View style={styles.jobHeader} wrap={false}>
                <Text style={styles.jobTitle}>
                  {item.designation ? item.designation : ""}
                </Text>
                <Text style={styles.jobDate}>
                  {item.from ? item.from + " " : ""}
                  {(item.to || item.iscurrentJob) && item.from ? " - " : ""}
                  {item.to ? item.to : item.iscurrentJob ? "Present" : ""}
                </Text>
              </View>
              <Text style={styles.jobCompany}>
                {item.company ? item.company : ""}
                {item.location ? ", " + item.location : ""}
              </Text>
              {item.jobDescription && item.jobDescription !== "<p></p>" ? (
                <Html
                  style={styles.jobDescription}
                  stylesheet={styles}
                  wrap={true}
                >
                  {sanitizeHtml(item.jobDescription, {
                    allowedTags: ["p", "ul", "ol", "li"],
                    allowedAttributes: {
                      p: ["class", "style"],
                      ol: ["class", "style"],
                      ul: ["class", "style"],
                      li: ["class", "style"],
                    },
                    allowedClasses: {
                      p: ["job-description-paragraph"],
                      ol: ["job-description-orderedlist"],
                      ul: ["job-description-unorderedlist"],
                      li: ["job-description-list-item"],
                    },
                    transformTags: {
                      p: sanitizeHtml.simpleTransform("p", {
                        class: "job-description-paragraph",
                      }),
                      ol: sanitizeHtml.simpleTransform("ol", {
                        class: "job-description-orderedlist",
                      }),
                      ul: sanitizeHtml.simpleTransform("ul", {
                        class: "job-description-unorderedlist",
                      }),
                      li: sanitizeHtml.simpleTransform("li", {
                        class: "job-description-list-item",
                      }),
                    },
                  })}
                </Html>
              ) : (
                <Html stylesheet={styles} wrap={true}></Html>
              )}
              {item.softSkills?.length || item.hardSkills?.length ? (
                <View style={styles.skills}>
                  {item.hardSkills?.length ? (
                    <View style={styles.hardSkills} wrap={false}>
                      <Text style={styles.hardSkillsTitle}>
                        {"Hard Skills: "}
                      </Text>
                      <Text style={styles.hardSkillsItems}>
                        {item.hardSkills.join(", ")}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  {item.softSkills?.length ? (
                    <View style={styles.softSkills} wrap={false}>
                      <Text style={styles.softSkillsTitle}>
                        {"Soft Skills: "}
                      </Text>
                      <Text style={styles.softSkillsItems}>
                        {item.softSkills.join(", ")}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </View>
        {checkForEmpty(educationInfo.education) ? (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>EDUCATION</Text>
            {educationInfo.education.map((item) => (
              <View style={styles.education} wrap={true}>
                <View style={styles.educationHeader} wrap={false}>
                  <Text style={styles.educationTitle}>
                    {item.degreeType ? item.degreeType : ""}
                    {item.degreeType && item.field ? " in " : ""}
                    {item.field ? item.field : ""}
                    {item.field && item.school ? " @ " : ""}
                    {item.school ? item.school : ""}{" "}
                  </Text>
                  <Text style={styles.educationDate}>
                    {item.from ? item.from : ""}
                    {item.to && item.from ? " - " : ""} {item.to ? item.to : ""}
                  </Text>
                </View>
                <View wrap={false}>
                  <Text>{item.minor ? "Minor in " + item.minor : ""}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <></>
        )}
        {resumeScreens.map((item) => (
          <View>
            {otheresSections &&
            typeof otheresSections[item.id] === "string" &&
            otheresSections[item.id] ? (
              <View style={styles.section} wrap={true}>
                <Text id="other" style={styles.sectionTitle} wrap={false}>
                  {item.title.toUpperCase()}
                </Text>
                <Html style={styles.otherData} stylesheet={styles} wrap={true}>
                  {sanitizeHtml(otheresSections[item.id], {
                    allowedTags: ["p", "ul", "ol", "li"],
                    allowedAttributes: {
                      p: ["class", "style"],
                      ol: ["class", "style"],
                      ul: ["class", "style"],
                      li: ["class", "style"],
                    },
                    allowedClasses: {
                      p: ["other-data-paragraph"],
                      ol: ["other-data-orderedlist"],
                      ul: ["other-data-unorderedlist"],
                      li: ["other-data-list-item"],
                    },
                    transformTags: {
                      p: sanitizeHtml.simpleTransform("p", {
                        class: "other-data-paragraph",
                      }),
                      ol: sanitizeHtml.simpleTransform("ol", {
                        class: "other-data-orderedlist",
                      }),
                      ul: sanitizeHtml.simpleTransform("ul", {
                        class: "other-data-unorderedlist",
                      }),
                      li: sanitizeHtml.simpleTransform("li", {
                        class: "other-data-list-item",
                      }),
                    },
                  })}
                </Html>
              </View>
            ) : (
              <></>
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
};
const Template1 = ({
  pdfOnly,
  downloadLinkRef,
  isEdit,
  sectionSpacing,
  lineHeight,
  fontSize,
  fontWeight,
  //enabledSections,
  setinstanceLoader,
}) => {
  const {
    contactInfo,
    summary,
    educationInfo,
    employementInfo,
    resumeScreens,
  } = useSelector((state) => state.resumeOptimizer);

  let additionalSections = {};
  resumeScreens.map((item) => {
    const sectionData = useSelector((state) => state.resumeOptimizer[item.id]);
    additionalSections[item.id] = sectionData;
  });

  console.log(useSelector((state) => state.resumeOptimizer));
  console.log(additionalSections);
  const styles = StyleSheet.create(
    getStyles(true, sectionSpacing, lineHeight, fontSize, fontWeight)
  );

  const [instance, updateInstance] = usePDF({
    document: (
      <PdfResume
        contactInfo={contactInfo}
        summary={summary}
        educationInfo={educationInfo}
        employementInfo={employementInfo}
        resumeScreens={resumeScreens}
        otheresSections={additionalSections}
        styles={styles}
      />
    ),
  });

  useEffect(() => {
    if (setinstanceLoader && typeof setinstanceLoader == "function") {
      setinstanceLoader(instance.loading);
    }
  }, [instance.loading]);

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <div>
      {pdfOnly ? (
        <></>
      ) : (
        <HtmlResume
          contactInfo={contactInfo}
          summary={summary}
          educationInfo={educationInfo}
          employementInfo={employementInfo}
          resumeScreens={resumeScreens}
          otheresSections={additionalSections}
          styles={styles}
          isEdit={isEdit}
        />
      )}
      <a
        ref={downloadLinkRef}
        href={instance.url}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Template1;
