import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import * as LoginActions from "../../store/newLogin/newLoginAction";

import "./ResetPassword.scss";
import unnlockNotification from "../../components/notification";
import history from "../../routes/History";
import titleImage from "../../assets/images/UnnlockNew.svg";
import UnnlockLayout from "../layout";
import CommonInput from "../ICDProvider/commonInput";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  newpass: Yup.string().required("Required").required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password does not fulfill all criterias."
    ),
  confirmpass: Yup.string().oneOf(
    [Yup.ref("newpass"), null],
    "Passwords must match"
  ).required("No password provided."),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    // Get the current URL's search parameters
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get the "redirect" parameter value
    const redirect = urlSearchParams.get('redirect');

    if (redirect) {
      // You can use the "redirect" value as needed
      setRedirectUrl(redirect);
    }
  }, []);

  const handleResetPass = (values) => {
    if (redirectUrl !== '') {
      dispatch(
        LoginActions.resetPasswordRequest({
          redirectUrl: redirectUrl,
          new_password: values.newpass,
        })
      );
    } else {
      unnlockNotification("Some Error Occured", "error", 52);
    }
  };

  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      background="white"
      optionalHeader={
        <>
          <div className="loginPage_header">
            <div className="loginPage_header_inner">
              <img
                src={titleImage}
                alt="titleImage"
                className="unnlockIcon"
              />
            </div>
          </div>
        </>
      }
    >
      <div className="login-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <div className="login-section">
            <div
              style={{
                fontSize: "20px",
                fontWeight: 500,
                marginBottom: "24px",
                textAlign: "center",
              }}
            >
              Reset Password
            </div>
            <Formik
              initialValues={{
                newpass: "",
                confirmpass: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                handleResetPass(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <CommonInput
                    formField
                    name="newpass"
                    placeholder="New Password"
                    type="password" />
                  <div
                    className="error_fields"
                    style={{
                      color: touched.newpass && errors.newpass ? "" : "transparent",
                    }}
                  >
                    {errors?.newpass}
                  </div>
                  <CommonInput
                    formField
                    name="confirmpass"
                    placeholder="Confirm Password"
                    type="password"
                  />
                  <div
                    className="error_fields"
                    style={{
                      color:
                        touched.confirmpass && errors.confirmpass ? "" : "transparent",
                    }}
                  >
                    {errors?.confirmpass}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button type="submit" className="login_btn">
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        history.push("/login");
                      }}
                      className="forgotpass_btn"
                    >
                      Back to Login
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </UnnlockLayout>
  );
};
export default ResetPassword;
