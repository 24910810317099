/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import Template1 from "../templates/template1";
import Template2 from "../templates/template2";

const Preview = (props) => {
  const { currentTemplate, loader } = useSelector(
    (state) => state.resumeOptimizer
  );
  const getTemplate = () => {
    switch (currentTemplate) {
      case 1:
        return <Template1 {...props} />;
      case 2:
        return <Template2 {...props} />;
      default:
        return <Template1 {...props} />;
    }
  };
  console.log(props, "isEdit");
  return (
    <div>
      <Skeleton loading={loader} active>
        {getTemplate()}
      </Skeleton>
    </div>
  );
};

export default Preview;
