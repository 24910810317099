/* eslint-disable react/jsx-key */
import React from "react";
import { Row, List, Card, Layout, Spin } from "antd";

import "./InterestingReads.scss";
import NewHeader from "../../components/newHeader";

import readImg1 from "../../assets/images/selector/readimg1.svg";
import readImg2 from "../../assets/images/selector/readimg2.svg";
import readImg3 from "../../assets/images/selector/readimg3.svg";
import readImg4 from "../../assets/images/selector/readimg4.svg";
import UnnlockLayout from "../layout";
import LinkPreview from "./linkPreview";

const { Content } = Layout;

const data = [
  {
    title: "Title 1",
    src: readImg1,
    link: "https://www.w3schools.com/cssref/css3_pr_background.php",
  },
  {
    title: "Title 2",
    src: readImg2,
    link: "https://www.netflix.com/watch/81320086?trackId=155573558",
  },
  {
    title: "Title 3",
    src: readImg3,
    link: "https://bitbucket.org/aamiined/miined_job_board/src/master/",
  },
  {
    title: "Title 6",
    src: readImg3,
    link: "https://www.naukri.com/mnjuser/performance#recruiterActions",
  },
  {
    title: "Title 4",
    src: readImg4,
    link: "https://www.canva.com/projects",
  },
  {
    title: "Title 5",
    src: readImg1,
    link: "https://www.figma.com/file/xaLHjTmr4mblCyV2XTPdAF/Unnlock-VD---CC?node-id=3044%3A23029&t=Gw88GxJXA9D4vQ99-0",
  },
 
];
const InterestingReads = () => {
  return (
    <UnnlockLayout>
      <Content style={{ padding: "32px" }}>
        <Row>
          <p className="interestingReadsTitle">Interesting Reads</p>
        </Row>
        <Row>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 3,
            }}
            dataSource={data}
            renderItem={(item, i) => (
              <List.Item>
                <LinkPreview index={i} link={item.link} />
              </List.Item>
            )}
          />
        </Row>
      </Content>
    </UnnlockLayout>
  );
};

export default InterestingReads;
