/* eslint-disable */
import { put, takeLatest, call, select } from "redux-saga/effects";
import { message } from "antd";
import history from "../../routes/History";
import * as ACTIONS from "./action";
import * as LOGINACTIONS from "../newLogin/newLoginAction";
import * as TYPES from "./types";
import Network from "../../network";
import unnlockNotification from "../../components/notification";

const { API, Config } = Network;

function* getFilteredCompList() {
  try {
    yield put(ACTIONS.companiesLoader(true));
    const faverates = yield select(
      (store) => store.login.userData?.favourite_companies || []
    );
    const tierId = yield select((store) => store.login.userData?.tier_id);
    const result = yield call(
      API.post,
      `${Config.resumeParse.companySearch()}?request_id=${
        tierId === 0 ? "9c5dc84d-f78d-438e-b6d4-125f599b6ccf" : "someRandomeId"
      }`,
      {
        // company_name: localStorage.getItem("comanyMatch_companyName"),
        // company_url: localStorage.getItem("comanyMatch_companyUrl"),
        // linkedin_url: localStorage.getItem("comanyMatch_companyLinkedIn"),
        unnlock_id: localStorage.getItem("comanyMatch_unnlock_id") || "",
        favourite_companies: faverates,
      },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("Extract:::", result);
    yield put(ACTIONS.companiesSearchSuccess(result));
    yield put(ACTIONS.companiesLoader(false));
  } catch (error) {
    yield put(ACTIONS.companiesSearchFailure(error));
    yield put(ACTIONS.companiesLoader(false));
    // yield put(LOGINACTIONS.checkTokanExpiry());
  }
}

// function* getCompaniesList(action) {
//   try {
//     const params = action.payload;
//     const { companyName, title } = params;
//     yield put(ACTIONS.companiesLoader(true));

//     const result = yield call(
//       API.get,
//       Config.resumeParse.companiesList(companyName, title),
//       {
//         Authorization: localStorage.getItem("unnlockUserToken"), // id_token
//       }
//     );

//     console.log("Company Listss:::", result);
//     yield put(ACTIONS.companiesListSuccess(result));
//     yield put(ACTIONS.companiesLoader(false));
//   } catch (error) {
//     yield put(ACTIONS.companiesListFailure(error));
//     yield put(ACTIONS.companiesLoader(false));
//   }
// }

function* favouriteClick(action) {
  try {
    const params = action.payload;
    const { operation, companyId, urlKey } = params;
    const userData = yield select((store) => store.login.userData);
    let favArr = [];
    if (userData[urlKey]?.includes(`${companyId}`)) {
      favArr = userData[urlKey].filter((item) => item !== `${companyId}`);
    } else {
      favArr = [...userData[urlKey], `${companyId}`];
    }
    yield put(
      LOGINACTIONS.getUserDataSuccess({
        ...userData,
        [urlKey]: favArr,
      })
    );
    // yield put(ACTIONS.companiesLoader(true));
    const result = yield call(
      API.get,
      Config.favourite.favouriteUrl(operation, companyId, urlKey),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    // console.log("Favourite CLick:::", result);
    yield put(
      ACTIONS.favouriteClickSuccess({
        companyId: companyId,
        operation: operation,
      })
    );
    // yield put(ACTIONS.companiesLoader(false));
  } catch (error) {
    yield put(ACTIONS.favouriteClickFailure(error));
    // yield put(ACTIONS.companiesLoader(false));3
    // yield put(LOGINACTIONS.checkTokanExpiry());
    unnlockNotification("Please try again.", "warning");
  }
}

function* getNearestComp(action) {
  try {
    const params = action.payload;
    const { companyName } = params;
    const result = yield call(
      API.post,
      Config.companyDetails.getNearestCompany(companyName),
      { company_name: companyName },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getNearestComponiesSuccess(result));
  } catch (error) {
    // message.error(error.message);
    yield put(ACTIONS.getNearestComponiesFailure(error));
  }
}

function* autoComplete(action) {
  try {
    const params = action.payload;
    const { companyName } = params;
    const result = yield call(
      API.get,
      Config.companyDetails.getAutoComplete(companyName),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.companiesAutoCompleteSuccess(result));
  } catch (error) {
    yield put(ACTIONS.companiesAutoCompleteFailure(error));
  }
}

function* getCompanyLocations(action) {
  try {
    const params = action.payload;
    const { unlockId } = params;
    const result = yield call(
      API.get,
      Config.companyDetails.getCompanyLocations(unlockId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getCompanyLocationsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getCompanyLocationsFailure(error));
  }
}

function* updateTopComponies(action) {
  try {
    const params = action.payload;
    const { companyName, industryName, resumeId } = params;
    const result = yield call(
      API.put,
      Config.companyDetails.updateTopCompany(resumeId),
      { CompanyMatch: companyName, IndustryMatch: industryName },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.updateTopComponiesSuccess(result));
  } catch (error) {
    yield put(ACTIONS.updateTopComponiesFailure(error));
  }
}

function* handleCompanyDeatils(action) {
  try {
    const params = action.payload;
    yield put(ACTIONS.companiesLoader(true));
    const result = yield call(
      API.get,
      Config.companyDetails.getCompanyDeatils(params),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    if (result.status === "SUBSCRIPTION_EXPIRED") {
      unnlockNotification(result.message, "warning", 0, 10);
      yield put(ACTIONS.getComponyDeatailsFailure("SUBSCRIPTION_EXPIRED"));
    } else {
      yield put(ACTIONS.getComponyDeatailsSuccess(result));
    }
  } catch (error) {
    yield put(ACTIONS.getComponyDeatailsFailure(error));
    // yield put(LOGINACTIONS.checkTokanExpiry());
  }
}

function* handleSocsSkillsRequest(action) {
  try {
    const params = action.payload;
    const { skillsArr, skill, id } = params;
    const result = yield call(
      API.post,
      Config.companyDetails.getSocsSkillsArrayUrl(skill, id),
      { skills: skillsArr },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(
      ACTIONS.getSocSkillsSuccess({ type: `${skill}SocArray`, result })
    );
  } catch (error) {
    yield put(ACTIONS.getSocSkillsFailure(error));
    // yield put(LOGINACTIONS.checkTokanExpiry());
  }
}

function* handleMatchIndustryRequest(action) {
  try {
    const params = action.payload;
    console.log("OKBBJR", params);
    const result = yield call(
      API.post,
      Config.companyDetails.getMatchIndustryUrl(),
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getMatchIndustrySuccess(result));
  } catch (error) {
    yield put(ACTIONS.getMatchIndustryFailure(error));
  }
}

function* handleDashboardCompanyDeatils(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.companyDetails.getDashboardCompanyDeatils(),
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    yield put(ACTIONS.getDashboardComponyDeatailsSuccess(result));
  } catch (error) {
    // console.log("fsfsfs")
    yield put(ACTIONS.getDashboardComponyDeatailsFailure(error));
    // yield put(LOGINACTIONS.checkTokanExpiry());
  }
}

function* handleSearchCompBySkills(action) {
  try {
    const postData = action.payload;
    const tierId = yield select((store) => store.login.userData?.tier_id);
    const result = yield call(
      API.post,
      `${Config.companyDetails.searchBySkills}?request_id=${
        tierId === 0 ? "9c5dc84d-f78d-438e-b6d4-125f599b6ccf" : ""
      }`,
      postData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.searchCompBySkillsSuccess(result));
    yield put(ACTIONS.companiesSearchSuccess(result));
    yield put(ACTIONS.companiesLoader(false));
    if (result.status === "SUBSCRIPTION_EXPIRED") {
      unnlockNotification(result.message, "warning", 0, 10);
      yield put(ACTIONS.searchCompBySkillsFailure(""));
    }
    if (Object.keys(result).length === 1) {
      unnlockNotification("No companies found for selected skills!", "warning");
    } else {
      history.push("/companyMatch?ExploreBySkills=true");
    }
  } catch (error) {
    yield put(ACTIONS.searchCompBySkillsFailure(error));
  }
}

function* getComponiesByLocation(action) {
  try {
    const postData = action.payload;
    const result = yield call(
      API.post,
      Config.companyDetails.getCompaniesByLocation(),
      postData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    if (result.message) {
      unnlockNotification(result.message, "warning");
      yield put(ACTIONS.companiesLoader(false));
    } else {
      yield put(ACTIONS.getComponiesByLocationSuccess(result));
      yield put(ACTIONS.companiesLoader(false));
    }
  } catch (error) {
    yield put(ACTIONS.getComponiesByLocationFailure(error));
  }
}

export function* requestFavClicked() {
  yield takeLatest(TYPES.FAVOURITE_REQUEST, favouriteClick);
}

export function* requestFilteredCompList() {
  yield takeLatest(TYPES.COMPANIESSEARCH_LIST_REQUEST, getFilteredCompList);
}

export function* requestNearestComponies() {
  yield takeLatest(TYPES.GET_NEAREST_COMPONIES_REQUEST, getNearestComp);
}

export function* requestAutoComplete() {
  yield takeLatest(TYPES.GET_AUTOCOMPLETE_REQUEST, autoComplete);
}

export function* requestupdateTopComp() {
  yield takeLatest(TYPES.UPDATE_TOPCOMPONIES_REQUEST, updateTopComponies);
}

export function* requestCompanyDeatils() {
  yield takeLatest(TYPES.GET_COMPANY_DEATILS_REQUEST, handleCompanyDeatils);
}

export function* requestSocsSkills() {
  yield takeLatest(TYPES.GET_SOCS_SKILLS_REQUEST, handleSocsSkillsRequest);
}

export function* requestMatchIndustry() {
  yield takeLatest(
    TYPES.GET_MATCH_INDUSTRY_REQUEST,
    handleMatchIndustryRequest
  );
}

export function* requestDashoardCompanyDeatils() {
  yield takeLatest(
    TYPES.GET_DASHBOARD_COMPANY_DEATILS_REQUEST,
    handleDashboardCompanyDeatils
  );
}

export function* requestSearchCompBySkills() {
  yield takeLatest(TYPES.SEARCH_BY_SKILLS_REQUEST, handleSearchCompBySkills);
}

export function* requestCompanyLocations() {
  yield takeLatest(TYPES.GET_COMPANY_LOCATIONS_REQUEST, getCompanyLocations);
}

export function* requestCompaniesByLocation() {
  yield takeLatest(
    TYPES.GET_COMPANY_BY_LOCATIONS_REQUEST,
    getComponiesByLocation
  );
}

// export function* requestCompaniesList() {
//   yield takeLatest(TYPES.COMPANIES_LIST_REQUEST, getCompaniesList);
// }
