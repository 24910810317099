import * as types from "./types";

export const companiesLoader = (payload) => ({
  type: types.COMPANY_LOADER,
  payload,
});
export const addFavComponies = (payload) => ({
  type: types.ADD_FAVRATES_COMPANIES,
  payload,
});
export const addFilteres = (payload) => ({
  type: types.ADD_FILTERES,
  payload,
});

export const companiesSearchRequest = (payload) => ({
  type: types.COMPANIESSEARCH_LIST_REQUEST,
  payload,
});
export const companiesSearchSuccess = (payload) => ({
  type: types.COMPANIESSEARCH_LIST_SUCCESS,
  payload,
});
export const companiesSearchFailure = (payload) => ({
  type: types.COMPANIESSEARCH_LIST_FAILURE,
  payload,
});

export const companiesAutoCompleteRequest = (payload) => ({
  type: types.GET_AUTOCOMPLETE_REQUEST,
  payload,
});
export const companiesAutoCompleteSuccess = (payload) => ({
  type: types.GET_AUTOCOMPLETE_SUCCESS,
  payload,
});
export const companiesAutoCompleteFailure = (payload) => ({
  type: types.GET_AUTOCOMPLETE_FAILURE,
  payload,
});

export const favouriteClickRequest = (payload) => ({
  type: types.FAVOURITE_REQUEST,
  payload,
});
export const favouriteClickSuccess = (payload) => ({
  type: types.FAVOURITE_SUCCESS,
  payload,
});
export const favouriteClickFailure = (payload) => ({
  type: types.FAVOURITE_FAILURE,
  payload,
});

export const getNearestComponiesRequest = (payload) => ({
  type: types.GET_NEAREST_COMPONIES_REQUEST,
  payload,
});
export const getNearestComponiesSuccess = (payload) => ({
  type: types.GET_NEAREST_COMPONIES_SUCCESS,
  payload,
});
export const getNearestComponiesFailure = (payload) => ({
  type: types.GET_NEAREST_COMPONIES_FAILURE,
  payload,
});

export const getComponyDeatailsRequest = (payload) => ({
  type: types.GET_COMPANY_DEATILS_REQUEST,
  payload,
});
export const getComponyDeatailsSuccess = (payload) => ({
  type: types.GET_COMPANY_DEATILS_SUCCESS,
  payload,
});
export const getComponyDeatailsFailure = (payload) => ({
  type: types.GET_COMPANY_DEATILS_FAILURE,
  payload,
});

export const getSocSkillsRequest = (payload) => ({
  type: types.GET_SOCS_SKILLS_REQUEST,
  payload,
});
export const getSocSkillsSuccess = (payload) => ({
  type: types.GET_SOCS_SKILLS_SUCCESS,
  payload,
});
export const getSocSkillsFailure = (payload) => ({
  type: types.GET_SOCS_SKILLS_FAILURE,
  payload,
});

export const getMatchIndustryRequest = (payload) => ({
  type: types.GET_MATCH_INDUSTRY_REQUEST,
  payload,
});
export const getMatchIndustrySuccess = (payload) => ({
  type: types.GET_MATCH_INDUSTRY_SUCCESS,
  payload,
});
export const getMatchIndustryFailure = (payload) => ({
  type: types.GET_MATCH_INDUSTRY_FAILURE,
  payload,
});

export const updateTopComponiesRequest = (payload) => ({
  type: types.UPDATE_TOPCOMPONIES_REQUEST,
  payload,
});
export const updateTopComponiesSuccess = (payload) => ({
  type: types.UPDATE_TOPCOMPONIES_SUCCESS,
  payload,
});
export const updateTopComponiesFailure = (payload) => ({
  type: types.UPDATE_TOPCOMPONIES_FAILURE,
  payload,
});

export const getDashboardComponyDeatailsRequest = (payload) => ({
  type: types.GET_DASHBOARD_COMPANY_DEATILS_REQUEST,
  payload,
});
export const getDashboardComponyDeatailsSuccess = (payload) => ({
  type: types.GET_DASHBOARD_COMPANY_DEATILS_SUCCESS,
  payload,
});
export const getDashboardComponyDeatailsFailure = (payload) => ({
  type: types.GET_DASHBOARD_COMPANY_DEATILS_FAILURE,
  payload,
});

export const searchCompBySkillsRequest = (payload) => ({
  type: types.SEARCH_BY_SKILLS_REQUEST,
  payload,
});
export const searchCompBySkillsSuccess = (payload) => ({
  type: types.SEARCH_BY_SKILLS_SUCCESS,
  payload,
});
export const searchCompBySkillsFailure = (payload) => ({
  type: types.SEARCH_BY_SKILLS_FAILURE,
  payload,
});

export const getCompanyLocationsRequest = (payload) => ({
  type: types.GET_COMPANY_LOCATIONS_REQUEST,
  payload,
});
export const getCompanyLocationsSuccess = (payload) => ({
  type: types.GET_COMPANY_LOCATIONS_SUCCESS,
  payload,
});
export const getCompanyLocationsFailure = (payload) => ({
  type: types.GET_COMPANY_LOCATIONS_FAILURE,
  payload,
});

export const selectedCompanyId = (payload) => ({
  type: types.SELECTED_COMPANY_ID,
  payload,
});

export const hardSoftCertSocsCalled = (payload) => ({
  type: types.HARD_SOFT_CERT_TXT,
  payload,
});

export const resetutoComplete = (payload) => ({
  type: types.RESET_AUTOCOMPLETE,
  payload,
});

export const getComponiesByLocationRequest = (payload) => ({
  type: types.GET_COMPANY_BY_LOCATIONS_REQUEST,
  payload,
});
export const getComponiesByLocationSuccess = (payload) => ({
  type: types.GET_COMPANY_BY_LOCATIONS_SUCCESS,
  payload,
});
export const getComponiesByLocationFailure = (payload) => ({
  type: types.GET_COMPANY_BY_LOCATIONS_FAILURE,
  payload,
});

export const setExploreSkills = (payload) => ({
  type: types.SET_EXPLORE_SKILLS,
  payload,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
