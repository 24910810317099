import { isString } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import DownGrowth from "../../assets/images/DownGrowth.svg";
import Growth from "../../assets/images/Growth.svg";
import "./OfferEvaluatorResult.scss";

const RatingBar = ({ fromJobMatch, ratings }) => {
  if (!ratings) {
    return null;
  } else if (isString(ratings)) {
    return <p>{ratings}</p>;
  }

  let circlrColor;
  let circleObjArr = [];

  if (ratings >= 3) {
    circlrColor = "#009900";
  } else if (ratings === 2) {
    circlrColor = "#F19737";
  } else {
    circlrColor = "#ED6E57";
  }

  for (let index = 1; index <= 5; index++) {
    if (index <= ratings) {
      circleObjArr.push({ color: circlrColor });
    } else {
      circleObjArr.push({ color: "rgba(0, 153, 0, 0.2)" });
    }
  }

  return (
    <div className="ratingdiv">
      {circleObjArr.map((obj) => {
        return (
          <span className="circle" style={{ background: obj.color }}></span>
        );
      })}
      {/* {fromJobMatch ? null : (
        <img
          src={ratings >= 3 ? Growth : DownGrowth}
          style={{ marginTop: "-5px" }}
        />
      )} */}
    </div>
  );
};

export default RatingBar;
