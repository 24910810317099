import * as Yup from "yup";

export const key = "trainingHistory";
export const schema = [
  // {
  //   field: "input",
  //   label: "Type",
  //   id: "type",
  //   type: "text",
  // },
  {
    field: "input",
    label: "Title",
    id: "title",
    type: "text",
  },
  {
    field: "monthnyearpicker",
    label: "Date",
    id: "endDate",
    type: "date",
  },
  // {
  //   field: "textArea",
  //   label: "Job Description",
  //   id: "jobDescription",
  //   type: "text",
  //   grid: 24,
  // },
];

export const values = {
  // type: "",
  title: "",
  // jobDescription: "",
};
export const initialValues = { certification: [values] };
export const validation = Yup.object().shape({
  certification: Yup.array().of(
    Yup.object().shape({
      // type: Yup.string().required("Type is required"),
      title: Yup.string().required("Title is required"),
      // jobDescription: Yup.string().required("Job description is required"),
    })
  ),
});
