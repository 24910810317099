/*eslint-disable*/
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";

import FroalaEditor from "react-froala-wysiwyg";
import React from "react";

const buttons = [
  "paragraphFormat",
  "bold",
  "italic",
  "underline",
  "strikeThrough",
  "align",
  "formatOL",
  "formatUL",
  "insertTable",
  "insertImage",
  "insertLink",
  "undo",
  "redo",
  "quote",
  "fullscreen",
  "html",
  "|",
];

const config = {
  height: 200,
  toolbarButtons: buttons,
  toolbarButtonsMD: buttons,
  toolbarButtonsSM: buttons,
  toolbarButtonsXS: buttons,
  spellcheck: false,
  placeholderText: "Describe here...",
  key: "8QwMNnfVP2pD100",
};

// Render Froala Editor component.
class FroalaEditorComp extends React.Component {
  render() {
    const { handleEditorChange, value, id } = this.props;
    // console.log(value, "value");
    return (
      <div>
        <FroalaEditor
          config={config}
          model={value}
          onModelChange={(e) => handleEditorChange(e, id)}
        />
      </div>
    );
  }
}

export default FroalaEditorComp;
