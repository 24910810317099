export const COMPANY_LOADER = "componies/COMPANY_LOADER";
export const ADD_FAVRATES_COMPANIES = "componies/ADD_FAVRATES_COMPANIES";
export const ADD_FILTERES = "componies/ADD_FILTERES";

export const COMPANIESSEARCH_LIST_REQUEST =
  "componies/COMPANIESSEARCH_LIST_REQUEST";
export const COMPANIESSEARCH_LIST_SUCCESS =
  "componies/COMPANIESSEARCH_LIST_SUCCESS";
export const COMPANIESSEARCH_LIST_FAILURE =
  "componies/COMPANIESSEARCH_LIST_FAILURE";

export const FAVOURITE_REQUEST = "componies/FAVOURITE_REQUEST";
export const FAVOURITE_SUCCESS = "componies/FAVOURITE_SUCCESS";
export const FAVOURITE_FAILURE = "componies/FAVOURITE_FAILURE";

export const GET_NEAREST_COMPONIES_REQUEST =
  "componies/GET_NEAREST_COMPONIES_REQUEST";
export const GET_NEAREST_COMPONIES_SUCCESS =
  "componies/GET_NEAREST_COMPONIES_SUCCESS";
export const GET_NEAREST_COMPONIES_FAILURE =
  "componies/GET_NEAREST_COMPONIES_FAILURE";

export const GET_COMPANY_DEATILS_REQUEST =
  "componies/GET_COMPANY_DEATILS_REQUEST";
export const GET_COMPANY_DEATILS_SUCCESS =
  "componies/GET_COMPANY_DEATILS_SUCCESS";
export const GET_COMPANY_DEATILS_FAILURE =
  "componies/GET_COMPANY_DEATILS_FAILURE";

export const GET_AUTOCOMPLETE_REQUEST = "componies/GET_AUTOCOMPLETE_REQUEST";
export const GET_AUTOCOMPLETE_SUCCESS = "componies/GET_AUTOCOMPLETE_SUCCESS";
export const GET_AUTOCOMPLETE_FAILURE = "componies/GET_AUTOCOMPLETE_FAILURE";

export const UPDATE_TOPCOMPONIES_REQUEST =
  "componies/UPDATE_TOPCOMPONIES_REQUEST";
export const UPDATE_TOPCOMPONIES_SUCCESS =
  "componies/UPDATE_TOPCOMPONIES_SUCCESS";
export const UPDATE_TOPCOMPONIES_FAILURE =
  "componies/UPDATE_TOPCOMPONIES_FAILURE";

export const GET_DASHBOARD_COMPANY_DEATILS_REQUEST =
  "componies/GET_DASHBOARD_COMPANY_DEATILS_REQUEST";
export const GET_DASHBOARD_COMPANY_DEATILS_SUCCESS =
  "componies/GET_DASHBOARD_COMPANY_DEATILS_SUCCESS";
export const GET_DASHBOARD_COMPANY_DEATILS_FAILURE =
  "componies/GET_DASHBOARD_COMPANY_DEATILS_FAILURE";

export const SEARCH_BY_SKILLS_REQUEST = "componies/SEARCH_BY_SKILLS_REQUEST";
export const SEARCH_BY_SKILLS_SUCCESS = "componies/SEARCH_BY_SKILLS_SUCCESS";
export const SEARCH_BY_SKILLS_FAILURE = "componies/SEARCH_BY_SKILLS_FAILURE";

export const GET_SOCS_SKILLS_REQUEST = "componies/GET_SOCS_SKILLS_REQUEST";
export const GET_SOCS_SKILLS_SUCCESS = "componies/GET_SOCS_SKILLS_SUCCESS";
export const GET_SOCS_SKILLS_FAILURE = "componies/GET_SOCS_SKILLS_FAILURE";

export const GET_MATCH_INDUSTRY_REQUEST =
  "componies/GET_MATCH_INDUSTRY_REQUEST";
export const GET_MATCH_INDUSTRY_SUCCESS =
  "componies/GET_MATCH_INDUSTRY_SUCCESS";
export const GET_MATCH_INDUSTRY_FAILURE =
  "componies/GET_MATCH_INDUSTRY_FAILURE";

export const GET_COMPANY_LOCATIONS_REQUEST =
  "componies/GET_COMPANY_LOCATIONS_REQUEST";
export const GET_COMPANY_LOCATIONS_SUCCESS =
  "componies/GET_COMPANY_LOCATIONS_SUCCESS";
export const GET_COMPANY_LOCATIONS_FAILURE =
  "componies/GET_COMPANY_LOCATIONS_FAILURE";

export const GET_COMPANY_BY_LOCATIONS_REQUEST =
  "componies/GET_COMPANY_BY_LOCATIONS_REQUEST";
export const GET_COMPANY_BY_LOCATIONS_SUCCESS =
  "componies/GET_COMPANY_BY_LOCATIONS_SUCCESS";
export const GET_COMPANY_BY_LOCATIONS_FAILURE =
  "componies/GET_COMPANY_BY_LOCATIONS_FAILURE";

export const SELECTED_COMPANY_ID = "dashboard/SELECTED_COMPANY_ID";

export const SET_EXPLORE_SKILLS = "componies/SET_EXPLORE_SKILLS";

export const HARD_SOFT_CERT_TXT = "dashboard/HARD_SOFT_CERT_TXT";

export const RESET_AUTOCOMPLETE = "dashboard/RESET_AUTOCOMPLETE";

export const RESET = "componies/RESET";
