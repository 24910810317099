/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row, Col, message } from "antd";
import { key, schema, validation, values as schemaValues } from "./schema";
import { formatToEmployementData } from "../../../utils/helper";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import DescriptionEditor from "./descriptionEditor";
// import AutoSave from "../../ResumeVault/autoSave";
import ResumeOptimizerInput from "../resumeOptimizerInput";

// import Check from "../../../assets/images/resumeOptimizer/Check.svg";
// import Uncheck from "../../../assets/images/resumeOptimizer/Uncheck.svg";
import TrashSimple from "../../../assets/images/resumeOptimizer/TrashSimple (red).svg";

import "./index.scss";
import Skills from "./skills";
import GrammerlyDescriptionEditor from "./grammerlyEditor";
import unnlockNotification from "../../../components/notification";
import moment from "moment";
// import { extractSkillsFailure } from "../../../store/dashBoard/dashboardActions";

let unsavedData = "";
let unsavedEditorData = "";

const ExperienceInfo = () => {
  const [display, setDisplay] = useState(false);
  const [saveSkills, setsaveSkills] = useState(false);
  const [refreshJDOnAddOrRemove, setRefreshJDOnAddOrRemove] = useState(false);
  const [editorValues, setEditorValues] = useState([]);
  const [fromValues, setFromValues] = useState([]);
  const [toValues, setToValues] = useState([]);
  const [isCurrentJobs, setIsCurrentJobs] = useState([]);
  const {
    employementInfo,
    currentStep,
    currntEployementIndex,
    experienceSaved,
  } = useSelector((state) => state.resumeOptimizer);
  const skillsArrayObject = useSelector(
    (state) => state.dashboard.skillsArrayObject
  );
  const [editIndex, SetEditIndex] = useState(currntEployementIndex);
  const [currentSoftSkills, setcurrentSoftSkills] = useState([]);
  const [currentHardSkills, setcurrentHardSkills] = useState([]);
  const dispatch = useDispatch();

  const handleEditorChange = (value, index, shouldModify) => {
    const arr = [...editorValues];
    // arr[index] = shouldModify ? removeSuggestionIds(value) : value;
    arr[index] = value;
    setEditorValues(arr);
  };

  const handleFromChange = (value, index) => {
    let d1 = moment(value, "MM/YYYY").toDate();
    let d2 = moment(toValues[index], "MM/YYYY").toDate();
    if (d2.toString() === "Invalid Date") {
      const arr = [...fromValues];
      arr[index] = moment(value, "MM/YYYY").format("MM/YYYY");
      setFromValues(arr);
    } else if (
      d1.getTime() < d2.getTime() &&
      d1.getTime() < moment().getTime()
    ) {
      const arr = [...fromValues];
      arr[index] = moment(value, "MM/YYYY").format("MM/YYYY");
      setFromValues(arr);
    } else {
      unnlockNotification(
        "From date cannot be greater than To date",
        "warning"
      );
    }
  };

  const handleToChange = (value, index) => {
    let d2 = moment(value, "MM/YYYY").toDate();
    let d1 = moment(fromValues[index], "MM/YYYY").toDate();
    if (d1.toString() === "Invalid Date") {
      const arr = [...toValues];
      arr[index] = moment(value, "MM/YYYY").format("MM/YYYY");
      setToValues(arr);
    } else if (d1.getTime() < d2.getTime()) {
      const arr = [...toValues];
      arr[index] = moment(value, "MM/YYYY").format("MM/YYYY");
      setToValues(arr);
    } else {
      unnlockNotification(
        "From date cannot be greater than To date",
        "warning"
      );
    }
  };

  const handleIsCurrentJob = (value, index) => {
    const arr = [...isCurrentJobs];
    arr[index] = value;
    setIsCurrentJobs(arr);
    if (value) {
      const arr = [...toValues];
      arr[index] = value;
      setToValues(arr);
    }
  };

  const removeSuggestionIds = (data) => {
    let str1 = data.replace(
      /<li contenteditable="false" id="li_(\d+(d)*)">/gm,
      "<li>"
    );
    let str2 = str1.replace(
      /<p contenteditable="false" id="p_(\d+(d)*)">/gm,
      "<p>"
    );
    let str3 = str2.split(">&nbsp;</span></div></div>").map((item) => {
      if (item.includes("<div contenteditable")) {
        return item.split("<div contenteditable")[0];
      }
      return item;
    });
    return str3.join("");
  };

  const getPostData = (values, arr, fromConfirmation) => {
    if (values.employment?.length) {
      let formData = {
        employment: values.employment.map((item, i) => {
          return formatToEmployementData(item);
        }),
      };
      formData = {
        employment: formData.employment.map((item, i) => {
          return {
            ...item,
            PositionHistory: [
              {
                ...item.PositionHistory[0],
                Description: arr
                  ? arr[i]
                  : removeSuggestionIds(editorValues[i]),
                currentStep:
                  i === editIndex
                    ? saveSkills || fromConfirmation
                      ? item.PositionHistory[0].currentStep
                      : currentStep + 1
                    : item.PositionHistory[0].currentStep,
                SoftSkills:
                  i === editIndex
                    ? currentSoftSkills
                    : employementInfo.employment[i]?.softSkills,
                HardSkills:
                  i === editIndex
                    ? currentHardSkills
                    : employementInfo.employment[i]?.hardSkills,
                StartDate: fromValues[i] || "",
                EndDate: isCurrentJobs[i] ? "" : toValues[i],
                currentEmployer: isCurrentJobs[i],
              },
            ],
          };
        }),
      };
      return formData;
    }
  };

  const handleSubmit = (values, arr) => {
    let formData = getPostData(values, arr);
    let jd = formData.employment[editIndex].PositionHistory[0].Description;
    let to = formData.employment[editIndex].PositionHistory[0].EndDate;
    let iscurrentJob =
      formData.employment[editIndex].PositionHistory[0].currentEmployer;

    if (!to && !iscurrentJob) {
      unnlockNotification("Please enter end date field!", "warning");
    } else if (!jd || jd === "<p></p>") {
      unnlockNotification("Please enter job descritpion!", "warning");
    } else {
      console.log(formData, "formDataformData");
      saveSkills ? null : dispatch(ResumeActions.setResumeSaveLoader(true));
      dispatch(
        ResumeActions.resumeUpdateRequest({
          formData,
          label: "employment-update",
        })
      );
    }
  };

  const handlePush = () => {
    setEditorValues(["", ...editorValues]);
    setFromValues(["", ...fromValues]);
    setToValues(["", ...toValues]);
    setIsCurrentJobs([false, ...isCurrentJobs]);
    setRefreshJDOnAddOrRemove(true);
    // SetEditIndex(editIndex + 1);
  };

  const handleRemove = (index) => {
    const editorArr = [...editorValues];
    editorArr.splice(index, 1);
    setEditorValues(editorArr);

    const fromArr = [...fromValues];
    fromArr.splice(index, 1);
    setFromValues(fromArr);

    const toArr = [...toValues];
    toArr.splice(index, 1);
    setToValues(toArr);

    const isPresentArr = [...isCurrentJobs];
    isPresentArr.splice(index, 1);
    setIsCurrentJobs(isPresentArr);

    setRefreshJDOnAddOrRemove(true);
  };

  useEffect(() => {
    if (employementInfo) {
      const arr =
        employementInfo.employment.map((item) => item.jobDescription) || [];
      const arr1 = employementInfo.employment.map((item) => item.from) || [];
      const arr2 = employementInfo.employment.map((item) => item.to) || [];
      const arr3 =
        employementInfo.employment.map((item) => item.iscurrentJob) || [];
      setEditorValues(arr);
      setFromValues(arr1);
      setToValues(arr2);
      setIsCurrentJobs(arr3);
      setDisplay(true);
    }
  }, [employementInfo]);

  useEffect(() => {
    dispatch(ResumeActions.setCurrentEmplyementIndex(editIndex));
    setcurrentHardSkills([]);
    setcurrentSoftSkills([]);
    // dispatch(extractSkillsFailure());
  }, [editIndex]);

  useEffect(() => {
    if (saveSkills) {
      document.getElementById("submitExperienceBtn").click();
    }
  }, [saveSkills]);

  // useEffect(() => {
  //   if (skillsArrayObject) {
  //     setcurrentSoftSkills(skillsArrayObject.soft_skills);
  //     setcurrentHardSkills(skillsArrayObject.hard_skills);
  //   }
  // }, [skillsArrayObject]);

  useEffect(() => {
    return () => {
      if (JSON.stringify(unsavedData) !== JSON.stringify(employementInfo)) {
        let formData = getPostData(unsavedData, unsavedEditorData, true);
        dispatch(ResumeActions.setExperienceSaved(formData));
      }
    };
  }, []);

  return (
    <div className="update_experience_container">
      <Row style={{ borderBottom: "1px solid #e4e4e4", marginBottom: "20px" }}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tabs">
              {editorValues.map((item, i) => (
                <div
                  className={editIndex === i ? "activeTab" : "tab"}
                  onClick={() => SetEditIndex(i)}
                >
                  Job {i + 1}
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className="actions">
            {(editIndex || editIndex === 0) && editorValues.length ? (
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <button
                    className="save-button"
                    onClick={() => {
                      setTimeout(() => {
                        SetEditIndex(0);
                        setRefreshJDOnAddOrRemove(false);
                      }, 500);
                      document.getElementById("add_empoyement_infoId").click();
                    }}
                  >
                    + Add Job
                  </button>
                </Col>
                <Col>
                  <button
                    type="button"
                    onClick={() => {
                      // document.getElementById("submitExperienceBtn").click()
                      let formData = getPostData(
                        unsavedData,
                        unsavedEditorData,
                        true
                      );
                      dispatch(
                        ResumeActions.resumeUpdateRequest({
                          formData,
                          label: "employment-update",
                        })
                      );
                    }}
                    className="save-button"
                    style={{ margin: "0px 10px" }}
                  >
                    Save
                  </button>
                </Col>
                <Col>
                  <button
                    className="delete-button"
                    onClick={() => {
                      setTimeout(() => {
                        if (editIndex) {
                          SetEditIndex(editIndex - 1);
                          setRefreshJDOnAddOrRemove(false);
                          document
                            .getElementById("submitExperienceBtn")
                            .click();
                        }
                      }, 1000);
                      document
                        .getElementById(`remove_empoyement_infoId_${editIndex}`)
                        .click();
                    }}
                  >
                    <img src={TrashSimple} alt="remove" />
                    Delete
                  </button>
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

      {display && (
        <>
          <Formik
            initialValues={employementInfo}
            enableReinitialize
            validationSchema={validation}
            onSubmit={(values) => {
              setTimeout(() => {
                handleSubmit(values);
              }, 400);
            }}
          >
            {({ values, errors, touched }) => {
              console.log(values, "values");
              unsavedData = values;
              unsavedEditorData = editorValues;
              return (
                <Form>
                  <FieldArray name={key}>
                    {({ remove, unshift }) => (
                      <div>
                        <div
                          id="add_empoyement_infoId"
                          style={{ display: "none" }}
                          onClick={() => {
                            unshift({
                              designation: "",
                              company: "",
                              location: "",
                              from: "",
                              to: "",
                              jobDescription: "",
                              currentStep: 0,
                            });
                            handlePush();
                          }}
                        >
                          add
                        </div>
                        {values.employment.length > 0 &&
                          values.employment.map((item, i) => (
                            <>
                              {i === editIndex ? (
                                <>
                                  <button
                                    id={`remove_empoyement_infoId_${i}`}
                                    style={{ display: "none" }}
                                    type="button"
                                    onClick={() => {
                                      remove(i);
                                      handleRemove(i);
                                    }}
                                  >
                                    Remove
                                  </button>
                                  <Row gutter={[24, 12]}>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                      <ResumeOptimizerInput
                                        label="Company"
                                        name={`employment[${i}].company`}
                                        type="text"
                                        error={
                                          errors.employment &&
                                          errors.employment[i] &&
                                          errors.employment[i].company
                                        }
                                        touched={
                                          touched.employment &&
                                          touched.employment[i] &&
                                          touched.employment[i].company
                                        }
                                      />
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                      <ResumeOptimizerInput
                                        label="Location"
                                        name={`employment[${i}].location`}
                                        type="text"
                                        error={
                                          errors.employment &&
                                          errors.employment[i] &&
                                          errors.employment[i].location
                                        }
                                        touched={
                                          touched.employment &&
                                          touched.employment[i] &&
                                          touched.employment[i].location
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row gutter={[24, 12]}>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                      <ResumeOptimizerInput
                                        label="From"
                                        name={`employment[${i}].from`}
                                        type="month"
                                        value={fromValues[i]}
                                        // error={
                                        //   errors.employment &&
                                        //   errors.employment[i] &&
                                        //   errors.employment[i].from
                                        // }
                                        // touched={
                                        //   touched.employment &&
                                        //   touched.employment[i] &&
                                        //   touched.employment[i].from
                                        // }
                                        handleChange={(val) =>
                                          handleFromChange(val, i)
                                        }
                                      />
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                      {isCurrentJobs[i] ? (
                                        <ResumeOptimizerInput
                                          label="To"
                                          type="text"
                                          value="Present"
                                        />
                                      ) : (
                                        <ResumeOptimizerInput
                                          label="To"
                                          name={`employment[${i}].to`}
                                          type="month"
                                          // error={
                                          //   errors.employment &&
                                          //   errors.employment[i] &&
                                          //   errors.employment[i].to
                                          // }
                                          // touched={
                                          //   touched.employment &&
                                          //   touched.employment[i] &&
                                          //   touched.employment[i].to
                                          // }
                                          value={toValues[i]}
                                          handleChange={(val) =>
                                            handleToChange(val, i)
                                          }
                                        />
                                      )}
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={isCurrentJobs[i]}
                                          onChange={(val) => {
                                            handleIsCurrentJob(
                                              val.target.checked,
                                              i
                                            );
                                          }}
                                        />
                                        <span style={{ marginLeft: "5px" }}>
                                          Current Job
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                      <ResumeOptimizerInput
                                        label="Designation"
                                        name={`employment[${i}].designation`}
                                        type="text"
                                        error={
                                          errors.employment &&
                                          errors.employment[i] &&
                                          errors.employment[i].designation
                                        }
                                        touched={
                                          touched.employment &&
                                          touched.employment[i] &&
                                          touched.employment[i].designation
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <>
                                    {refreshJDOnAddOrRemove ? null : (
                                      <>
                                        {/* {item.currentStep === 2 ? (
                                          <GrammerlyDescriptionEditor
                                            handleEditorChange={(
                                              value,
                                              index,
                                              shouldModify
                                            ) =>
                                              handleEditorChange(
                                                value,
                                                index,
                                                shouldModify
                                              )
                                            }
                                            value={editorValues[i]}
                                            id={i}
                                            label="Job Highlights"
                                          />
                                        ) : ( */}
                                        <DescriptionEditor
                                          handleEditorChange={(
                                            value,
                                            index,
                                            shouldModify
                                          ) =>
                                            handleEditorChange(
                                              value,
                                              index,
                                              shouldModify
                                            )
                                          }
                                          value={editorValues[i]}
                                          company={
                                            values?.employment[i]?.company
                                          }
                                          designation={
                                            values?.employment[i]?.designation
                                          }
                                          id={i}
                                          editInfo={true}
                                          level={i}
                                          editIndex={editIndex}
                                          currentStep={item.currentStep}
                                          label="Job Highlights"
                                        />
                                        {/* )} */}
                                      </>
                                    )}
                                  </>
                                  {item.currentStep >= 2 ? (
                                    <>
                                      <Skills
                                        title="Hard Skills"
                                        skills={
                                          skillsArrayObject.hard_skills?.map(
                                            (item) => item?.name
                                          ) || []
                                        }
                                        setcurrentSkills={setcurrentHardSkills}
                                        addedSkillss={item.hardSkills || []}
                                        setsaveSkills={setsaveSkills}
                                      />
                                      <Skills
                                        title="Soft Skills"
                                        skills={
                                          skillsArrayObject.soft_skills?.map(
                                            (item) => item?.name
                                          ) || []
                                        }
                                        setcurrentSkills={setcurrentSoftSkills}
                                        addedSkillss={item.softSkills || []}
                                        setsaveSkills={setsaveSkills}
                                      />
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                  <button
                    type="submit"
                    id="submitExperienceBtn"
                    style={{ visibility: "hidden" }}
                    onClick={() => {
                      console.log("clided");
                    }}
                  >
                    Submit
                  </button>
                  {/* <AutoSave debounceMs={1000} /> */}
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};

export default ExperienceInfo;
