/*eslint-disable*/
import React from "react";

const SaveButton = ({ handleClick, styles }) => (
  <div className="save-button" style={styles}>
    <button type="button" onClick={handleClick}>
      Save
    </button>
  </div>
);

export default SaveButton;
