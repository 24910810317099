/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Layout, Spin, Col, Tabs } from "antd";

import "./JobMatchResults.scss";
import CheckRegular from "../../assets/images/CheckRegular.svg";
import XRegular from "../../assets/images/XRegular.svg";

import ellipsesPlaceholder from "../../assets/images/ellipsesPlaceholder.svg";
import ellipsesGreen from "../../assets/images/ellipsesGreen.svg";
import ellipsesRed from "../../assets/images/ellipsesRed.svg";
import ellipsesWarn from "../../assets/images/ellipsesWarn.svg";
import checkMark from "../../assets/images/checkMark.png";
import crossMark from "../../assets/images/crossMark.svg";
import { isEmpty } from "lodash";
import {
  cloneResume,
  setCurrentResumeId,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import MiinedButton from "../../components/Button";
import History from "../../routes/History";
import UpdateSkillsModal from "./updateSkillsModal";
import { useState } from "react";
import {
  getCoverLetter,
  getJobMatchFit,
  getJobMatchSuggestion,
} from "../../store/Tools/toolsActions";
import chatbot from "../../assets/images/chatbot.svg";

// const { Content } = Layout;

const checkvalidArray = (data) => {
  if (Array.isArray(data) && data.length) {
    return true;
  } else return false;
};

const JobResultCards = ({ ...props }) => {
  let iconMatch = null;
  let cssClass = "cardContaent";
  if (props.title === "Matched") {
    iconMatch = CheckRegular;
  } else if (props.title === "Not Matched") {
    iconMatch = XRegular;
  } else {
    cssClass = "smallCardContent";
  }
  return (
    <div
      className={`jobResultCard ${
        props.title === "Matched" || props.title === "Not Matched"
          ? "jobSkillsResultCard"
          : ""
      }`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="heading_container"
      >
        <p className="subHeaderTitle2" style={{ margin: 0 }}>
          {props.title}
          {props.title === "Matched" || props.title === "Not Matched" ? (
            <img
              style={{ margin: "10px 0px 13px 10px" }}
              src={props.title === "Matched" ? checkMark : crossMark}
            />
          ) : null}
        </p>
        {props.title === "Matched" || props.title === "Not Matched" ? (
          <>
            {Array.isArray(props.content) && Array.isArray(props?.unmatched) ? (
              <div className="heading_container_right">
                <div style={{ paddingLeft: "10px" }}>
                  Skills {props.content.length}/
                  {props.content.length + props?.unmatched?.length}
                </div>
              </div>
            ) : (
              <div
                style={{
                  background: "rgba(230, 169, 61, 0.1)",
                  padding: "8px",
                  borderRadius: "3px",
                }}
              >
                Skills not found in resume.
              </div>
            )}
          </>
        ) : null}
      </div>

      {props.title === "Matched" || props.title === "Not Matched" ? (
        <div className={cssClass} style={{ color: "#666666" }}>
          {checkvalidArray(props.content)
            ? props.content.map((companyDeatil, i) => (
                <span style={{ margin: "8px 0px" }}>
                  {companyDeatil}
                  <span
                    style={{ color: "rgb(67, 137, 250)", margin: "0px 8px" }}
                  >
                    {" "}
                    |{" "}
                  </span>
                </span>
              ))
            : null}
        </div>
      ) : (
        <div className={cssClass}>
          <p>{props.content}</p>
        </div>
      )}

      {props.title === "Matched" || props.title === "Not Matched" ? (
        <div style={{ borderTop: "2px solid #8080802b" }} />
      ) : null}

      {props.withDesc ? (
        <>
          <div
            className={cssClass}
            style={{
              color: "#843C0C",
              height: "auto",
              marginTop: "10px",
              fontSize: "14px",
            }}
          >
            These skills from the job description are missing from your resume.
            If you have experience with any of these skills, add them to your
            resume before you apply.
          </div>
        </>
      ) : (
        <>
          {Array.isArray(props.content) && Array.isArray(props?.unmatched) && (
            <div
              className={cssClass}
              style={{
                color: "#6EBF67",
                height: "auto",
                marginTop: "10px",
                fontSize: "14px",
              }}
            >
              Great! These skills from the job description are already on your
              resume. Get more interviews by adding any missing skills related
              to your experience.
            </div>
          )}
        </>
      )}
    </div>
  );
};

const RatingCards = ({ title, bars, notitle }) => {
  let arr = Array.from(Array(bars).keys());
  let arr2 = Array.from(Array(5 - bars).keys());
  return (
    <div style={{ paddingRight: "32px" }}>
      <div className="ratingCardsCon">
        {notitle ? null : <p style={{ width: "50%" }}>{title}</p>}
        <div style={{ display: "flex", alignItems: "center" }}>
          {arr.map((item) => {
            if (arr.length == 1) {
              return (
                <img
                  src={ellipsesRed}
                  alt="ratings"
                  style={{ margin: "0px 5px" }}
                />
              );
            }
            if (arr.length == 2) {
              return (
                <img
                  src={ellipsesWarn}
                  alt="ratings"
                  style={{ margin: "0px 5px" }}
                />
              );
            }
            if (arr.length > 2) {
              return (
                <img
                  src={ellipsesGreen}
                  alt="ratings"
                  style={{ margin: "0px 5px" }}
                />
              );
            }
          })}
          {arr2.map((item) => {
            return (
              <img
                src={ellipsesPlaceholder}
                alt="ratings"
                style={{ margin: "0px 5px" }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

// const getPercentageMatch = (matched, total) => {
//   const percentage = matched / total ? (matched / total) * 100 : 0;
//   return Math.floor(percentage);
// };

const JobMatchResults = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [showUpdateSkils, setshowUpdateSkils] = useState(false);
  const [currentTab, setCurrentTab] = useState("0");
  const [clonedResumes, setClonedResumes] = useState("");
  const [unnlockJMUnamatchedSkills, setUnnlockJMUnamatchedSkills] =
    useState("");
  const {
    jobMatchResultData,
    jobMatchFetchData,
    showUpdateSkillsModal,
    loader: jobMatchLoader,
  } = useSelector((state) => state.jobmatch);
  const { jm_suggestion, jm_fit, loading, coverLetterLoading, coverLetter } =
    useSelector((state) => state.tools);
  const educationInfo = useSelector((state) => state.dashboard.educationInfo);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const { loader, resumeVaults } = useSelector(
    (state) => state.resumeOptimizer
  );
  const { userData } = useSelector((state) => state.login);

  function handleCopy() {
    // Get the text field
    var copyText = document.getElementById("chatBotAnswers");

    // Select the text field
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.innerHTML);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const handleSkillUpdate = () => {
    if (shouldDisplayUpdate()) {
      const { company_name, resume_id, job_match_id } = jobMatchFetchData;
      const unnlockJMUnamatchedSkills = {
        soft_skills: jobMatchResultData.soft_skills?.unmatched,
        hard_skills: jobMatchResultData.hard_skills?.unmatched,
      };
      setUnnlockJMUnamatchedSkills(unnlockJMUnamatchedSkills);
      dispatch(setCurrentResumeId(resume_id));
      setTimeout(() => {
        dispatch(
          cloneResume({
            name: `${company_name || jobMatchResultData.company_name}-resume`,
            note: "Resume created from job tracker",
            fromTracker: true,
            fromJobMatch:
              window.location.pathname === "/jobmatch" ? true : false,
            trackerId: job_match_id || jobMatchResultData.job_match_id,
            currentResume: resume_id || jobMatchResultData.resume_id,
          })
        );
      }, 1000);
    } else if (!shouldDisplayUpdate() && userData && userData.tier_id === 0) {
      History.push("/subscriptions");
    }
  };

  const shouldDisplayUpdate = () => {
    if (
      userData &&
      userData.tier_id &&
      jobMatchResultData &&
      (jobMatchResultData?.hard_skills?.unmatched?.length ||
        jobMatchResultData?.soft_skills?.unmatched?.length)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setClonedResumes(
      resumeVaults.filter(
        (item) =>
          item.cloned_from_tracker &&
          item.job_match_id === jobMatchResultData?.job_match_id
      )
    );
  }, [resumeVaults, jobMatchResultData]);

  useEffect(() => {
    const { text, resume_id } = jobMatchResultData;
    dispatch(
      getJobMatchFit({
        resume_id: resume_id,
        title: "jm_fit",
        jd: text,
      })
    );
    dispatch(
      getJobMatchSuggestion({
        resume_id: resume_id,
        title: "jm_suggestion",
        jd: text,
      })
    );

    return () => {
      setCurrentTab("0");
    };
  }, []);

  const fitAndSuggestionApiCall = (fromTxt) => {
    const { text, resume_id } = jobMatchResultData;
    if (fromTxt === "jm_fit") {
      dispatch(
        getJobMatchFit({
          resume_id: resume_id,
          title: "jm_fit",
          jd: text,
        })
      );
    } else {
      dispatch(
        getJobMatchSuggestion({
          resume_id: resume_id,
          title: "jm_suggestion",
          jd: text,
        })
      );
    }
  };

  const jobBotErrorTxt = (clickFrom) => {
    return (
      <span>
        Coach Unnbot is unavailable at this time. Click{" "}
        <strong
          style={{ borderBottom: "1px solid", cursor: "pointer" }}
          onClick={() => fitAndSuggestionApiCall(clickFrom)}
        >
          here
        </strong>{" "}
        to contact coach.
      </span>
    );
  };

  return (
    <div className="jobMatchResultsContainer">
      <Spin size="large" spinning={loader || jobMatchLoader}>
        <Row>
          {/* <span className="verticalLine" /> */}
          <Col
            md={24}
            style={{
              marginTop: "24px",
              background: "white",
              padding: "15px",
              borderRadius: "24px",
              position: "relative",
            }}
          >
            <Tabs
              onChange={(e) => {
                setCurrentTab(e);
                setshowUpdateSkils(false);
                if (e === "4") {
                  const { text, resume_id } = jobMatchResultData;
                  dispatch(
                    getCoverLetter({
                      resume_id: resume_id,
                      jd: text,
                    })
                  );
                }
              }}
            >
              {[
                "Job Fit",
                "Skill Match",
                "Other Matches",
                "Improve My Resume",
                "Cover letter",
              ].map((item, i) => (
                <Tabs.TabPane tab={item} key={i}>
                  {/* {item} */}
                </Tabs.TabPane>
              ))}
            </Tabs>
            {currentTab === "0" ? (
              <div className="jobfit_container">
                <div className="left">
                  <img
                    src={chatbot}
                    alt="tips"
                    style={{
                      width: "25px",
                      marginBottom: "16px",
                      opacity: 0.5,
                    }}
                  />
                  <div style={{ whiteSpace: "pre-line" }}>
                    {loading || !jm_fit ? (
                      <div style={{ marginRight: "10px", color: "#b5cbef" }}>
                        Responses may take up to 30 seconds to generate during
                        period of high demand
                      </div>
                    ) : jm_fit ===
                      "Coach Unnbot isn’t responding right now. Please try again later." ? (
                      jobBotErrorTxt("jm_fit")
                    ) : (
                      jm_fit
                    )}
                  </div>
                </div>

                <div className="right">
                  <h3>Criteria for each fit category:</h3>
                  <p>
                    <span>Great Fit:</span> Your resume features the most
                    important skills and almost perfectly aligns with the job
                    description. You’d be a great candidate.
                  </p>
                  <p>
                    <span>Good Fit:</span> Your resume positions you as a strong
                    candidate but may be missing a key requirement. Review
                    “Improve My Resume” to see if you can address it before
                    applying.
                  </p>
                  <p>
                    <span>Potential Fit:</span>
                    Your resume aligns well with the job description but is
                    missing several key skills and requirements. Optimize your
                    resume before applying or find a better fit.
                  </p>
                  <p>
                    <span> No Fit:</span>
                    Your resume does not contain the key requirements and
                    critical skills found in the job description.
                  </p>
                </div>
              </div>
            ) : null}

            {currentTab === "1" ? (
              <div>
                {jobMatchResultData.hasOwnProperty("hard_skills") && (
                  <div className="jobMatchResultsSubContainer">
                    <div className="subHeaderTitle2_container">
                      <p className="subHeaderTitle2">Hard Skills</p>
                      {jobMatchResultData.hasOwnProperty("hard_skills") && (
                        <RatingCards
                          title="Hard Skills"
                          bars={jobMatchResultData.hard_skills.bars || 0}
                          notitle
                        />
                      )}
                    </div>
                    <Row className="row">
                      <Col md={12} className="left">
                        <JobResultCards
                          title="Matched"
                          content={jobMatchResultData.hard_skills?.matched}
                          unmatched={jobMatchResultData.hard_skills?.unmatched}
                        />
                      </Col>
                      <Col md={12} className="right">
                        <JobResultCards
                          title="Not Matched"
                          content={jobMatchResultData.hard_skills?.unmatched}
                          withDesc
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {jobMatchResultData.hasOwnProperty("soft_skills") && (
                  <div className="jobMatchResultsSubContainer">
                    <div className="subHeaderTitle2_container">
                      <p className="subHeaderTitle2">Soft Skills</p>
                      {jobMatchResultData.hasOwnProperty("soft_skills") && (
                        <RatingCards
                          title="Soft Skills"
                          bars={jobMatchResultData.soft_skills.bars || 0}
                          notitle
                        />
                      )}
                    </div>
                    <Row className="row">
                      <Col md={12} className="left">
                        <JobResultCards
                          title="Matched"
                          content={jobMatchResultData.soft_skills?.matched}
                          unmatched={jobMatchResultData.soft_skills?.unmatched}
                        />
                      </Col>
                      <Col md={12} className="right">
                        <JobResultCards
                          title="Not Matched"
                          content={jobMatchResultData.soft_skills?.unmatched}
                          withDesc
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            ) : null}

            {currentTab === "2" ? (
              <div className="fourCardsContainer">
                <div className="cardItem">
                  <div className="cardHeader">
                    <p className="cardHeaderTitle">Industry</p>
                    {jobMatchResultData.hasOwnProperty("industry") && (
                      <RatingCards
                        title="Industry"
                        bars={jobMatchResultData.industry.bars || 0}
                        notitle
                      />
                    )}
                  </div>
                  <div className="cardContent">
                    <div className="flex_container">
                      <div className="left">Your Industry :</div>{" "}
                      <div className="right">
                        {jobMatchResultData?.industry?.your}
                      </div>
                    </div>
                    <div className="flex_container">
                      <div className="left">Target Company Industry :</div>
                      <div className="right">
                        {jobMatchResultData?.user_industry}
                      </div>
                    </div>
                  </div>
                  <div className="cardFooter">
                    Recruiters and ATS algorithms favor candidates with
                    experience in the same or a similar industry.
                  </div>
                </div>
                <div className="cardItem">
                  <div className="cardHeader">
                    <p className="cardHeaderTitle">Experience</p>
                    {jobMatchResultData.hasOwnProperty("experience") && (
                      <RatingCards
                        title="Experience"
                        bars={jobMatchResultData.experience.bars || 0}
                        notitle
                      />
                    )}
                  </div>
                  <div className="cardContent">
                    <div className="flex_container">
                      <div className="left">Your Experience :</div>{" "}
                      <div className="right">{`${jobMatchResultData?.experience?.your} Years`}</div>
                    </div>
                    <div className="flex_container">
                      <div className="left">Required Experience :</div>{" "}
                      <div className="right">{`${jobMatchResultData?.experience?.required} Years`}</div>
                    </div>
                  </div>
                  <div className="cardFooter">
                    Recruiting systems may rank candidates based on experience
                    level.
                  </div>
                </div>
                <div className="cardItem">
                  <div className="cardHeader">
                    <p className="cardHeaderTitle">Education</p>
                  </div>
                  <div className="cardContent">
                    <div className="flex_container">
                      <div className="left">Your Education :</div>{" "}
                      <div className="right">
                        {!isEmpty(educationInfo) &&
                          educationInfo.education.length > 0 &&
                          educationInfo.education[0].degreeName}
                      </div>
                    </div>
                    <div className="flex_container">
                      <div className="left">Required Education :</div>{" "}
                      <div className="right">
                        {jobMatchResultData?.education}
                      </div>
                    </div>
                  </div>
                  <div className="cardFooter">
                    Some companies have Education requirements, but generally
                    skills and experience have more importance
                  </div>
                </div>
                <div className="cardItem">
                  <div className="cardHeader">
                    <p className="cardHeaderTitle">Job Title</p>
                  </div>
                  <div className="cardContent">
                    <div className="flex_container">
                      <div className="left">Your Job Title :</div>{" "}
                      <div className="right">
                        {!isEmpty(employementInfo) &&
                          employementInfo.employment.length > 0 &&
                          employementInfo.employment[0].designation}
                      </div>
                    </div>
                    <div className="flex_container">
                      <div className="left">Required Job Title :</div>{" "}
                      <div className="right">
                        {jobMatchResultData?.job_title}
                      </div>
                    </div>
                  </div>
                  <div className="cardFooter">
                    If the Target Job Title is similar, there is no harm in
                    changing your current Job Title to match exactly
                  </div>
                </div>
              </div>
            ) : null}

            {currentTab === "3" ? (
              <div className="improvement_container_outer">
                <div className="improvement_container">
                  <div className="unbotSuggestion">
                    <img
                      src={chatbot}
                      alt="tips"
                      style={{
                        width: "25px",
                        marginBottom: "16px",
                        opacity: 0.5,
                      }}
                    />
                    <div style={{ whiteSpace: "pre-line" }}>
                      {loading || !jm_suggestion ? (
                        <div style={{ marginRight: "10px", color: "#b5cbef" }}>
                          Responses may take up to 30 seconds to generate during
                          period of high demand
                        </div>
                      ) : jm_suggestion ===
                        "Coach Unnbot isn’t responding right now. Please try again later." ? (
                        jobBotErrorTxt("jm_suggestion")
                      ) : (
                        jm_suggestion
                      )}
                    </div>
                  </div>
                </div>
                <div className="improvement_container">
                  <div className="unbotSuggestion">
                    {!shouldDisplayUpdate() &&
                    userData &&
                    userData.tier_id === 0 ? (
                      <MiinedButton
                        title="Subscribe to edit"
                        onClick={() => {
                          handleSkillUpdate();
                        }}
                      />
                    ) : clonedResumes?.length ? (
                      <>
                        <h3>Unnlock created a duplicate resume for this job</h3>

                        {clonedResumes[0]?.file_name ? (
                          <h4>Resume : {clonedResumes[0]?.file_name}</h4>
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                          className="ButtonContainer"
                        >
                          <MiinedButton
                            title="Add Missing Skills"
                            onClick={() => {
                              handleSkillUpdate();
                              setshowUpdateSkils(true);
                            }}
                            className="AddMissingSkillsBtn"
                          />
                          <MiinedButton
                            title="Edit Resume"
                            onClick={() => {
                              History.push({
                                pathname: `/resumeOptimizer`,
                                state: {
                                  resumeId: clonedResumes[0]?.resume_id,
                                  resumeName: clonedResumes[0]?.file_name,
                                },
                              });
                              dispatch(
                                setCurrentResumeId(clonedResumes[0]?.resume_id)
                              );
                            }}
                            className="editResumeBtn"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {shouldDisplayUpdate() ? (
                          <MiinedButton
                            title="Duplicate your resume and add missing skills"
                            onClick={() => {
                              handleSkillUpdate();
                              setshowUpdateSkils(true);
                            }}
                          />
                        ) : (
                          <div>No Unmatched Skills present to update</div>
                        )}
                      </>
                    )}

                    {showUpdateSkils ? (
                      <UpdateSkillsModal
                        visible={true}
                        unnlockJMUnamatchedSkills={unnlockJMUnamatchedSkills}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {currentTab === "4" ? (
              <div>
                <div
                  className="improvement_container"
                  style={{ width: "100%" }}
                >
                  <div className="unbotSuggestion">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={chatbot}
                        alt="tips"
                        style={{
                          width: "25px",
                          marginBottom: "16px",
                          opacity: 0.5,
                        }}
                      />
                      <button
                        style={{
                          border: "none",
                          background: "#4389fa",
                          padding: "2px 5px",
                          borderRadius: "4px",
                          color: "white",
                          cursor: "pointer",
                          width: "60px",
                          margin: "5px",
                        }}
                        onClick={handleCopy}
                      >
                        {copied ? "Copied!" : "Copy"}
                      </button>
                    </div>
                    <div style={{ whiteSpace: "pre-line" }} id="chatBotAnswers">
                      {coverLetterLoading ? (
                        <div style={{ marginRight: "10px", color: "#b5cbef" }}>
                          Responses may take up to 30 seconds to generate during
                          period of high demand
                        </div>
                      ) : (
                        <>{coverLetter}</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default JobMatchResults;
