import React from "react";
import { Space, Spin, Table, Tabs } from "antd";
import CommonInput from "../ICDProvider/commonInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCouponsList,
  getUsersList,
} from "../../store/adminDashboard/actions";
import AddCouponsModal from "./addCouponModal";

const { Column } = Table;

const coupons = [
  {
    coupon_id: "8aa1d3f6-b2ab-4edf-914f-86ec4cf449cf",
    coupon_name: "Beeta200",
    limit: "2",
    usage_count: 0,
    duration: "10",
    is_active: false,
    created_at: "2023-08-26 14:40:14",
    created_by: "daniel@gmail.com",
  },
  {
    coupon_id: "275250aa-a925-4a45-b6cb-7b3188021365",
    coupon_name: "testingui",
    limit: "2",
    usage_count: 0,
    duration: "20",
    is_active: false,
    created_at: "2023-08-26 14:44:54",
    created_by: "daniel@gmail.com",
  },
  {
    coupon_id: "fbcec735-9b35-4afa-bca0-ff5fb94600a2",
    coupon_name: "testing",
    limit: "2",
    usage_count: 0,
    duration: "22",
    is_active: false,
    created_at: "2023-08-26 14:47:41",
    created_by: "daniel@gmail.com",
  },
];

const CouponsComponent = (props) => {
  const dispatch = useDispatch();
  const {
    loader,
    // coupons
  } = useSelector((state) => state.adminDashboard);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setshowModal] = useState(false);

  const applyFilter = (data) => {
    if (searchValue.trim()) {
      return data.filter((item) =>
        item.coupon_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return data;
    }
  };

  useEffect(() => {
    dispatch(getCouponsList());
  }, []);

  console.log(coupons, "coupons");

  return (
    <Spin size="large" spinning={loader}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            background: "white",
          }}
        >
          <h2>
            Coupons{" "}
            <span
              style={{
                background: "rgba(67, 137, 250, 0.1)",
                color: "#1890ff",
                padding: "0px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setshowModal(!showModal);
              }}
            >
              +
            </span>
          </h2>
          <div style={{ width: "30%" }}>
            <CommonInput
              placeholder="Search by name"
              name="coupon"
              type="text"
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <Table dataSource={applyFilter(coupons)}>
            <Column
              title="Coupon Name"
              dataIndex="coupon_name"
              key="coupon_name"
            />
            <Column title="Limit" dataIndex="limit" key="limit" />
            <Column
              title="Created By"
              dataIndex="created_by"
              key="created_by"
            />
            <Column
              title="Usage Count"
              dataIndex="usage_count"
              key="usage_count"
            />
            <Column title="Is Active" dataIndex="is_active" key="is_active" />
            <Column
              title="Action"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <a>Edit</a>
                  <a>Delete</a>
                </Space>
              )}
            />
          </Table>
        </div>
        <AddCouponsModal visible={showModal} />
      </div>
    </Spin>
  );
};

export default CouponsComponent;
