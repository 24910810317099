/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import Network from "../../network";
import unnlockNotification from "../../components/notification";

const { API, Config } = Network;

function* handleUsersList(action) {
  try {
    const getUseres = Config.adminDashboard.getUseres();
    const result = yield call(API.get, getUseres, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getUsersListSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getUsersListFailure(error));
  }
}

function* handleCouponsList(action) {
  try {
    const getCoupons = Config.adminDashboard.getCoupons();
    const result = yield call(API.get, getCoupons, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getCouponsListSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getCouponsListFailure(error));
  }
}

function* handleAddCoupons(action) {
  try {
    const addCoupons = Config.adminDashboard.addCoupons();
    const result = yield call(API.post, addCoupons, action.payload, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.addCouponSuccess(result));
    unnlockNotification(
      `Coupon - ${action.payload.coupon_name} added successfully!`,
      "success"
    );
  } catch (error) {
    unnlockNotification(`Something went wrong`, "error");
    yield put(ACTIONS.addCouponFailure(error));
  }
}

export function* requestUsersList() {
  yield takeLatest(TYPES.GET_USERS_LIST_REQUEST, handleUsersList);
}
export function* requestCouponsList() {
  yield takeLatest(TYPES.COUPONS_LIST_REQUEST, handleCouponsList);
}
export function* requestAddCoupons() {
  yield takeLatest(TYPES.ADD_COUPON_REQUEST, handleAddCoupons);
}
