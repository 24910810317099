import React from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./subscripCard.scss";
import CheckCircle from "../../assets/images/CheckCircle.svg";
import * as ProfileActions from "../../store/profile/profileActions";

/* eslint-disable */
const SubscriptionCard = ({ currentSub, subsData, themetype }) => {
  const dispatch = useDispatch();
  const customerId = useSelector((state) => state.profile.customerId);
  const subscriptions = useSelector((state) => state.profile.subscriptions);

  const handleSubscribe = () => {
    message.loading({
      content: "Please Wait...",
      key: "createSubscriptionLoader",
    });
    {
      const formData = {
        customerId: customerId,
        priceId: subsData.price_id,
      };
      console.log("SubsData---", subsData);
      dispatch(ProfileActions.updateSelectedPlan(subsData.price));
      dispatch(
        ProfileActions.createSubscriptionRequest({
          formData,
        })
      );
    }
  };

  let btnStyle, headerStyle, title, subtitle;

  if (themetype == subsData.tier_id) {
    btnStyle = "buynowSelected";
    headerStyle = "subheadSelected";
  } else {
    btnStyle = "buynow";
    headerStyle = "subhead";
  }

  if (subsData.description == "FREE_SUBSCRIPTION") {
    title = "FREE";
    subtitle = "";
  } else if (subsData.description == "MONTHLY_SUBSCRIPTION") {
    title = "MONTHLY";
    subtitle = "/ Month";
  } else if (subsData.description == "QUARTERLY_SUBSCRIPTION") {
    title = "QUARTERLY";
    subtitle = "/ 3 Months";
  } else if (subsData.description == "YEARLY_SUBSCRIPTION") {
    title = "YEARLY";
    subtitle = "/ Year";
  } else if (subsData.description == "HALF_YEARLY_SUBSCRIPTION") {
    title = "HALF YEARLY";
    subtitle = "/ 6 Months";
  } else {
    title = "FREE";
    subtitle = "/ Unlimited";
  }

  const getColor = () => {
    if (title === "FREE") return "rgba(0, 153, 0, 0.1)";
    if (title === "QUARTERLY") return "rgba(226, 81, 81, 0.1)";
    if (title === "MONTHLY") return "rgba(230, 169, 61, 0.2)";
    if (title === "HALF YEARLY") return "rgba(230, 153, 71, 0.1)";
    return "#c5e308";
  };

  return (
    <div className={currentSub ? "selected_card_container" : "card_container"}>
      <div className={headerStyle}>
        <p className="periodbtn" style={{ background: getColor() }}>
          {title}
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="price">{`$  ${subsData.price}`}</h2>
          <p className="period">{subtitle}</p>
        </div>
        <p className="unlockprem" style={{ marginBottom: 0 }}>{subsData.discount ? subsData.discount : <span style={{ lineHeight: "21px", visibility: "hidden" }}>.</span>}</p>
      </div>
      <div style={{ padding: "0px 20px" }}>
        <div className="divider" />
      </div>
      <div className="descbDiv">
        {/* <h4>Take your career to the next level with</h4> */}
        {subsData.features.map((obj) => (
          <div className="descRow">
            <img src={CheckCircle} />
            <p>{obj}</p>
          </div>
        ))}
      </div>
      <div className="buynowdiv">
        {currentSub ? (
          <p className={btnStyle}>Current Subscription Plan</p>
        ) : (
          <>
            {subscriptions?.length ? (
              <p className={btnStyle} onClick={handleSubscribe}>
                {title !== "FREE" ? "Get Now" : "FREE"}
              </p>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  subsData: PropTypes.object.isRequired,
  themetype: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SubscriptionCard;
