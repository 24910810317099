/* eslint-disable */
import { combineReducers } from "redux";
import profile from "./profile/profileReducer";
import tools from "./Tools/toolsReducer";
import componies from "./componies/reducer";
import login from "./newLogin/newReducer";
import register from "./newRegister/registerReduceres";
import dashboard from "./dashBoard/dashboardReduceres";
import jobmatch from "./jobMatch/jobMatchReducers";
import resumeOptimizer from "./resumeOptimizer/resumeOptimizerReducer";
import persanalityTest from "./persanalityTest/persanalityTestReducer";
import adminDashboard from "./adminDashboard/reducer";

export const AppReducers = combineReducers({
  login,
  register,
  dashboard,
  profile,
  tools,
  componies,
  jobmatch,
  resumeOptimizer,
  persanalityTest,
  adminDashboard,
});
