import React from "react";
import UnnlockLayout from "../layout";
import { Space, Spin, Table, Tabs } from "antd";
import CommonInput from "../ICDProvider/commonInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUsersList } from "../../store/adminDashboard/actions";
import CouponsComponent from "./couponsComponent";

const { Column } = Table;

const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  const { loader, users } = useSelector((state) => state.adminDashboard);
  const [searchValue, setSearchValue] = useState("");

  const onChange = (key) => {
    console.log(key);
  };

  const applyFilter = (data) => {
    if (searchValue.trim()) {
      return data.filter(
        (item) =>
          item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.first_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return data;
    }
  };

  const usersComp = (
    <Spin size="large" spinning={loader}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            background: "white",
          }}
        >
          <h2>Users</h2>
          <div style={{ width: "30%" }}>
            <CommonInput
              placeholder="Search by email or name..."
              name="email"
              type="text"
              value={searchValue}
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <Table dataSource={applyFilter(users)}>
            <Column
              title="First Name"
              dataIndex="first_name"
              key="first_name"
            />
            <Column title="Last Name" dataIndex="last_name" key="last_name" />
            <Column title="Email" dataIndex="email" key="email" />
            <Column
              title="Tier ID(Subscription ID)"
              dataIndex="tier_id"
              key="tier_id"
            />
            <Column
              title="Action"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <a>Edit</a>
                  <a>Delete</a>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </Spin>
  );

  const items = [
    {
      key: "1",
      label: "Users",
      children: usersComp,
    },
    {
      key: "2",
      label: "Coupons",
      children: <CouponsComponent />,
    },
  ];

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  return (
    <UnnlockLayout>
      <div style={{ padding: "5%" }}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </UnnlockLayout>
  );
};

export default AdminDashboard;
