export const JOBMATCHRESULTS_REQUEST = "JOBMATCHRESULTS_REQUEST";
export const JOBMATCHRESULTS_SUCCESS = "JOBMATCHRESULTS_SUCCESS";
export const JOBMATCHRESULTS_FAILURE = "JOBMATCHRESULTS_FAILURE";

export const SAVE_JOBMATCHRESULTS_REQUEST = "SAVE_JOBMATCHRESULTS_REQUEST";
export const SAVE_JOBMATCHRESULTS_SUCCESS = "SAVE_JOBMATCHRESULTS_SUCCESS";
export const SAVE_JOBMATCHRESULTS_FAILURE = "SAVE_JOBMATCHRESULTS_FAILURE";

export const UPDATE_JOBMATCHRESULTS_REQUEST = "UPDATE_JOBMATCHRESULTS_REQUEST";
export const UPDATE_JOBMATCHRESULTS_SUCCESS = "UPDATE_JOBMATCHRESULTS_SUCCESS";
export const UPDATE_JOBMATCHRESULTS_FAILURE = "UPDATE_JOBMATCHRESULTS_FAILURE";

export const GET_JOBTACKERS_REQUEST = "GET_JOBTACKERS_REQUEST";
export const GET_JOBTACKERS_SUCCESS = "GET_JOBTACKERS_SUCCESS";
export const GET_JOBTACKERS_FAILURE = "GET_JOBTACKERS_FAILURE";

export const UPDATE_JOBTACKERS_REQUEST = "UPDATE_JOBTACKERS_REQUEST";
export const UPDATE_JOBTACKERS_SUCCESS = "UPDATE_JOBTACKERS_SUCCESS";
export const UPDATE_JOBTACKERS_FAILURE = "UPDATE_JOBTACKERS_FAILURE";

export const JOBMATCHFETCH_REQUEST = "JOBMATCHFETCH_REQUEST";
export const JOBMATCHFETCH_SUCCESS = "JOBMATCHFETCH_SUCCESS";
export const JOBMATCHFETCH_FAILURE = "JOBMATCHFETCH_FAILURE";

export const DELETE_JOBTACKERS_REQUEST = "DELETE_JOBTACKERS_REQUEST";
export const DELETE_JOBTACKERS_SUCCESS = "DELETE_JOBTACKERS_SUCCESS";
export const DELETE_JOBTACKERS_FAILURE = "DELETE_JOBTACKERS_FAILURE";

export const SET_TRACKER_ID = "SET_TRACKER_ID";
export const SET_TRACKER_ID_TO_UPDATE = "SET_TRACKER_ID_TO_UPDATE";
export const SHOW_UPDATE_SKILLS_MODAL = "SHOW_UPDATE_SKILLS_MODAL";

export const RESET = "jobMatchResults/RESET";
