import React from "react";
import { Popover } from "antd";
import "./evaluateJob.scss";

const EvaluateTable = () => {
  const rows = [
    {
      name: "Industry",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Company Type",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Size (Revenue)",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Profitable",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Growth",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Location",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Job Title",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Compensation",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Salary",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Bonus",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Equity",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "Oppurtunity for Advancement",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
    {
      name: "General Notes",
      samsung: "Software Services",
      apple: "Software Services",
      considerations: "Lorem ipsum dolor sit amet, consectetu..",
    },
  ];

  const content = (
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
      </p>
    </div>
  );

  return (
    <div className="evalTable_container">
      <table className="table">
        <tr className="table_header_row">
          <td className="col_header"> </td>
          <th scope="col" className="col_header">
            <div className="col_header_txt">Samsung</div>
          </th>
          <th scope="col" className="col_header">
            <div className="col_header_txt">Apple</div>
          </th>
          <th scope="col" className="col_header">
            <div className="col_header_txt">Considerations</div>
          </th>
        </tr>
        {rows.map((row) => (
          <tr key={row} className="table_header_col">
            <th scope="row" className="row_header">
              <div className="row_header_txt">{row.name}</div>
            </th>
            <td className="row_data">
              <div className="row_txt">{row.samsung}</div>
            </td>
            <td className="row_data">
              <div className="row_txt">{row.apple}</div>
            </td>
            <td className="row_data">
              <Popover
                placement="bottomLeft"
                content={content}
                overlayStyle={{ width: "400px", borderRadius: "5px" }}
                overlayInnerStyle={{ borderRadius: "10px", padding: "15px" }}
              >
                <div className="row_txt">{row.considerations}</div>
              </Popover>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
export default EvaluateTable;
