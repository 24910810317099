import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Table, Row, Col } from "antd";
import history from "../../routes/History";
import NewHeader from "../../components/newHeader";
import UnnlockLayout from "../layout";

import CompanyCard from "../Companies/componyCard";

import "./OfferEvaluatorResult.scss";
import Pencil from "../../assets/images/offer/Pencil.svg";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import { any } from "prop-types";
import { isEmpty } from "lodash";
import RatingBar from "./RatingBar";

const { Content } = Layout;

const columns = [
  {
    title: "Confirm/Input",
    dataIndex: "confirm",
    key: "confirm",
  },
  {
    title: "",
    dataIndex: "currentcompany",
    key: "currentcompany",
  },
  {
    title: "",
    dataIndex: "newcompany",
    key: "newcompany",
  },
  {
    title: "Results",
    dataIndex: "results",
    key: "results",
  },
];

const data = [
  {
    key: "1",
    confirm: "Job Title",
  },
  {
    key: "4",
    confirm: "Industry",
  },
  {
    key: "2",
    confirm: "Size/Employees",
  },
  {
    key: "10",
    confirm: "Comnpany Type",
  },
  {
    key: "7",
    confirm: "Base",
  },
  {
    key: "8",
    confirm: "Bonus",
  },
  {
    key: "3",
    confirm: "Location",
  },
];

const offerOb = {
  curr_base: "",
  curr_bonus: "",
  curr_grantval: "",
  unvested: "",
  val_unvested: "",
  relocate: "",

  comp_name: "",
  industry: "",
  job_title: "",
  comp_size: "",
  location: "",

  new_base: "",
  new_bonus: "",
  new_equity: "",
};

const OfferEvaluatorResult = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columnsHead, setColumnsHead] = useState(columns);
  const [columnsData, setCoumnsData] = useState(data);

  const [currObject, setCurrObject] = useState({});
  const [offerObject, setOfferObject] = useState(offerOb);
  const [companydetails, setCompanydetails] = useState({});

  const location = useLocation();

  useEffect(() => {
    const currCompOBj = location.state.currentCompData;
    const enteredData = location.state.offerObject;
    const compDetails = location.state.companyDetails;
    const matchObj = location.state.MatchIndustryObj;

    console.log("compDetails", compDetails);
    console.log("currCompOBj", currCompOBj.currCompDetails[0])

    let currentCompanySize = "";
    let currentComapnyIndustry = "";
    let currentCompanyType = "";

    if (currCompOBj.currCompDetails[0]) {
      currentCompanySize = currCompOBj.currCompDetails[0].company_size;
      currentComapnyIndustry = currCompOBj.currCompDetails[0].industry;
      currentCompanyType = currCompOBj.currCompDetails[0].type;
    }

    let indus = "";
    let size = "";
    let compLocation = "";
    let type = "";

    if (compDetails) {
      indus = compDetails && compDetails.industry ? compDetails.industry : "";
      size =
        compDetails && compDetails.company_size ? compDetails.company_size : "";
      compLocation =
        compDetails && compDetails.hq_city ? compDetails.hq_city : "";
      type = compDetails && compDetails.type ? compDetails.type : "";
      setCompanydetails(compDetails);
    } else {
      indus = enteredData && enteredData.industry ? enteredData.industry : "";
      size = enteredData && enteredData.comp_size ? enteredData.comp_size : "";
      compLocation =
        enteredData && enteredData.location ? enteredData.location : "";
    }

    setCurrObject(currCompOBj);
    setOfferObject(enteredData);

    console.log("currCompOBj", currCompOBj)
    console.log("enteredData", enteredData)
    console.log("compDetails", compDetails)

    const currCompHeadTitle = (item) => {

      const imgurl = item.logo_url
        ? item.logo_url
        : "/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg"

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: "36px", height: "36px", marginRight: "16px" }} src={imgurl} alt="" />
          <p style={{ margin: "0" }}>{item.company}</p>
        </div>
      )

    }

    const newCompHeadTitle = (item, type) => {

      const imgurl = item.logo_url
        ? item.logo_url
        : "/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg"

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: "36px", height: "36px", marginRight: "16px" }} src={type == 'ent' ? '/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg' : imgurl} alt="" />
          <p style={{ margin: "0" }}>{type == "ent" ? item.comp_name : item.company}</p>
        </div>
      )
    }



    const baseRes =
      ((parseInt(enteredData.new_base, 10) -
        parseInt(enteredData.curr_base, 10)) /
        parseInt(enteredData.curr_base, 10)) *
      100;
    const bonusRes =
      ((parseInt(enteredData.new_bonus, 10) -
        parseInt(enteredData.curr_bonus, 10)) /
        parseInt(enteredData.curr_bonus, 10)) *
      100;
    const columnsNew = [
      {
        title: "Confirm/Input",
        dataIndex: "confirm",
        key: "confirm",
      },
      {
        title: () => currCompHeadTitle(currCompOBj.currCompDetails[0]),
        dataIndex: "currentcompany",
        key: "currentcompany",
      },
      {
        title: !isEmpty(compDetails) ? newCompHeadTitle(compDetails, 'comp') : newCompHeadTitle(enteredData, 'ent'),
        dataIndex: "newcompany",
        key: "newcompany",
      },
      {
        title: "Results",
        dataIndex: "results",
        key: "results",
        render: (rate) => <RatingBar ratings={rate} />,
      },
    ];

    const dataNew = [
      {
        key: "1",
        confirm: "Job Title",
        currentcompany: currCompOBj.currDesig,
        newcompany: enteredData.job_title,
      },
      {
        key: "4",
        confirm: "Industry",
        currentcompany: currentComapnyIndustry,
        newcompany: indus,
        results: matchObj?.bars,
      },
      {
        key: "2",
        confirm: "Size/Employees",
        currentcompany: currentCompanySize,
        newcompany: size,
      },
      {
        key: "10",
        confirm: "Comnpany Type",
        currentcompany: currentCompanyType,
        newcompany: type,
      },
      {
        key: "7",
        confirm: "Base",
        currentcompany: enteredData.curr_base,
        newcompany: enteredData.new_base,
        results: `${Math.abs(baseRes).toFixed(2)}%`,
      },
      {
        key: "8",
        confirm: "Bonus",
        currentcompany: enteredData.curr_bonus,
        newcompany: enteredData.new_bonus,
        results: `${Math.abs(bonusRes).toFixed(2)}%`,
      },
      {
        key: "3",
        confirm: "Location",
        newcompany: compLocation,
      },
    ];
    setColumnsHead(columnsNew);
    setCoumnsData(dataNew);
  }, [location]);

  const handleClick = () => {
    // console.log("Click Details");
    setIsModalVisible(!isModalVisible);
  };

  return (
    <UnnlockLayout>
      <div className="offerEvaluatorResultContainer">
        <Content style={{ padding: "32px", marginBottom: "42px" }}>
          {/* <div className="subheader">
          <span style={{ display: "flex" }}>
            <b>{currObject.currDesig}</b>
            <p style={{ margin: "0px 4px" }}>at</p>
            <b>{currObject.currentCompName}</b>
            <p style={{ margin: "0px 4px" }}> with Compensation of $</p>
            <b> {`$ ${offerObject.base} p.a`}</b>
          </span>
          <img
            src={Pencil}
            alt="Edit"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: "/OfferEvaluator",
                state: {
                  enteredFormData: offerObject,
                  sentCompanyDetails: currObject.currCompDetails,
                },
              })
            }
          />
        </div> */}
          <div style={{ marginTop: "20px", marginBottom: "60px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "18px",
                color: "#261C4B",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() =>
                    history.push({
                      pathname: "/OfferEvaluator",
                      state: {
                        enteredFormData: offerObject,
                        sentCompanyDetails: currObject.currCompDetails,
                      },
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ArrowLeft}
                    alt="arrow"
                    style={{
                      marginBottom: "4px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span
                    style={{ fontWeight: 600, textDecoration: "underline" }}
                  >
                    Offer Evaluation
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="headertxt">Current Compensation</p>
              {currObject.currCompDetails && (
                <CompanyCard
                  item={currObject.currCompDetails[0]}
                  getFavClick={() => { }}
                  handleCompanyClicked={() => { }}
                  faveratesList={[]}
                  fromDashboard
                />
              )}
            </div>

            <div>
              <p className="headertxt">New Compensation</p>

              <CompanyCard
                item={
                  !isEmpty(companydetails)
                    ? companydetails
                    : {
                      company: offerObject.comp_name,
                      company_size: offerObject.comp_size,
                      logo_url: null,
                      industry: offerObject.industry,
                      type: "",
                      unnlock_id: 0,
                    }
                }
                getFavClick={() => { }}
                handleCompanyClicked={() => { }}
                faveratesList={[]}
                fromDashboard
              />
            </div>
          </div> */}
          <Table
            columns={columnsHead}
            bordered
            dataSource={columnsData}
            pagination={false}
          />
          <Row style={{ padding: "24px" }}>
            <Col md={24}></Col>
          </Row>
        </Content>
      </div>
    </UnnlockLayout>
  );
};

export default OfferEvaluatorResult;
