/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./plans.scss";
import SubscriptionCard from "../Profile/subscriptionCard";


const PlansModel = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClickCard = (item) => {
    setSelectedCard(item);
  }

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  const subscriptions = [
    {
      title: "FREE",
      subtitle: "/ Unlimited",
      subscription_uuid: "a0c4b9f6-004d-473c-ac4c-59fd58a3e4ef",
      tier_id: 0,
      price: 0,
      price_id: null,
      description: "FREE_SUBSCRIPTION",
      features: [
        "Free subscription until you purchase premium",
        "First month free policy",
      ],
    },
    {
      title: "MONTHLY",
      subtitle: "/ Month",
      subscription_uuid: "869ef295-6e77-46c2-a66b-b33daed10f4e",
      tier_id: 1,
      price: 39,
      price_id: "price_1J5naoEgMeWk3wKnj5BoXe8R",
      description: "MONTHLY_SUBSCRIPTION",
      features: ["All benefits from free", "premium benifits"],
    },
    {
      title: "QUARTERLY",
      subtitle: "/ 3 Months",
      subscription_uuid: "d85c597b-c1be-4571-8564-9b3ff489c82e",
      tier_id: 2,
      price: 79,
      price_id: "price_1J5nchEgMeWk3wKnw5o3CtC8",
      description: "QUARTERLY_SUBSCRIPTION",
      features: ["All benefits from free", "premium benifits"],
    },
    {
      title: "YEARLY",
      subtitle: "/ Year",
      subscription_uuid: "077689d0-d4c5-4502-a7b8-edbdda4c63c3",
      tier_id: 3,
      price: 199,
      price_id: "price_1J5ne1EgMeWk3wKnhGtfK94H",
      description: "YEARLY_SUBSCRIPTION",
      features: ["All benefits from free", "premium benifits"],
    },
  ];

  return (
    <div className="plansContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 30px",
          background: "#4389FA",
        }}
      >
        <div style={{ color: "white", fontSize: "18px", fontWeight: 700 }}>
          unnlock
        </div>
      </div>
      <div className="subheader">
        <h1 className="subsTitle">Choose Your Plan</h1>
        <p className="subsDesc">Dreamers and visionaries from over 100 countries use miined. It's time for you to join them. Get a sneak peek at your future!</p>
      </div>
      <div className="cardsDiv">
        {subscriptions.map((item, i) => (
          <>
            <SubscriptionCard
              subsData={item}
              themetype={selectedCard}
              closeModal={handleCancel}
              clickCard={handleClickCard}
            />
          </>
        ))}
      </div>
    </div >
  );
};

PlansModel.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default PlansModel;
