import { fork, all } from "redux-saga/effects";
import * as RegisterSaga from "./newRegister/registerSaga";
import * as NewLoginSaga from "./newLogin/newLoginSaga";
import * as ProfileSaga from "./profile/profileSaga";
import * as ToolsSaga from "./Tools/toolsSaga";
import * as CompanySaga from "./componies/saga";
import * as DashboardSaga from "./dashBoard/dashboardSaga";
import * as JobMatchResultsSaga from "./jobMatch/jobMatchSaga";
import * as ResumeOptimizerSaga from "./resumeOptimizer/resumeOptimizerSaga";
import * as PersanalityTestSaga from "./persanalityTest/persanalityTestSaga";
import * as AdminDashboardSaga from "./adminDashboard/saga";

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(NewLoginSaga),
      ...Object.values(RegisterSaga),
      ...Object.values(DashboardSaga),
      ...Object.values(ProfileSaga),
      ...Object.values(ToolsSaga),
      ...Object.values(CompanySaga),
      ...Object.values(JobMatchResultsSaga),
      ...Object.values(ResumeOptimizerSaga),
      ...Object.values(PersanalityTestSaga),
      ...Object.values(AdminDashboardSaga),
    ].map(fork)
  );
}
