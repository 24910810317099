import React from "react";
import { Divider } from "antd";
// import { useEffect } from "react";
// import { useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import iconChecked from "../../assets/images/dashboard/checked.svg";
import iconUnChecked from "../../assets/images/dashboard/unchecked.svg";

// import CommonInput from "./commonInput";
import "./index.scss";
import CompanyCard from "../Companies/componyCard";

const CompanyDescCard = ({
  fromExplore,
  onConfirm,
  companyNotFound,
  companyNotFound2,
  onConfirm2,
  currentScreen,
}) => {
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );
  const companyLoader = useSelector(
    (state) => state.componies.dashboardCompnayDetailsLoader
  );
  // const [details, setDetails] = useState([
  //   { label: "Employees Size", value: "" },
  //   { label: "Industry", value: "" },
  //   { label: "Company Website", value: "" },
  //   { label: "Company Type", value: "" },
  //   { label: "Location", value: "" },
  // ]);

  // useEffect(() => {
  //   if (companyDetails && companyDetails?.length)
  //     setDetails([
  //       { label: "Employees Size", value: companyDetails[0]?.company_size },
  //       { label: "Industry", value: companyDetails[0]?.industry },
  //       { label: "Company Website", value: companyDetails[0]?.company_web_url },
  //       { label: "Company Type", value: companyDetails[0]?.type },
  //       { label: "Location", value: companyDetails[0]?.hq_full_address },
  //     ]);
  // }, [companyDetails]);

  return (
    <div id="CompanyDescCard">
      <div
        className="card_header"
        style={{ marginTop: fromExplore ? "0px" : "30px" }}
      >
        <div className="left">
          {fromExplore ? // <div className="companyName">Company Description</div>
          null : (
            <div className="companyName">
              {currentScreen == 1
                ? `Confirm your company or select "Company not found"`
                : "Confirm your company"}
            </div>
          )}
        </div>
      </div>
      <div className="CompanyDescCard">
        <Skeleton loading={companyLoader} active style={{ height: "215px" }}>
          {/* <div className="devider" style={{ marginBottom: 0 }} /> */}
          <div className="descriptions">
            <div className="descriptions-inner" style={{ width: "100%" }}>
              <CompanyCard
                item={companyDetails[0]}
                getFavClick={() => {}}
                handleCompanyClicked={() => {}}
                faveratesList={[]}
                fromDashboard
              />
              {fromExplore ? null : (
                <>
                  <Divider type="vertical" className="devider" />
                  {currentScreen == 1 ? (
                    <div className="confirmmations">
                      <div
                        className={companyNotFound == 1 ? "checked" : "confirm"}
                        onClick={onConfirm}
                        style={{ boxShadow: "none" }}
                      >
                        <img
                          src={
                            companyNotFound == 1 ? iconChecked : iconUnChecked
                          }
                        />
                        Confirm Company
                      </div>
                    </div>
                  ) : (
                    <div className="confirmmations">
                      <div
                        className={
                          companyNotFound2 == 1 ? "checked" : "confirm"
                        }
                        onClick={onConfirm2}
                        style={{ boxShadow: "none" }}
                      >
                        <img
                          src={
                            companyNotFound2 == 1 ? iconChecked : iconUnChecked
                          }
                        />
                        Confirm Company
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default CompanyDescCard;
