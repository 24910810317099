import React from "react";
import UnnlockLayout from "../layout";
import ExploreComp from "./exploreComp";
import "./index.scss";

const Explore = () => {
  return (
    <UnnlockLayout>
      <div className="explore_conatiner">
        <ExploreComp />
      </div>
    </UnnlockLayout>
  );
};

export default Explore;
