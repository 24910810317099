import React from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import history from "../../routes/History";
import UnnlockLayout from "../layout";
import titleImage from "../../assets/images/UnnlockNew.svg";
import UploadResume from "./uploadResume";
import "./style.scss";
import { savePreUploadData } from "../../store/newRegister/registerActions";

const PreUploadResume = () => {
  const { loader } = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const parseResume = (data, filename) => {
    setTimeout(() => {
      history.push("/registration");
      const postData = {
        resData: data,
        userId: "",
        file_name: filename,
      };
      dispatch(savePreUploadData(postData));
    }, 11000);
  };
  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      background="white"
      optionalHeader={
        <>
          <div className="loginPage_header">
            <div className="loginPage_header_inner">
              <img src={titleImage} alt="titleImage" className="unnlockIcon" />
            </div>
          </div>
        </>
      }
    >
      <Spin
        size="large"
        spinning={loader}
        style={{
          fontSize: "24px",
          fontFamily: "sans-serif",
          fontWeight: "bolder",
          color: "purple",
          height: "100%",
        }}
      >
        <UploadResume parseResume={parseResume} />
      </Spin>
    </UnnlockLayout>
  );
};

export default PreUploadResume;
