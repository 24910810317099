import React from "react";
import UnnlockLayout from "../layout";
import ExploreComp from "./exploreComp";
import "../Explore/index.scss";

const OpenJobs = () => {
  return (
    <UnnlockLayout>
      <div className="explore_conatiner">
        <ExploreComp />
      </div>
    </UnnlockLayout>
  );
};

export default OpenJobs;
