export const SET_TOOL_LOADER = "SET_TOOL_LOADER";
export const SET_FORM_DATA = "SET_FORM_DATA";

export const POST_MATCHJOB_REQUEST = "POST_MATCHJOB_REQUEST";
export const POST_MATCHJOB_SUCCESS = "POST_MATCHJOB_SUCCESS";
export const POST_MATCHJOB_FAILURE = "POST_MATCHJOB_FAILURE";

export const GET_SAL_COMP_REQUEST = "GET_SAL_COMP_REQUEST";
export const GET_SAL_COMP_SUCCESS = "GET_SAL_COMP_SUCCESS";
export const GET_SAL_COMP_FAILURE = "GET_SAL_COMP_FAILURE";

export const GET_SKILL_DESIG_REQUEST = "GET_SKILL_DESIG_REQUEST";
export const GET_SKILL_DESIG_SUCCESS = "GET_SKILL_DESIG_SUCCESS";
export const GET_SKILL_DESIG_FAILURE = "GET_SKILL_DESIG_FAILURE";

export const POSTCOMP_URLS_REQUEST = "POSTCOMP_URLS_REQUEST";
export const POSTCOMP_URLS_SUCCESS = "POSTCOMP_URLS_SUCCESS";
export const POSTCOMP_URLS_FAILURE = "POSTCOMP_URLS_FAILURE";

export const GETMATCHING_SKILLS_REQUEST = "GETMATCHING_SKILLS_REQUEST";
export const GETMATCHING_SKILLS_SUCCESS = "GETMATCHING_SKILLS_SUCCESS";
export const GETMATCHING_SKILLS_FAILURE = "GETMATCHING_SKILLS_FAILURE";

export const GET_OPEN_JOBS_REQUEST = "GET_OPEN_JOBS_REQUEST";
export const GET_OPEN_JOBS_SUCCESS = "GET_OPEN_JOBS_SUCCESS";
export const GET_OPEN_JOBS_FAILURE = "GET_OPEN_JOBS_FAILURE";

export const GET_CHATBOT_ANSWER_REQUEST = "GET_CHATBOT_ANSWER_REQUEST";
export const GET_CHATBOT_ANSWER_SUCCESS = "GET_CHATBOT_ANSWER_SUCCESS";
export const GET_CHATBOT_ANSWER_FAILURE = "GET_CHATBOT_ANSWER_FAILURE";

export const GET_OPEN_JOB_CHATBOT_ANSWER_REQUEST =
  "GET_OPEN_JOB_CHATBOT_ANSWER_REQUEST";
export const GET_OPEN_JOB_CHATBOT_ANSWER_SUCCESS =
  "GET_OPEN_JOB_CHATBOT_ANSWER_SUCCESS";
export const GET_OPEN_JOB_CHATBOT_ANSWER_FAILURE =
  "GET_OPEN_JOB_CHATBOT_ANSWER_FAILURE";

export const GET_AI_SEARCHED_JOB_REQUEST = "GET_AI_SEARCHED_JOB_REQUEST";
export const GET_AI_SEARCHED_JOB_SUCCESS = "GET_AI_SEARCHED_JOB_SUCCESS";
export const GET_AI_SEARCHED_JOB_FAILURE = "GET_AI_SEARCHED_JOB_FAILURE";

export const GET_CHAT_BOT_QUESTIOS_REQUEST = "GET_CHAT_BOT_QUESTIOS_REQUEST";
export const GET_CHAT_BOT_QUESTIOS_SUCCESS = "GET_CHAT_BOT_QUESTIOS_SUCCESS";
export const GET_CHAT_BOT_QUESTIOS_FAILURE = "GET_CHAT_BOT_QUESTIOS_FAILURE";

export const GET_JOBMATCH_FIT_REQUEST = "GET_JOBMATCH_FIT_REQUEST";
export const GET_JOBMATCH_FIT_SUCCESS = "GET_JOBMATCH_FIT_SUCCESS";
export const GET_JOBMATCH_FIT_FAILURE = "GET_JOBMATCH_FIT_FAILURE";

export const GET_JOBMATCH_SUGGESTION_REQUEST =
  "GET_JOBMATCH_SUGGESTION_REQUEST";
export const GET_JOBMATCH_SUGGESTION_SUCCESS =
  "GET_JOBMATCH_SUGGESTION_SUCCESS";
export const GET_JOBMATCH_SUGGESTION_FAILURE =
  "GET_JOBMATCH_SUGGESTION_FAILURE";

export const SET_SELECTED_OPENJOBS_RESUME = "SET_SELECTED_OPENJOBS_RESUME";

export const SET_OPENJOB_FILTERS = "SET_OPENJOB_FILTERS";
export const SET_OPENJOB_CITIES = "SET_OPENJOB_CITIES";
export const SET_SHOW_COOKIES = "SET_SHOW_COOKIES";

export const GET_COVER_LETTER_REQUEST = "GET_COVER_LETTER_REQUEST";
export const GET_COVER_LETTER_SUCCESS = "GET_COVER_LETTER_SUCCESS";
export const GET_COVER_LETTER_FAILURE = "GET_COVER_LETTER_FAILURE";

export const RESET = "RESET";
