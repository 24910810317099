/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import "../Profile/model.scss";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import { useDispatch, useSelector } from "react-redux";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ForgotPassModal = ({ visible }) => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.login);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [email, setEmail] = useState("");

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    dispatch(
      LoginActions.forgotPassRequest({
        email: email,
      })
    );
    setTimeout(() => {
      setIsModalVisible(false);
      setEmail("");
    }, 2000);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      title="Basic Modal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="subscription_model"
      width={400}
    >
      <div className="model_body">
        {/* <div className="img_holder"></div> */}
        <div className="header">
          <div className="text_holder">
            <div className="title">Enter your email to receive a password reset link</div>
          </div>
        </div>
        <input
          type="email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{
            padding: "10px",
            border: "1px solid #6eaaff",
            borderRadius: "5px",
            width: "100%",
            marginTop: "20px",
          }}
        />
        {!validateEmail(email) && email && (
          <p style={{ color: "red" }}>Invalid Email</p>
        )}
        <div
          style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}
        >
          <button
            onClick={handleSubmit}
            disabled={!validateEmail(email)}
            style={{
              padding: "8px",
              background: "rgb(67, 137, 250)",
              borderRadius: "5px",
              display: "block",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            {loader ? "Loading..!" : "Send link"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ForgotPassModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default ForgotPassModal;
