import React from "react";

const CustomGraph = ({ subGraph, data }) => {
  const numYTicks = 5;

  const getBg = (item) => {
    switch (item) {
      case "Neuroticism":
        return { bg: "#EA9EC91A", content: "#EA9EC9" };
      case "Extraversion":
        return { bg: "#93D7CA1A", content: "#93D7CA" };
      case "Openness To Experience":
        return { bg: "#F6B0911A", content: "#B4B738" };
      case "Agreeableness":
        return { bg: "#A484E31A", content: "#A484E3" };
      case "Conscientiousness":
        return { bg: "#4389FA1A", content: "#26B3D2" };
      default:
        return { bg: "#EA9EC91A", content: "#EA9EC9" };
    }
  };

  let arr = subGraph ? [25, 20, 15, 10, 5, 0] : [100, 80, 60, 40, 20, 0];

  return (
    <div className="customgraph_container">
      <svg width={subGraph ? 950 : 900} height={400} className="svg">
        {arr.map((value, index) => {
          const y = 50 + index * (300 / numYTicks);

          return (
            <g key={index}>
              <line
                x1={50}
                y1={y}
                x2={subGraph ? 935 : 900}
                y2={y}
                stroke="grey"
              />
              <text x={50 - 5} y={y + 5} textAnchor="end">
                {value}
              </text>
            </g>
          );
        })}
      </svg>
      <div className="customGraphs">
        {data.map((item) => (
          <div className="customGraph_wrap">
            <div
              className="customGraph"
              style={{
                background: subGraph ? "transparent" : getBg(item.title).bg,
              }}
            >
              <div
                className="Graphcontent"
                style={{
                  height: subGraph ? `${item.score * 4}%` : `${item.score}%`,
                  padding: subGraph ? `15px` : `20px`,
                  borderRadius: subGraph ? `12px` : `15px`,
                  background: getBg(item.title).content,
                }}
              >
                {item.score}
              </div>
            </div>
            <p className="label">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomGraph;
