/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Progress, Radio, Space, Skeleton } from "antd";
// import { Column } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { getResultsRequest } from "../../store/persanalityTest/persanalityTestActions";
import CustomGraph from "./customGraph";
import HighIcon from "../../assets/images/HighGraph.svg";
import listIcon from "../../assets/images/listIcon.svg";
import "./index.scss";

const PersonalityTestRsults = () => {
  const { results, loader } = useSelector((state) => state.persanalityTest);
  const { userData } = useSelector((state) => state.login);

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && userData.quiz_id)
      dispatch(getResultsRequest({ id: userData.quiz_id }));
  }, [userData]);

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const activeContent = results?.data?.results[activeTab];

  return (
    <div className="personalityTest_Results">
      <Skeleton loading={loader} active>
        <div>
          <div className="results">
            {results?.data?.results && results?.data?.results.length && (
              <div className="result resultBig5">
                <div className="result_title_big5">
                  The Big Five - Your Results
                </div>
                <div className="resultBig5graph">
                  <CustomGraph data={results?.data?.results} />
                </div>
              </div>
            )}
            <div className="result">
              <div className="tabs">
                {results?.data?.results &&
                  results?.data?.results.length &&
                  results?.data?.results.map((item, i) => (
                    <div
                      className={activeTab == i ? "tab avtiveTab" : "tab"}
                      onClick={() => handleTabChange(i)}
                    >
                      {item.title}
                    </div>
                  ))}
              </div>
              {activeContent?.facets && (
                <div className="tab_content">
                  <div className="tab_graph">
                    <div className="titleCard">
                      <div className="shortDescription">
                        {activeContent?.shortDescription}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="score">
                          Score <span>{activeContent?.score}</span>
                        </div>
                        <div className="high">
                          High <img src={HighIcon} alt="HighIcon" />
                        </div>{" "}
                      </div>
                    </div>
                    <CustomGraph
                      subGraph={true}
                      data={activeContent?.facets || []}
                    />
                  </div>
                  <div className="subDescription">
                    {activeContent?.facets.map((it, i) => (
                      <div className="sub_result">
                        <div className="titleCard">
                          <div className="sub_result_title">
                            <img
                              src={listIcon}
                              alt="listIcon"
                              style={{ marginRight: "10px" }}
                            />
                            {it.title}
                          </div>
                          <div className="sub_subDetails">
                            Score: {it.score} - {it.scoreText}
                          </div>
                        </div>
                        <p className="sub_shortDescription">{it.text}</p>
                        {activeContent?.facets.length === i + 1 ? null : (
                          <div className="devider" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* <div className="result">
                    <div className="result_title">{item.title}</div>
                    <p className="subDetails">
                      Score: {item.score} - {item.scoreText}
                    </p>
                    <p className="shortDescription">{item.shortDescription}</p>
                    <div className="graph">
                      <Column
                        {...config}
                        data={item.facets.map((it) => {
                          return { type: it.title, sales: it.score };
                        })}
                      />
                    </div>
                    <div className="sub_results">
                      {item.facets.map((it) => (
                        <div className="sub_result">
                          <div className="sub_result_title">{it.title}</div>
                          <p className="sub_subDetails">
                            Score: {it.score} - {it.scoreText}
                          </p>
                          <p className="sub_shortDescription">{it.text}</p>
                        </div>
                      ))}
                    </div>
                  </div> */}
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default PersonalityTestRsults;
