import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Progress } from "antd";
import { FileUploader } from "react-drag-drop-files";
import "./style.scss";

import documentupload from "../../assets/images/documentupload.svg";

const UploadResumeCard = (props) => {
  return (
    <>
      <FileUploader
        multiple={false}
        handleChange={props.handleClick}
        name="file"
        types={["PDF", "DOCX", "DOC", "TXT", "RTF"]}
        classes="customFileDandD"
      >
        <div className="uploaddiv">
          <div className="title_conatiner" style={{ display: "flex", flexDirection: "column" }}>
            <h2>Upload Your Resume</h2>
            <p>See instant analysis and create your unnlock profile</p>
          </div>
          <div className="greycircle">
            <img src={documentupload} alt="" />
          </div>
          <p className="dragtext" style={{ marginLeft: "0px" }}>Drag and drop your resume here</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <span className="smallLine"></span>
            <p style={{ marginBottom: "0px", color: `#6060608c` }}>OR</p>
            <span className="smallLine"></span>
          </div>
          <button type="submit" className="check_btn">
            <p>Browse & Upload</p>
          </button>
          <p className="filetext">File format PDF, DOCX, TXT, RTF</p>
          <p className="filetext">Maximum file size : 5MB</p>
          <p style={{ fontSize: "16px", marginTop: "12px", marginBottom: "0px" }}>unnlock will never sell your personal information to third parties</p>
        </div>
      </FileUploader>
    </>
  );
};

export default UploadResumeCard;
