/*eslint-disable*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Row, Col } from "antd";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import ResumeOptimizerInput from "../resumeOptimizerInput";
import { validation } from "./schema";
import "../index.scss";
import SaveButton from "../saveButton";
// import AutoSave from "../../ResumeVault/autoSave";

const ContactInfo = () => {
  const dispatch = useDispatch();
  const { contactInfo } = useSelector((state) => state.resumeOptimizer);

  const handleSubmit = (values) => {
    console.log(values);  
    const formData = {
      contact_info: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
      },
      address: {
        city: values.city,
        state: values.state,
        country: values.country,
        postal_code: values.postalCode,
        address_line: [values.addressLine1, values.addressLine2],
      },
      online_profiles: [
        values.onlineProfile1,
        values.onlineProfile2,
        values.onlineProfile3,
      ],
    };
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "contact-update",
      })
    );
  };

  return (
    <div>
      <SaveButton
        handleClick={() => document.getElementById("submitContactBtn").click()}
      />
      <Formik
        initialValues={contactInfo}
        validationSchema={validation}
        onSubmit={(values) => {
          setTimeout(() => {
            handleSubmit(values);
          }, 400);
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <div>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="First name"
                    name="firstName"
                    type="text"
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Last name"
                    name="lastName"
                    type="text"
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Email"
                    name="email"
                    type="text"
                    error={errors.email}
                    touched={touched.email}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Phone number"
                    name="phoneNumber"
                    type="text"
                    error={errors.phoneNumber}
                    touched={touched.phoneNumber}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="City"
                    name="city"
                    type="text"
                    error={errors.city}
                    touched={touched.city}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="State"
                    name="state"
                    type="text"
                    error={errors.state}
                    touched={touched.state}
                  />
                </Col>
                {/* <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Country"
                    name="country"
                    type="text"
                    error={errors.country}
                    touched={touched.country}
                  />
                </Col> */}
              </Row>
              <Row gutter={[24, 24]}>
                {/* <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="AddressLine1"
                    name="addressLine1"
                    type="text"
                    error={errors.addressLine1}
                    touched={touched.addressLine1}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="AddressLine2"
                    name="addressLine2"
                    type="text"
                    error={errors.addressLine2}
                    touched={touched.addressLine2}
                  />
                </Col> */}
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="ZIP code"
                    name="postalCode"
                    type="text"
                    error={errors.postalCode}
                    touched={touched.postalCode}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="LinkedIn Profile URL"
                    name="onlineProfile1"
                    type="text"
                    error={errors.onlineProfile1}
                    touched={touched.onlineProfile1}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Custom URL 1"
                    name="onlineProfile2"
                    type="text"
                    error={errors.OnlineProfile2}
                    touched={touched.OnlineProfile2}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <ResumeOptimizerInput
                    label="Custom URL 2"
                    name="onlineProfile3"
                    type="text"
                    error={errors.OnlineProfile3}
                    touched={touched.OnlineProfile3}
                  />
                </Col>
              </Row>
              <button
                type="submit"
                id="submitContactBtn"
                style={{ visibility: "hidden" }}
              >
                Submit
              </button>
              {/* <AutoSave debounceMs={1000} /> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactInfo;
