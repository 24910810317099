import * as types from "./newLoginTypes";

// Login Actions
export const loginRequest = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = (payload) => ({
  type: types.LOGIN_REQUEST_SUCCESS,
  payload,
});
export const loginFailure = (payload) => ({
  type: types.LOGIN_REQUEST_FAILURE,
  payload,
});

// Logout Actions
export const logoutRequest = (payload) => ({
  type: types.LOGOUT_REQUEST,
  payload,
});
export const logoutSuccess = (payload) => ({
  type: types.LOGOUT_REQUEST_SUCCESS,
  payload,
});
export const logoutFailure = (payload) => ({
  type: types.LOGOUT_REQUEST_FAILURE,
  payload,
});

// Getuser Actions
export const getUserDataRequest = (payload) => ({
  type: types.GET_USER_DETAILS_REQUEST,
  payload,
});
export const getUserDataSuccess = (payload) => ({
  type: types.GET_USER_DETAILS_REQUEST_SUCCESS,
  payload,
});
export const getUserDataFailure = (payload) => ({
  type: types.GET_USER_DETAILS_REQUEST_FAILURE,
  payload,
});

// Email Verification Actions
export const emailVerificationRequest = (payload) => ({
  type: types.EMAIL_VERIFICATION_REQUEST,
  payload,
});
export const emailVerificationSuccess = (payload) => ({
  type: types.EMAIL_VERIFICATION_REQUEST_SUCCESS,
  payload,
});
export const emailVerificationFailure = (payload) => ({
  type: types.EMAIL_VERIFICATION_REQUEST_FAILURE,
  payload,
});

// Reset Password Actions
export const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload,
});
export const resetPasswordReceive = (payload) => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload,
});
export const resetPasswordFailure = (payload) => ({
  type: types.RESET_PASSWORD_FAILURE,
  payload,
});

// forgot Password Actions
export const forgotPassRequest = (payload) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
});
export const forgotPassReceive = (payload) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload,
});
export const forgotPassFailure = (payload) => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  payload,
});

// Refresh LogIn Token Request Actions
export const refreshLoginRequest = (payload) => ({
  type: types.REFRESH_LOGIN_REQUEST,
  payload,
});
export const refreshLoginSuccess = (payload) => ({
  type: types.REFRESH_LOGIN_SUCCESS,
  payload,
});
export const refreshLoginFailure = (payload) => ({
  type: types.REFRESH_LOGIN_FAILURE,
  payload,
});

export const getUserIndustriesRequest = (payload) => ({
  type: types.GET_USER_INDUSTRIES_REQUEST,
  payload,
});
export const getUserIndustriesSuccess = (payload) => ({
  type: types.GET_USER_INDUSTRIES_SUCCESS,
  payload,
});
export const getUserIndustriesFailure = (payload) => ({
  type: types.GET_USER_INDUSTRIES_FAILURE,
  payload,
});

export const getSubsCountRequest = (payload) => ({
  type: types.GET_SUBSCRIPTION_COUNT_REQUEST,
  payload,
});
export const getSubsCountSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTION_COUNT_SUCCESS,
  payload,
});
export const getSubsCountFailure = (payload) => ({
  type: types.GET_SUBSCRIPTION_COUNT_FAILURE,
  payload,
});

export const applyCouponRequest = (payload) => ({
  type: types.APPLY_COUPON_REQUEST,
  payload,
});
export const applyCouponSuccess = (payload) => ({
  type: types.APPLY_COUPON_SUCCESS,
  payload,
});
export const applyCouponFailure = (payload) => ({
  type: types.APPLY_COUPON_FAILURE,
  payload,
});

export const setSubscriptionLoader = (payload) => ({
  type: types.SET_SUBSCRIPTION_LOADER,
  payload,
});

// check tokan expiry
export const checkTokanExpiry = () => ({
  type: types.CHECK_TOKAN_EXPIRY,
});

// reset
export const reset = () => ({
  type: types.RESET,
});
