/*eslint-disable*/
import * as Yup from "yup";
require("yup-phone");

export const schema1 = [
  {
    field: "input",
    label: "First Name",
    id: "firstName",
    type: "text",
    isRquired: true,
  },
  {
    field: "input",
    label: "Last Name",
    id: "lastName",
    type: "text",
    isRquired: true,
  },
  {
    field: "input",
    label: "Email",
    id: "email",
    type: "email",
    isRquired: true,
  },
  {
    field: "input",
    label: "Phone Number",
    id: "phoneNumber",
    type: "number",
    isRquired: true,
  },
];

export const schema2 = [
  {
    field: "input",
    label: "Address Line 1",
    id: "addressLine1",
    type: "text",
    notRequired: true,
    isRquired: false,
  },
  {
    field: "input",
    label: "Address Line 2",
    id: "addressLine2",
    type: "text",
    isRquired: false,
  },
  {
    field: "input",
    label: "City",
    id: "city",
    type: "text",
    isRquired: true,
  },
  {
    field: "input",
    label: "State",
    id: "state",
    type: "text",
    isRquired: true,
  },
  {
    field: "input",
    label: "Country",
    id: "country",
    type: "text",
    isRquired: false,
  },
  {
    field: "input",
    label: "ZIP Code",
    id: "postalCode",
    type: "number",
    isRquired: true,
  },
];
export const schema3 = [
  {
    field: "input",
    label: "Linked In",
    id: "onlineProfile1",
    type: "text",
    showWarning: true,
    isRquired: false,
  },
  {
    field: "input",
    label: "Online Profile #2",
    id: "onlineProfile2",
    type: "text",
    isRquired: false,
  },
  {
    field: "input",
    label: "Online Profile #3",
    id: "onlineProfile3",
    type: "text",
    isRquired: false,
  },
];
export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  onlineProfile1: "",
  onlineProfile2: "",
  onlineProfile3: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string().phone().required("Required"),
  // addressLine1: Yup.string().required("addressLine1 is required"),
  // addressLine2: Yup.string().required("addressline2 is required"),
  // city: Yup.string().required("city is required"),
  // state: Yup.string().required("state is required"),
  // country: Yup.string().required("country is required"),
  postalCode: Yup.string().required("zipCode is required"),
  // onlineProfile1: Yup.string().required("Online Profile is required"),
  // onlineProfile2: Yup.string().required("Online Profile is required"),
  // onlineProfile3: Yup.string().required("Online Profile is required"),
});
