import * as types from "./dashboardTypes";
import {
  formatAchievementsData,
  formatAssociationData,
  formatCertificateData,
  formatContactData,
  formatEducationData,
  formatEmployementData,
  formatLanguageData,
  formatMiliteryData,
  formatPiechartData,
  formatReferenceData,
  formatSecurityData,
  formatSkillsData,
  formatTrainingData,
  formatDashboardSkills,
  formatExperienceSummary,
  formatSkillsForJobMAtch,
} from "../../utils/helper";

const INITIAL_STATE = {
  loader: false,
  resumeParseData: "",
  companyFilteres: "",
  skillsArrayObject: "",
  contactInfo: formatContactData(""),
  employementInfo: formatEmployementData(""),
  educationInfo: formatEducationData(""),
  certificateInfo: formatCertificateData(""),
  languageInfo: formatLanguageData(""),
  skillsInfo: formatSkillsData(""),
  achievementsInfo: formatAchievementsData(""),
  associationInfo: formatAssociationData(""),
  pieChartData: formatPiechartData(""),
  militeryInfo: formatMiliteryData(""),
  securityInfo: formatSecurityData(""),
  trainingInfo: formatTrainingData(""),
  referenceInfo: formatReferenceData(""),
  dashboardSkills: formatDashboardSkills(""),
  experienceSummary: formatExperienceSummary(""),
  skillsForJobMatch: formatSkillsForJobMAtch(""),
  htmlValue: "",
  skillsTaxanomy: [],
  skillsForAtsView: [],
  topCompanies: [],
  isFavClickLoading: false,
  selectedCompanyId: "",
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESUME_PARSER_REQUEST:
      return {
        ...state,
        loader: true,
        resumeParseData: "",
        contactInfo: formatContactData(""),
        employementInfo: formatEmployementData(""),
        educationInfo: formatEducationData(""),
        certificateInfo: formatCertificateData(""),
        languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(""),
        achievementsInfo: formatAchievementsData(""),
        associationInfo: formatAssociationData(""),
        pieChartData: formatPiechartData(""),
        militeryInfo: formatMiliteryData(""),
        securityInfo: formatSecurityData(""),
        trainingInfo: formatTrainingData(""),
        referenceInfo: formatReferenceData(""),
        dashboardSkills: formatDashboardSkills(""),
        experienceSummary: formatExperienceSummary(""),
        skillsForJobMatch: formatSkillsForJobMAtch(""),
        skillsTaxanomy: [],
        topCompanies: [],
        error: "",
      };
    case types.RESUME_PARSER_SUCCESS:
      return {
        ...state,
        loader: false,
        resumeParseData: action.payload,
        contactInfo: formatContactData(action.payload.ContactInfo),
        employementInfo: formatEmployementData(
          action.payload.EmploymentHistory
        ),
        educationInfo: formatEducationData(action.payload.EducationHistory),
        certificateInfo: formatCertificateData(
          action.payload.LicensesAndCertifications
        ),
        languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(action.payload.SkillsTaxonomy),
        achievementsInfo: formatAchievementsData(action.payload.Achievements),
        associationInfo: formatAssociationData(
          action.payload.Associations?.Association
        ),
        pieChartData: formatPiechartData(action.payload.SkillsTaxonomy),
        militeryInfo: formatMiliteryData(action.payload.MilitaryExperience),
        securityInfo: formatSecurityData(action.payload.SecurityCredentials),
        trainingInfo: formatTrainingData(action.payload.TrainingHistory),
        referenceInfo: formatReferenceData(action.payload.References),
        dashboardSkills: "",
        skillsForJobMatch: formatSkillsForJobMAtch(
          action.payload.skills_for_job_match
        ),
        skillsForAtsView: action.payload?.skills_for_job_match || [],
        experienceSummary: formatExperienceSummary(
          action.payload.ExperienceSummaryDetails
        ),
        htmlValue: "",
        skillsTaxanomy: action.payload.SkillsTaxonomy || [],
        topCompanies: action.payload.TopCompanies || [],
        error: "",
      };
    case types.RESUME_PARSER_FAILURE:
      return {
        ...state,
        loader: false,
        resumeParseData: "",
        contactInfo: formatContactData(""),
        employementInfo: formatEmployementData(""),
        educationInfo: formatEducationData(""),
        certificateInfo: formatCertificateData(""),
        languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(""),
        achievementsInfo: formatAchievementsData(""),
        associationInfo: formatAssociationData(""),
        pieChartData: formatPiechartData(""),
        militeryInfo: formatMiliteryData(""),
        securityInfo: formatSecurityData(""),
        trainingInfo: formatTrainingData(""),
        referenceInfo: formatReferenceData(""),
        dashboardSkills: formatDashboardSkills(""),
        experienceSummary: formatExperienceSummary(""),
        htmlValue: "",
        skillsTaxanomy: [],
        topCompanies: [],
        error: action.payload,
      };
    case types.EXTRACT_SKILLS_REQUEST:
      return {
        ...state,
        loader: false,
        skillsArrayObject: "",
      };
    case types.EXTRACT_SKILLS_SUCCESS:
      return {
        ...state,
        loader: false,
        skillsArrayObject: action.payload,
      };
    case types.EXTRACT_SKILLS_FAILURE:
      return {
        ...state,
        loader: false,
        skillsArrayObject: "",
      };

    case types.SAVE_COMPANY_DATILS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.SAVE_COMPANY_DATILS_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.SAVE_COMPANY_DATILS_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.GET_COMPANY_FILTERS_DATILS_REQUEST:
      return {
        ...state,
        loader: true,
        companyFilteres: "",
      };
    case types.GET_COMPANY_FILTERS_DATILS_SUCCESS:
      return {
        ...state,
        loader: false,
        companyFilteres: action.payload,
      };
    case types.GET_COMPANY_FILTERS_DATILS_FAILURE:
      return {
        ...state,
        loader: false,
        companyFilteres: "",
      };
    // case types.FAVOURITE_REQUEST:
    //   return {
    //     ...state,
    //     isFavClickLoading: true,
    //   };
    // case types.FAVOURITE_SUCCESS:
    //   return {
    //     ...state,
    //     isFavClickLoading: true,
    //   };
    // case types.FAVOURITE_FAILURE:
    //   return {
    //     ...state,
    //     isFavClickLoading: true,
    //   };
    // case types.SELECTED_COMPANY_ID:
    //   return {
    //     ...state,
    //     selectedCompanyId: action.payload,
    //   };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
