import { put, takeLatest, call } from "redux-saga/effects";
import { message } from "antd";
import * as ACTIONS from "./profileActions";
import * as TYPES from "./profileTypes";
import Network from "../../network";
import unnlockNotification from "../../components/notification";

const { API, Config } = Network;
/* eslint-disable */
function* putProfilePic(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.put,
      Config.ProfileDetails.putProfilePic,
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("profiledata:::", result);
    yield put(ACTIONS.profilePicUpdateSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.profilePicUpdateFailure(error));
    yield put(ACTIONS.setProfileLoader(false));
    // console.log("profiledataerr:::", error);
  }
}

function* getProfileDetails() {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const result = yield call(API.get, Config.registration.getUserInformation, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });

    // console.log("UserPersonal Detailssss:::", result);
    yield put(ACTIONS.getprofileSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.getprofileFailure(error));
  }
}

function* addProfilePic(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;


    const result = yield call(
      API.get,
      Config.ProfileDetails.getProfilePicUrl(
        formData.fileType,
        formData.fileName
      ),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    const extension = formData.fileName.split(".");
    const contentType =
      extension.length > 1 ? `image/${extension[1]}` : "image/json";
    const file = new Blob([formData.file]);
    yield call(API.put, Config.ProfileDetails.addProfilePic(result.url), file, {
      "Content-Type": contentType,
    });

    // console.log("UserPersonal Detailssss:::", result);
    yield put(ACTIONS.addprofileSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.addprofileFailure(error));
  }
}

function* addUploadProfPic(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.post,
      Config.ProfileDetails.uploadProfilePicUrl,
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    console.log("UserPersonal Detailssss:::", result);
    yield put(ACTIONS.uploadProfilePicSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.uploadProfilePicFailure(error));
  }
}

function* getSubscriptionDetails() {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const result = yield call(API.get, Config.ProfileDetails.getSubscription, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.getSubscriptionSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.getSubscriptionFailure(error));
  }
}

function* craeteCustomerId(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.post,
      Config.ProfileDetails.createCustomer,
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.createCustomerSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.createCustomerFailure(error));
  }
}

function* craeteSub(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.createSubscription(
        formData.customerId,
        formData.priceId
      ),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    message.success({
      content: "Please enter the card details",
      key: "createSubscriptionLoader",
      duration: 4,
    });
    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.createSubscriptionSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.setPaymentModal(true));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.createSubscriptionFailure(error));
    message.error({
      content: "No results found. Try another combination of skills",
      key: "createSubscriptionLoader",
      duration: 3,
    });
  }
}

function* cancelSubscription(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.cancelSubscription(formData.subsId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.cancelSubscriptionSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.getprofileRequest());
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.cancelSubscriptionFailure(error));
    message.error("You're subscription has already been canceled!");
  }
}

function* getCardsDetails(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { stripePaymentId } = params;
    // console.log(stripePaymentId);
    const result = yield call(
      API.get,
      Config.ProfileDetails.getCardDetails(stripePaymentId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.getCardsSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.getCardsFailure(error));
  }
}

function* changePass(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { formData } = params;

    const result = yield call(
      API.post,
      Config.ProfileDetails.changePassword,
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("profile Detailssss:::", result);
    yield put(ACTIONS.changePassSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.changePassFailure(error));
    unnlockNotification("Something went wrong!", "error");
  }
}

function* forgotPass(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { userName } = params;
    const result = yield call(
      API.get,
      Config.ProfileDetails.forgotPassword(userName)
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.forgotPassSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.forgotPassFailure(error));
  }
}

function* getPaymentMethods(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { custId } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.getPaymentMethods(custId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.getPaymentMethodSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.getPaymentMethodFailure(error));
  }
}

function* attachCard(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { custId, payMethod } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.attachPaymentMethod(custId, payMethod),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.attachPayMethodSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.attachPayMethodFailure(error));
  }
}

function* detachCard(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { payMethod } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.detachCard(payMethod),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.detachPayMethodSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.detachPayMethodFailure(error));
  }
}

function* updateSubPayment(action) {
  yield put(ACTIONS.setProfileLoader(true));
  try {
    const params = action.payload;
    const { subId, payId } = params;

    const result = yield call(
      API.get,
      Config.ProfileDetails.updateSubTonewPayment(subId, payId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.updateSubPaymentSuccess(result));
    yield put(ACTIONS.setProfileLoader(false));
  } catch (error) {
    yield put(ACTIONS.setProfileLoader(false));
    yield put(ACTIONS.updateSubPaymentFailure(error));
  }
}

export function* updateSubPaymentMethod() {
  yield takeLatest(TYPES.UPDATE_SUB_REQUEST, updateSubPayment);
}
export function* detachPayCard() {
  yield takeLatest(TYPES.DETACH_CARD_REQUEST, detachCard);
}
export function* attachPayCard() {
  yield takeLatest(TYPES.ATTACH_CARD_REQUEST, attachCard);
}
export function* getPayMethods() {
  yield takeLatest(TYPES.GET_PAY_METHODS_REQUEST, getPaymentMethods);
}
export function* forgotPassword() {
  yield takeLatest(TYPES.FORGOT_PASS_REQUEST, forgotPass);
}
export function* changePassword() {
  yield takeLatest(TYPES.CHANGE_PASS_REQUEST, changePass);
}
export function* getCardDetails() {
  yield takeLatest(TYPES.GET_CARDS_REQUEST, getCardsDetails);
}
export function* cancelSubs() {
  yield takeLatest(TYPES.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
}
export function* createSubs() {
  yield takeLatest(TYPES.CREATE_SUBSCRIPTION_REQUEST, craeteSub);
}
export function* createCustomer() {
  yield takeLatest(TYPES.CREATE_CUSTOMER_REQUEST, craeteCustomerId);
}
export function* getSubscriptions() {
  yield takeLatest(TYPES.GET_SUBSCRIPTION_REQUEST, getSubscriptionDetails);
}
export function* profilePicAdd() {
  yield takeLatest(TYPES.ADD_PROFILE_REQUEST, addProfilePic);
}
export function* uploadProfilePic() {
  yield takeLatest(TYPES.UPLOAD_PROFILE_PIC_REQUEST, addUploadProfPic);
}
export function* profilePicUpdate() {
  yield takeLatest(TYPES.UPDATE_PROFILE_PIC_REQUEST, putProfilePic);
}
export function* profileDetails() {
  yield takeLatest(TYPES.GET_PROFILE_REQUEST, getProfileDetails);
}
