export const GET_USERS_LIST_REQUEST = "adminDashboard/GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "adminDashboard/GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "adminDashboard/GET_USERS_LIST_FAILURE";

export const COUPONS_LIST_REQUEST = "adminDashboard/COUPONS_LIST_REQUEST";
export const COUPONS_LIST_SUCCESS = "adminDashboard/COUPONS_LIST_SUCCESS";
export const COUPONS_LIST_FAILURE = "adminDashboard/COUPONS_LIST_FAILURE";

export const ADD_COUPON_REQUEST = "adminDashboard/ADD_COUPON_REQUEST";
export const ADD_COUPON_SUCCESS = "adminDashboard/ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAILURE = "adminDashboard/ADD_COUPON_FAILURE";

export const RESET = "adminDashboard/RESET";
