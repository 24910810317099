import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UnnlockLayout from "../../containers/layout";
import JobCompanyCard from "./JobCompanyCard";
import Topbar from "./Topbar";
import history from "../../routes/History";
import "./JobCompanycard.scss";
import { Col, Row, Spin } from "antd";
import * as JobMatchAction from "../../store/jobMatch/jobMatchActions";
import { acceptOverLayRequest } from "../../store/dashBoard/dashboardActions";
import { getUserDataSuccess } from "../../store/newLogin/newLoginAction";
import { setCurrentResumeId } from "../../store/resumeOptimizer/resumeOptimizerActions";

const OpenJobsDetailsComp = (props) => {
  const { openJobs: matchedjobsList, selectedResumeId } = useSelector(
    (state) => state.tools
  );
  const [faveratesList, setFaveratesList] = useState([]);
  const [Details, setDetails] = useState("");
  const [currentOpenJobId, setcurrentOpenJobId] = useState("");
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.userData);
  const componiesLoader = useSelector((state) => state.tools.loading);

  const getFavClick = (
    operation,
    companyId,
    initialFormData,
    addJobMatchDirectly
  ) => {
    dispatch(setCurrentResumeId(selectedResumeId));
    const postdata = {
      resumeId: selectedResumeId,
      fromOpenJobs: true,
      data: {
        company_name: initialFormData.COMPANY_NAME,
        company_url: Array.isArray(initialFormData.URL)
          ? initialFormData.URL[0]
          : "",
        job_title: initialFormData.TITLE_NAME,
        education: Array.isArray(initialFormData.EDUCATION_LEVELS_NAME)
          ? initialFormData.EDUCATION_LEVELS_NAME[0]
          : "",
        required_experience: initialFormData.MIN_YEARS_EXPERIENCE || "",
        text: null,
        open_job_id: initialFormData.ID,
        industry: initialFormData.TITLE_RAW,
        primary_resume: selectedResumeId,
      },
    };
    if (!addJobMatchDirectly) {
      let favArr = [];
      if (userData.favourite_jobs?.includes(`${companyId}`)) {
        favArr = userData.favourite_jobs.filter(
          (item) => item !== `${companyId}`
        );
      } else {
        favArr = [...userData.favourite_jobs, `${companyId}`];
      }
      dispatch(getUserDataSuccess({ ...userData, favourite_jobs: favArr }));
      dispatch(
        acceptOverLayRequest({
          key: "favourite_jobs",
          value: favArr,
        })
      );
      if (operation === "add") {
        // setclickCheckJob(true);
        dispatch(
          JobMatchAction.jobMatchResultsRequest({ postdata, donotSave: false })
        );
      }
    } else {
      history.push({
        pathname: `/jobmatch`,
        state: {
          postdata,
          fromOpenJobs: true,
        },
      });
    }
  };

  useEffect(() => {
    if (userData.favourite_jobs && Array.isArray(userData.favourite_jobs)) {
      setFaveratesList(userData.favourite_jobs);
    }
  }, [userData]);

  const OpenJobId =
    window &&
    window?.location &&
    window?.location?.search &&
    window?.location?.search?.split("=")[1];

  useEffect(() => {
    if (OpenJobId) {
      setcurrentOpenJobId(OpenJobId);
    }
  }, [OpenJobId]);

  useEffect(() => {
    if (matchedjobsList?.jobs_list?.length) {
      const arr = [...matchedjobsList?.jobs_list];
      setDetails(arr?.filter((item) => item.ID === currentOpenJobId)[0]);
    }
  }, [currentOpenJobId, matchedjobsList]);

  console.log(currentOpenJobId, "currentOpenJobId");

  return (
    <UnnlockLayout>
      <div className="OpenJobComapnies_Details_container">
        <Spin size="large" spinning={componiesLoader}>
          <Topbar />
          <Row className="OpenJobComapnies_Details_container_inner">
            <Col span={6} className="left-componies-list-container">
              {matchedjobsList.jobs_list.map((jobcompany, index) => {
                return (
                  <div key={jobcompany.ID}>
                    {/* {jobcompany.ID} */}
                    <JobCompanyCard
                      item={jobcompany}
                      isFaverate={
                        faveratesList.includes(`${jobcompany.ID}`)
                          ? true
                          : false
                      }
                      getFavClick={getFavClick}
                      showLimited={true}
                      currentOpenJobId={currentOpenJobId}
                    />
                  </div>
                );
              })}
            </Col>
            <Col span={18} className="right-details-container">
              {Details ? (
                <div key={Details.ID}>
                  <JobCompanyCard
                    item={Details}
                    isFaverate={
                      faveratesList.includes(`${Details.ID}`) ? true : false
                    }
                    getFavClick={getFavClick}
                    isDetails={true}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </Spin>
      </div>
    </UnnlockLayout>
  );
};

export default OpenJobsDetailsComp;
